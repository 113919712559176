import { Component, OnDestroy, ViewChild } from '@angular/core';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../utils/helpers';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavKey, SidenavService } from '../../services/sidenav-service';
import { ProjectService } from 'src/app/services/project.service';
import { Member } from 'src/app/models/project';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  readonly icons = {
    times: faTimes,
    bars: faBars,
  };
  sidenavOpen: boolean = false;
  private subscriptions: Subscription[] = [];
  members: Member[] = [];
  route: string
  lang: string;
  @ViewChild('sidenav') sidenav: MatSidenav;
  constructor(
    private router: Router,
    protected sidenavService: SidenavService,
    protected projectService: ProjectService,
    protected translationHelper: TranslationHelperService,
  ) {
    this.sidenavService.watch(SidenavKey.MAIN).subscribe(e => this.sidenavOpen = e)
    this.projectService.groups.forEach(g => this.members.push(...g.members));
    this.router.events.subscribe(val => {

      this.translationHelper.getLanguage().subscribe(lang => {
        this.lang = lang;
        if (val instanceof NavigationEnd) {
          this.route = val.urlAfterRedirects.split('?')[0]
        }
      })
    });
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
  toggleMenu() {
    this.sidenavService.toggle(SidenavKey.MAIN);
  }
  checkMember(member: Member) {
    let checker = false;
    if ('/' + this.lang + '/' + member.routerLink === this.route) {
      checker = true;
    }
    return checker;
  }
}