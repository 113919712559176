import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';
import { DOMAIN } from '../../../models/domain/domain';

@DeclareExtract('helpdesk_time_comparison')
export class HelpdeskTimeComparisonExtract extends AbstractBarometerExtract {

  name = 'helpdesk_time_comparison';

  xUnit = 'seconds';

  get title() {
    return this.name + '.' + this.filter.get('measure');
  }

  choices = {
    measure: ['AVERAGE', 'PERCENTAGE_120S'],
    dataLabel: DOMAIN.barometerPeriods,
  };
}
