<div class="container-fluid page-header bg-1 bg-image">
  <div class="bg-image-overlay"></div>
  <div class="page-header-inner">
    <h1>{{'terms.title' | translate}}</h1>
  </div>
</div>

<main class="container breathe" [innerHTML]="text">

</main>
