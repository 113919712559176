import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AtlasDescriptionBaseComponent } from 'src/app/components/data-description/components/atlas-description-base.component';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';

@Component({
  selector: 'app-atlas-trein-description',
  templateUrl: `atlas-trein-description.component.html`,
  styleUrls: ['./atlas-trein-description.component.scss'],
})
export class AtlasTreinDescriptionComponent extends AtlasDescriptionBaseComponent implements OnInit {

  constructor(
    protected translationHelperService: TranslationHelperService,
    protected _router: Router
  ) { super(translationHelperService, _router) }

  ngOnInit(): void {
  }

}

