import { TranslationHelperService } from '../../services/translations/translation-helper.service';

import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartConfiguration, ChartOptions, PositionType } from 'chart.js';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Extract } from '../../datasets/extracts/extract';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { flatMap, map } from 'rxjs/operators';
import { BreakpointState } from '@angular/cdk/layout';
import { isNullOrUndefined, unsubscribe } from '../../utils/helpers';
import { BootstrapMediaBreakpoint, BootstrapMediaService } from '../../services/bootstrap-media.service';

export interface Entry {
  label: string;
  value: string;
}

export interface ChartConfig {
  label: string;
  key: string;
  choices?: Entry[];
  multiple?: boolean;
}

@Directive()
export abstract class AbstractChartJsComponent<D = any> implements OnInit, OnChanges, OnDestroy {

  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  @ViewChild('chart', { static: true }) canvas: ElementRef;
  @Input() extract: Extract;
  @Input() hideTitle: boolean = false;
  @Output() updateRequest: EventEmitter<Extract> = new EventEmitter<Extract>();
  @Output() typeChange: EventEmitter<string> = new EventEmitter<string>();

  chart: Chart;
  types: Array<string> = [];
  labelTranslationPrefix = 'labels';
  titleTranslationPrefix = 'title';
  extractSubscription: Subscription;
  chartSubscription: Subscription;

  lockSidenavOpen: boolean = false;

  private subscriptions: Subscription[] = [];

  get choices(): { [filter: string]: any } {
    return this.extract.choices || {};
  }

  get type() {
    return this.extract.chartType;
  }

  get filter() {
    return this.extract.filter;
  }

  get config() {
    return this.extract.config;
  }

  get chartClass() {
    return this.constructor.name;
  }

  get datasetClass() {
    return this.extract.dataset.constructor.name;
  }

  get canBeControlled() {
    return Object.keys(this.choices).length > 0;
  }

  get isLoading() {
    return !this.extract || this.extract.loading || isNullOrUndefined(this.extract.data);
  }

  get canChangeType() {
    return this.types.length > 1 && this.extract.canChangeChartType;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  constructor(
    protected statistics: StatisticsService,
    protected translationHelper: TranslationHelperService,
    protected breakpointObserver: BootstrapMediaService,
    protected zone: NgZone,
  ) {
    unsubscribe(this.subscriptions)
    this.subscriptions.push(
      this.breakpointObserver.observe(BootstrapMediaBreakpoint.LG).subscribe((state: BreakpointState) => {
        this.lockSidenavOpen = state.matches;
      }),
    );
  }

  ngOnInit() {
    this.createChart();
    setTimeout(() => this.updateChart(), 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('extract' in changes) {
      this.observeExtractUpdate();
      this.updateChart();
    }
  }

  observeExtractUpdate() {
    if (this.extractSubscription) {
      this.extractSubscription.unsubscribe();
    }

    if (this.extract && this.extract.filter) {
      this.extractSubscription = this.extract.observeUpdates().subscribe(
        () => this.updateChart(),
        (error) => console.log(this.constructor.name, 'observeExtractUpdate', error),
      );
    }
  }

  createChart() {
    if (this.chartSubscription) {
      this.chartSubscription.unsubscribe();
    }
    let ctx = this.canvas.nativeElement;
    this.chartSubscription = this.getOptions().subscribe(
      (options) => {
        let configuration: ChartConfiguration = {
          type: this.type,
          data: {},
          options: options,
        };
        this.zone.runOutsideAngular(() => {
          try {
            this.chart = new Chart(ctx, configuration);
          } catch (err) {
            if (err.message !== 'NotYetImplemented') {
              throw err;
            }
          }
        });
      },
      (error) => console.error(this.constructor.name, 'createChart', error),
    );
  }

  abstract updateChart();

  translateLabels(labels: Array<string> = Object.keys(this.extract.data)): Observable<Array<string>> {
    let labelKeys = labels.map(label => this.labelTranslationPrefix + '.' + label);

    return this.translationHelper.observeAll(...labelKeys)
      .pipe(
        map((translations) =>
          translations.map((translation, index) =>
            translation !== labelKeys[index]
              ? translation
              : labels[index]
          )
        )
      )
      ;
  }

  translateLabelsGroups(...labelsGroups: Array<Array<string>>): Observable<Array<Array<string>>> {
    let labelKeysGroups: Array<Array<string>> = labelsGroups.map(labels => labels.map(label => this.labelTranslationPrefix + '.' + label));

    return this.translationHelper.getLocale().pipe(
      flatMap(locale =>
        forkJoin(...labelKeysGroups.map(labelKeys => this.translationHelper.getAll(...labelKeys)))
      ),
      map((translationsGroups) => translationsGroups.map((translations, i) => {
        let labels = labelsGroups[i];
        let labelKeys = labelKeysGroups[i];
        return translations.map((translation, j) =>
          translation !== labelKeys[j]
            ? translation
            : labels[j]
        );
      }))
    );
  }

  updateChartSize(update: boolean = true) {
    if (this.chart) {
      let width = this.canvas.nativeElement.offsetWidth;
      let height = this.canvas.nativeElement.offsetHeight;
      (<any>this.chart).width = width;
      (<any>this.chart).height = height;
      this.canvas.nativeElement.width = width;
      this.canvas.nativeElement.height = height;
      if (update) {
        this.chart.update();
      }
    }
  }

  getOptions(): Observable<ChartOptions> {
    return this.translationHelper.get(this.titleTranslationPrefix + '.' + this.extract.name).pipe(
      map(translation => (<ChartOptions>{
        title: {
          display: false,
          text: translation,
          fontFamily: '\'Nunito Sans\', \'Open Sans\', sans-serif',
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          position: <PositionType>'bottom',
          labels: {
            fontFamily: '\'Nunito Sans\', \'Open Sans\', sans-serif',
          },
        },
        animation: { duration: 0 },
      })),
    );
  }

  emptyChart() {
    let data = this.chart.data;
    while (data.datasets.length > 0) {
      data.datasets.splice(0);
    }
    while (data.labels.length > 0) {
      data.labels.splice(0);
    }
  }

  destroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  changeType(type: string) {
    this.extract.chartType = type;
    this.typeChange.emit(type);
    this.destroy();
    this.createChart();
    this.updateChart();
  }

  nextChartType: string;

  switchType() {
    let currentType = this.extract.chartType;
    let nextIndex = (this.types.indexOf(currentType) + 1) % this.types.length;

    this.nextChartType = this.types[(nextIndex + 1) % this.types.length];

    this.changeType(this.types[nextIndex]);
  }

  update() {
    this.extract.loading = true;
    this.updateRequest.emit(this.extract);
  }
}
