import { Extract } from './extract';

export class ExtractRegistry {

  static types: { [key: string]: any } = {};

  static declare(name: string, constructor: any) {
    this.types[name] = constructor;
  }

  static getConstructor(name: string): { new(...args: any[]): Extract } {
    return this.types[name];
  }
}
