<div class="legend-container ol-control ol-attribute" id="trein-legend">
  <img src="../../../../assets/images/atlas_legend.png" class="legend" />
  <div class="legend-values">
    <div
      *ngFor="let value of legend.values"
      class="legend-value"
      [style.left]="value.position * 50 + '%'"
    >
      <div class="legend-label">{{ value.label | translate }}</div>
      <div class="legend-mark"></div>
    </div>
  </div>
</div>
