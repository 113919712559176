import { Component } from '@angular/core';
import { AtlasFilterBaseComponent } from '../../atlas-filter-base.component';
import { AtlasMobileFilterService } from 'src/app/services/filter/atlas-mobile-filter.service';
import { DatasetEntry } from 'src/app/components/map/map.component';
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';

@Component({
  selector: 'app-atlas-mobile-filter',
  templateUrl: './atlas-mobile-filter.component.html',
  styleUrls: ['./atlas-mobile-filter.component.scss']
})
export class AtlasMobileFilterComponent extends AtlasFilterBaseComponent {

  constructor(
    public atlasMobileFilterService: AtlasMobileFilterService,
    public olMapService: OLMapService
  ) {
    super();
  }

  setFilter(filter: string, value: any) {
    this.atlasMobileFilterService.setFilter(filter, value);
  }
  changeDataset(entry: DatasetEntry) {
    this.atlasMobileFilterService.atlasMobileService.changeDataset(entry);
  }
}

