///<reference path="../../utils/geojson.d.ts"/>

import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faSpinnerThird as falSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import * as leaflet from 'leaflet';
import * as L from 'leaflet';
import { LatLngBounds, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { Filter, FilterConfig } from '../../datasets/filter';
import { environment } from '../../../environments/environment';
import { Dataset, MapType } from '../../datasets/dataset';
import { inverse } from '../../utils/arrays';
import { Area } from '../../services/area/area';
import { HttpClient } from '@angular/common/http';
import { AreaService } from '../../services/area/area.service';
import { RGBColor } from '../../utils/RGBColor';
import { Observable, Subscription } from 'rxjs';
import { unsubscribe } from '../../utils/helpers';
import { BreakpointState } from '@angular/cdk/layout';
import { BootstrapMediaBreakpoint, BootstrapMediaService } from '../../services/bootstrap-media.service';
import { LayerDefinition } from '../../models/layer';
import { FeatureCollection, GeoJsonObject, Geometry } from '../../utils/geojson';

export interface DatasetEntry {
  label: string;
  dataset: Dataset;
  filter: Filter;
  filters: FilterConfig[];
}

export type Datasets = Array<DatasetEntry>;

interface PositionInfo {
  properties: {
    households: number;
    lastModified: string;
    level: string;
    nameDutch: string;
    nameFrench: string;
    nameGerman: string;
    niscode: string;
    population: number;
    postalCodes: Array<string>;
    shapeArea: number;
    shapeLeng: number;
    xyorigin: number;
  };
  geometry: Geometry;
  value: number;
  color: { r: number, g: number, b: number, a: number };
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  public static readonly DEFAULT_ZOOM = 8;
  public static readonly DEFAULT_CENTER = new leaflet.LatLng(50.845653, 4.364210);
  public static readonly DEFAULT_RECTANGLE = new leaflet.LatLngBounds([49.4968827519196, 2.54133921349926], [51.5051138604811, 6.40809817433551]);
  public static readonly COLOR_SEARCHED_BORDERS = new RGBColor(0x5b, 0x62, 0xab);

  @ViewChild('map') mapElementRef: ElementRef;
  @Input() filter: Filter;
  @Input() filters: FilterConfig[];
  @Input() dataset: Dataset;
  @Input() datasetEntry: DatasetEntry;
  @Input() datasets: Datasets = [];
  @Input() datasetsInputType: 'tab' | 'select' = 'tab';
  @Input() allowMultipleFilterValues: boolean = true;
  @Output() filterSet = new EventEmitter<{ key: string, value: any }>();

  map: leaflet.Map;
  layers: { [name: string]: leaflet.Layer } = {};
  subscriptionMap: { [name: string]: Subscription } = {};
  subscriptions: Subscription[] = [];

  showControls: boolean = false;
  activeFeatureProperties: any;
  details: Array<{ label: string, value: string }>;
  detailsLoading: boolean = false;

  isLoading: boolean = false;

  lockSidenavOpen: boolean = false;

  highlightedNisCode: string;

  constructor(
    protected http: HttpClient,
    protected areaService: AreaService,
    protected breakpointObserver: BootstrapMediaService,
    protected zone: NgZone,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.breakpointObserver.observe(BootstrapMediaBreakpoint.LG).subscribe((state: BreakpointState) => {
        this.lockSidenavOpen = state.matches;
      }),
    );
  }

  ngOnInit() {
    if (this.datasetEntry) {
      this.dataset = this.datasetEntry.dataset;
      this.filter = this.datasetEntry.filter;
      this.filters = this.datasetEntry.filters;
    }
    this.createMap();
    this.observeFilter();
  }

  ngOnDestroy() {
    if (this.map) {
      this.mapElementRef.nativeElement.remove();
      this.map.remove();
    }
    unsubscribe(Object.values(this.subscriptionMap));
    unsubscribe(this.subscriptions);
  }

  changeDataset(entry: DatasetEntry) {
    this.datasetEntry = entry;
    this.dataset = entry.dataset;
    this.filter = entry.filter;
    this.filters = entry.filters;
    this.loadData();
    this.observeFilter();
    this.map.setMaxBounds(this.dataset.getBounds());
  }

  observeFilter() {
    this._do('observeFilter', this.filter.observeChanges(), () => this.loadData());
  }

  createMap() {
    this.zone.runOutsideAngular(() => {
      this.map = leaflet.map('map', {
        center: MapComponent.DEFAULT_CENTER,
        zoom: MapComponent.DEFAULT_ZOOM,
        attributionControl: false,
        worldCopyJump: true,
        maxZoom: this.dataset.MAP_MAX_ZOOM,
        minZoom: this.dataset.MAP_MIN_ZOOM,
        scrollWheelZoom: false, // this changes when the map is focused or blurred
        // zoomSnap: 0.5,
      })
        .on('focus', () => this.map.scrollWheelZoom.enable())
        .on('blur', () => this.map.scrollWheelZoom.disable())
        .on('click', (event: LeafletMouseEvent) => {
          this.zone.run(() => {
            this.onMouseClick(event);
          });
        });
    });

    this.loadBackground();
    this.loadCountryBoundaries();

    this.addAttributionControl();

    this.loadData();
    this.map.setMaxBounds(this.dataset.getBounds());
    this.map.fitBounds(MapComponent.DEFAULT_RECTANGLE);
  }

  onMouseClick(event: LeafletMouseEvent) {
    this.clearHighlight();
    let url = this.dataset.getPositionInfoUrl(event.latlng, this.filter);
    if (url) {
      this.details = undefined;
      this.detailsLoading = true;
      this.subscriptions.push(
        this.http.get(url).subscribe(
          (result: PositionInfo) => {
            // console.log(this.constructor.name, 'onMouseClick', result);
            if (result !== null) {
              if (result.properties.niscode !== this.highlightedNisCode) {
                let name = result.properties.nameDutch || result.properties.nameFrench || result.properties.nameGerman;
                this.details = [
                  { label: 'area', value: name },
                  { label: 'cover', value: Math.round(result.value * 10000) / 100 + '%' },
                ];
                this.highlightedNisCode = result.properties.niscode;
                this.highlightGeometry(result.geometry, RGBColor.fromJSON(result.color, true));
              }
            } else {
              this.clearHighlight();
            }
            this.detailsLoading = false;
          },
          (err) => console.error(this.constructor.name, 'onMouseMove', err),
        ))
    }
  }

  highlightGeometry(geometry: Geometry, color: RGBColor) {
    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: geometry,
        properties: {},
      }],
    };

    this.replaceLayer('highlight',
      (<any>L).geoJson(featureCollection, {
        style: (feature) => ({
          stroke: false,
          fill: true,
          fillOpacity: 0.9,
          fillColor: color.darken(20).toHexString(),
        }),
      })
        .setZIndex(3)
    );
  }

  loadCountryBoundaries() {
    let layerData: LayerDefinition = environment.tileserver.backgroundLayers.countryBoundaries;

    this.replaceLayer('countryBoundaries',
      leaflet.tileLayer(layerData.url, {
        attribution: layerData.attribution,
      })
        .setZIndex(2)
    );
  }

  loadBackground(id: string = Object.keys(environment.tileserver.backgroundLayers)[0]) {
    let layerData: LayerDefinition = environment.tileserver.backgroundLayers[id];

    this.replaceLayer('background',
      leaflet.tileLayer(layerData.url, {
        attribution: layerData.attribution,
        pane: 'tilePane',
      })
    );
  }

  loadData() {
    let url = this.dataset.getTileUrlTemplate(this.filter);

    if (this.dataset.getMapType() === MapType.RASTER) {
      this.clearData();
      this.layers['data'] = leaflet.tileLayer(url, {})
        .addTo(this.map);
      this.addClassToLayer('data', 'data-layer');

    } else if (this.dataset.getMapType() === MapType.VECTOR) {
      this.isLoading = true;
      this.clearData();
      this._do('data', this.http.get(url),
        (geojson: GeoJsonObject) => {
          this.isLoading = false;
          return L.geoJSON(geojson, {
            style: (feature) => ({
              fill: true,
              // stroke: false,
              color: feature.properties['stroke'],
              strokeOpacity: feature.properties['stroke-opacity'] || 0.25,
              weight: feature.properties['stroke-width'] || 1,
              fillOpacity: feature.properties['fill-opacity'] || 0.85,
              fillColor: feature.properties['fill'],
            }),
            // interactive: true,
            // maxZoom: 18,
          })
            .on('mouseover', (event: any) => {
              this.updateVectorActiveFeature(event.layer.properties, event.layer);
            })
            .on('click', (event: any) => {
              if (event.layer.properties.bbox) {
                let bbox = event.layer.properties.bbox;
                let bounds = new LatLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]]);
                this.map.fitBounds(bounds);

                if (this.dataset.onFeatureClick) {
                  this.dataset.onFeatureClick(this, event);
                }
              }
            })
            ;
        }
      );
    }
  }

  loadSearchedBoundaries(area: Area) {
    this.isLoading = true;
    this._do('searchedBoundaries', this.areaService.getGeometry(area),
      (featuresCollection) => {
        this.isLoading = false;
        return L.geoJSON(featuresCollection, {
          style: (feature) => ({
            fill: false,
            color: MapComponent.COLOR_SEARCHED_BORDERS.toRGBAString(),
          }),
        })
          .setZIndex(3);
      }
    );
  }

  addAttributionControl() {
    this.map.addControl(leaflet.control.attribution({
      position: 'bottomright',
      prefix: '',
    }));
  }

  updateVectorActiveFeature(properties: any, layerData: any) {
    let layer = (<any>this.layers['data']);
    if (this.activeFeatureProperties !== undefined) {
      layer.resetFeatureStyle(this.activeFeatureProperties.id);
    }

    layer.setFeatureStyle(properties.id, Object.assign(layerData.options, {
      fillColor: RGBColor.fromHexString(properties['fill']).mix(RGBColor.BLACK, 0.35).toHexString(),
      fillOpacity: 0.90,
    }));

    this.activeFeatureProperties = properties;

    this.details = [
      { label: 'area', value: properties.name },
      { label: 'cover', value: Math.round(properties.value * 10000) / 100 + '%' },
    ];
  }

  searchArea(area: Area) {
    // console.log(this.constructor.name, 'searchArea', area);
    this.clearLayer('searchedBoundaries');
    this.clearSubscription('searchedBoundaries');

    if (area && area.rectangleBounds && area.rectangleBounds.points) {
      this.loadSearchedBoundaries(area);

      let points = area.rectangleBounds.points;
      let bounds = new LatLngBounds(points[0].coordinates.map(inverse) as LatLngTuple, points[2].coordinates.map(inverse) as LatLngTuple);
      this.map.fitBounds(bounds);
    }
  }

  toggleControls() {
    this.showControls = !this.showControls;
  }

  closeControls() {
    this.showControls = false;
  }

  clearData() {
    this.clearLayer('data');
    this.clearHighlight();
  }

  clearHighlight() {
    this.details = undefined;
    this.clearLayer('highlight');
    this.highlightedNisCode = undefined;
  }

  clearLayer(name: string) {
    if (this.layers[name]) {
      this.layers[name].remove();
    }
    this.layers[name] = undefined;
  }

  clearSubscription(name: string) {
    if (this.subscriptionMap[name]) {
      this.subscriptionMap[name].unsubscribe();
    }
    this.subscriptionMap[name] = undefined;
  }

  addClassToLayer(layerName: string, className: string) {
    if ((<any>this.layers[layerName]).getContainer) {
      let container: HTMLElement = (<any>this.layers[layerName]).getContainer();
      let classes = container.className.trim().split(' ');
      classes.push(className);
      container.className = classes.join(' ');
    }
  }

  setFilter(key: string, value: any) {
    this.filter.set(key, value);
    this.filterSet.emit({ key: key, value: value });
    this.loadData();
  }

  /**
   * Disabled because uses "document", which is not available with SSR. Though not used, we keep
   * the code here so we
   */
  saveAsImage() {
    // let nativeElement = this.mapElementRef.nativeElement;
    // html2canvas(nativeElement, <Html2CanvasOptions> {
    //   foreignObjectRendering: true,
    //   logging: false,
    //   backgroundColor: null,
    //   ignoreElements: (element: HTMLElement) => element.classList.contains('leaflet-control-container'),
    // }).then((canvas: HTMLCanvasElement) => {
    //   let a = document.createElement('a');
    //   a.setAttribute('style', 'display: none');
    //   document.body.appendChild(a);
    //   a.setAttribute('href', canvas.toDataURL());
    //   let now = new Date()
    //     .toISOString()
    //     .split('.')[0]
    //     .replace('T', '_')
    //   ;
    //   a.setAttribute('download', now + '.png');
    //   a.click();
    //   a.remove();
    // });
  }

  _do<T>(
    actionName: string,
    observable: Observable<T>,
    successCallback?: (result: T) => L.Layer | void,
    errorCallback?: (error: Error) => boolean,
    completeCallback?: () => void,
  ) {
    if (this.subscriptionMap[actionName]) {
      this.subscriptionMap[actionName].unsubscribe();
    }

    this.subscriptionMap[actionName] = observable.subscribe(
      (result: T) => {
        let layer = successCallback(result);
        if (layer) {
          this.replaceLayer(actionName, layer);
        }
      },
      (error) => {
        this.isLoading = false;
        if (errorCallback && !errorCallback(error)) {
          console.log(this.constructor.name, '_do(' + actionName + ')', error);
        }
      },
      () => {
        this.isLoading = false;
        this.subscriptionMap[actionName].unsubscribe();
        if (completeCallback) {
          completeCallback();
        }
      },
    );
  }

  replaceLayer(layerName: string, layer) {
    this.clearLayer(layerName);
    this.layers[layerName] = layer;
    layer.addTo(this.map);
    let layerClass = layerName.replace(/[A-Z]+/g, (match) => '-' + match.toLowerCase()) + '-layer';
    this.addClassToLayer(layerName, layerClass);
  }
}
