import { Dataset, MapType } from "./dataset";
import { environment } from "../../environments/environment";

export class AtlasFixDataset extends Dataset {
  protected mapBaseUrl: string =
    environment.geoServer.baseUrl;

  protected mapPositionInfoUrl?: string;

  public legend = null;

  protected statisticsBaseUrl: string = null;

  protected attribution: string = undefined;

  getMapType(): MapType {
    return MapType.VECTOR;
  }
}
