<div class="d-flex flex-row datasets d-lg-none" *ngIf="datasets.length > 1 && datasetsInputType === 'tab'">
  <div class="col dataset p-3"
       *ngFor="let entry of datasets"
       [class.active]="dataset === entry.dataset"
       (click)="changeDataset(entry)"
  >
    {{ entry.label | translate }}
  </div>
</div>
<mat-sidenav-container class="w-100">
  <mat-sidenav mode="over" #sidenav position="end" [opened]="false" class="sidenav-filter pt-3">
    <div class="w-100 h-100 p-3">
      <div class="close" (click)="sidenav.close()">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </div>

      <app-map-filters [filter]="filter" [filters]="filters" *ngIf="!lockSidenavOpen" (selectedAreaChange)="searchArea($event)"></app-map-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container-fluid h-100">
      <div class="row h-100">

        <div class="loader-overlay" [class.shown]="isLoading">
          <fa-icon [icon]="icons.falSpinnerThird" size="3x" [spin]="true"></fa-icon>
        </div>

        <div class="d-flex d-lg-none justify-content-end align-items-center chart-controls w-100">
          <button class="btn btn-default" (click)="sidenav.toggle()">
            <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
            {{ 'ui.charts.filters' | translate }}
          </button>
        </div>

        <div class="col-lg-3 col-md-12 filters" *ngIf="lockSidenavOpen">
          <div class="row datasets" *ngIf="datasets.length > 1 && datasetsInputType === 'tab'">
            <div class="col dataset p-3"
                 *ngFor="let entry of datasets"
                 [class.active]="dataset === entry.dataset"
                 (click)="changeDataset(entry)"
            >
              {{ entry.label | translate }}
            </div>
          </div>

          <div class="breathe">
            <mat-form-field *ngIf="datasets.length > 1 && datasetsInputType == 'select'" class="w-100">
              <mat-select [placeholder]="'labels.dataset' | translate"
                          [ngModel]="datasetEntry"
                          (ngModelChange)="changeDataset($event)">
                <mat-option *ngFor="let entry of datasets" [value]="entry">{{entry.label | translate}}</mat-option>
              </mat-select>
            </mat-form-field>

            <app-map-filters [filter]="filter" [filters]="filters" (selectedAreaChange)="searchArea($event)"></app-map-filters>
          </div>
        </div>

        <div class="col-lg-9 col-md-12 chart">
          <div class="row position-relative flex-column">

            <div class="map-container" [class.loading]="isLoading">
              <div #map id="map"></div>

              <app-map-legend [definition]="dataset.legend" *ngIf="dataset.legend"></app-map-legend>

              <div class="details-container" *ngIf="details || detailsLoading">
                <ul *ngIf="details">
                  <li *ngFor="let detail of details">
                    <span class="label">{{ detail.label | label | async }}</span>
                    <span class="value">{{ detail.value }}</span>
                  </li>
                </ul>
                <fa-icon *ngIf="detailsLoading" class="details-loader" [icon]="icons.falSpinnerThird" size="2x" [spin]="true"></fa-icon>
              </div>
            </div>

            <div class="controls-overlay" *ngIf="showControls" (click)="closeControls()"></div>
            <div class="controls" [class.shown]="showControls">
              <button class="close" (click)="closeControls()">
                <fa-icon [icon]="icons.falTimes"></fa-icon>
              </button>
            </div>

          </div>
        </div>

      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>



