import { Component, OnDestroy } from '@angular/core';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { ProjectService } from '../../services/project.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../utils/helpers';
import { Project } from '../../models/project';

@Component({
  selector: 'app-datasource-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss']
})
export class ProjectLayoutComponent implements OnDestroy {

  private subscriptions: Subscription[] = [];

  project: Project;

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  constructor(
    protected translationHelper: TranslationHelperService,
    protected datasourceService: ProjectService,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.datasourceService.getSelected().subscribe(ds => {
        this.project = ds;
      }),
    );
  }
}
