import { Component } from "@angular/core";
import { AtlasFilterBaseComponent } from "../../atlas-filter-base.component";
import { AtlasFixFilterService } from "src/app/services/filter/atlas-fix-filter.service";
import { GeoserverAPIService } from "src/app/services/geoserver/geoserver-api.service";

@Component({
  selector: "app-atlas-fix-filter",
  templateUrl: "./atlas-fix-filter.component.html",
  styleUrls: ["./atlas-fix-filter.component.scss"],
})
export class AtlasFixFilterComponent extends AtlasFilterBaseComponent {
  constructor(
    public atlasFixFilterService: AtlasFixFilterService,
    private geoserverService: GeoserverAPIService
  ) {
    super();
  }
  setFilter(filter: string, value: any) {
    this.atlasFixFilterService.setFilter(filter, value);
    this.getPoints();
  }
  getPoints() {
    this.atlasFixFilterService.atlasFixService.loadPoint(null);
  }
}
