<mat-sidenav-container class="w-100" [hasBackdrop]="false">
  <mat-sidenav
    mode="over"
    #sidenav
    position="end"
    [opened]="false"
    class="sidenav-filter pt-3"
  >
    <div class="w-100 h-100 p-3">
      <button class="close" (click)="sidenav.close()">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="loader-overlay" [class.shown]="isLoading">
          <fa-icon
            [icon]="icons.falSpinnerThird"
            size="3x"
            [spin]="true"
          ></fa-icon>
        </div>
        <div
          class="d-flex d-xl-none justify-content-end align-items-center chart-controls w-100"
        >
          <button class="btn btn-default" (click)="sidenav.toggle()">
            <fa-icon [icon]="icons.falFilter" class="mr-1"></fa-icon>
          </button>
        </div>
        <div class="col-lg-9 col-md-12 chart">
          <div class="row position-relative flex-column">
            <div
              class="map-container"
              [class.loading]="isLoading"
              id="fix-detail"
            >
              <div
                class="details-container"
                *ngIf="olMapService.details?.length > 0 || detailsLoading"
              >
                <button class="close" (click)="olMapService.details.length = 0">
                  <fa-icon [icon]="icons.falTimes"></fa-icon></button
                ><br />
                <ul *ngIf="olMapService.details">
                  <li *ngFor="let detail of olMapService.details">
                    <span class="label">{{
                      detail.label | label | async
                    }}</span>
                    <span class="value" *ngIf="detail.value != null">{{
                      detail.value
                    }}</span>
                    <span class="value" *ngIf="detail.ratio != null"
                      >{{
                        detail.ratio | multiply : 100 | number : "1.0-2"
                      }}%</span
                    >
                  </li>
                </ul>
                <fa-icon
                  *ngIf="detailsLoading"
                  class="details-loader"
                  [icon]="icons.falSpinnerThird"
                  size="2x"
                  [spin]="true"
                ></fa-icon>
              </div>
              <div
                class="point-details-container"
                *ngIf="pointDetail?.length > 0 || detailsLoading"
              >
                <button class="close" (click)="pointDetail.length = 0">
                  <fa-icon [icon]="icons.falTimes"></fa-icon></button
                ><br />
                <table
                  mat-table
                  [dataSource]="pointDetail"
                  multiTemplateDataRows
                >
                  <ng-container
                    matColumnDef="{{ column }}"
                    *ngFor="let column of LABEL_ATLASFIX_POINTS"
                  >
                    <th mat-header-cell *matHeaderCellDef class="p-2">
                      {{ column }}
                    </th>
                    <td mat-cell *matCellDef="let details" class="p-2">
                      <div
                        class="detail px-1"
                        [style.background-color]="
                          getBackgroundColor(details[column])
                        "
                        [style.color]="getTextColor(details[column])"
                      >
                        {{ details[column] }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="expand">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      aria-label="row actions"
                    >
                      &nbsp;
                    </th>
                    <td mat-cell *matCellDef="let details"></td>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <td
                      mat-cell
                      *matCellDef="let details"
                      [attr.colspan]="LABEL_ATLASFIX_POINTS.length"
                    >
                      <span> {{ details.Operators }}</span>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="LABEL_ATLASFIX_POINTS"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let details; columns: LABEL_ATLASFIX_POINTS"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                  ></tr>
                </table>
                <fa-icon
                  *ngIf="detailsLoading"
                  class="details-loader"
                  [icon]="icons.falSpinnerThird"
                  size="2x"
                  [spin]="true"
                >
                </fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
