import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { environment } from '../../../../environments/environment';
import { IndicatorValue } from '../models/indicator-value';
import { ChartType } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { getPreSelection } from '../pre-selection';
import { Indicator } from '../models/indicator';
import { arraySet } from '../../../utils/arrays';
import { unsubscribe } from 'src/app/utils/helpers';
import { Subscription } from 'rxjs';

function getArrayQueryParam(val) {
  if (Array.isArray(val)) {
    return val;
  }
  return undefined;
}

@Component({
  selector: 'app-indicator-values-component',
  templateUrl: './indicators-values.component.html',
  styleUrls: ['./indicators-values.component.scss']
})
export class IndicatorsValuesComponent implements OnChanges, OnDestroy {
  subscriptions: Subscription[] = [];
  @Input() indicator: Indicator;

  readonly icons = {
    loader: faSpinnerThird,
  };

  indicatorValues: IndicatorValue[];
  loading = false;
  error: any;

  subjects: string[];
  selectedSubjects: string[];
  periods: string[];
  selectedPeriods: string[];
  chartTypes: { label: string, value: ChartType }[] = [
    { label: 'filters.chartType.values.bar', value: 'bar' },
    { label: 'filters.chartType.values.line', value: 'line' },
  ];
  chartType: ChartType = this.chartTypes[0].value;
  chartTypeDirty = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly route: ActivatedRoute,
  ) {
    unsubscribe(this.subscriptions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }

  private getData() {
    if (!this.indicator) {
      return;
    }

    this.loading = true;
    this.subscriptions.push(
      this.httpClient
        .get(`${environment.indicators.api.url}/v1/indicators/${this.indicator.key}/values`)
        .subscribe({
          next: (values: IndicatorValue[]) => {
            // console.log(values);

            this.loading = false;
            this.indicatorValues = values;

            this.subjects = arraySet(values.map(val => val.subject));
            this.periods = arraySet(values.map(val => val.period));

            this.preSelect();
          },
          error: err => {
            this.loading = false;
            this.error = err;
            console.log(err);
          },
        }))
  }

  private preSelect() {
    const preSelection = getPreSelection(this.indicator.group, this.indicator.short, this.indicator.breakdown);

    if (this.subjects.length <= 20) {
      this.selectedSubjects = getArrayQueryParam(this.route.snapshot.queryParamMap.get('subject')) || [...this.subjects];
    } else {
      this.selectedSubjects = [...preSelection.subjects];
    }

    if (this.subjects.length > 1 && this.periods.length > 1) {
      this.selectedPeriods = [this.periods.sort()[this.periods.length - 1]];
    } else {
      this.selectedPeriods = getArrayQueryParam(this.route.snapshot.queryParamMap.get('period')) || [...this.periods];
    }

    if (this.subjects.length > 1 && this.periods.length > 1) {
      this.chartType = 'bar';
    } else if (this.subjects.length === 1 && this.periods.length > 1) {
      this.chartType = 'line';
    } else {
      this.chartType = this.route.snapshot.queryParamMap.get('chartType') || this.selectedPeriods.length >= 3 ? 'line' : 'bar';
    }
  }

  onChartTypeChange() {
    this.chartTypeDirty = true;
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
