<div
  class="container breathe mt-5"
  *ngIf="subjectSummary?.tableName && subjectDataSummary?.tableName"
>
  <div class="row datasets">
    <div
      class="col dataset p-3"
      *ngFor="let entry of tabNames"
      [class.active]="selectedData === entry"
      (click)="onSelectionChange(entry)"
    >
      {{ entry }}
    </div>
  </div>
  <div class="centered mt-5 breathe">
    <a
      class="btn btn-lg btn-primary"
      href="{{ 'ui.projects.drivetesting.download-report-link' | translate }}"
      target="_blank"
    >
      {{ "ui.projects.drivetesting.download-report" | translate }}
      <fa-icon [icon]="icons.falFilePdf" class="ml-2"></fa-icon>
    </a>
  </div>

  <table class="wide d-none d-md-table">
    <tr class="column-titles">
      <th class="title" colspan="2">{{ subjectSummary.tableName }}</th>
      <th
        *ngFor="let operator of subjectSummary.providers"
        [className]="'data-column ' + operator | lowercase"
      >
        {{ operator }}
      </th>
    </tr>

    <ng-container *ngFor="let category of subjectSummary.categories">
      <tr class="inbetween-title">
        <th colspan="5">{{ category.subTable }}</th>
      </tr>

      <tr class="data-row" *ngFor="let row of category.data">
        <th class="data-row-title">{{ row.header }}</th>
        <th class="data-row-unit">{{ row.ratio }}</th>
        <td class="data-column" *ngFor="let value of row.values">
          {{ value }}
        </td>
      </tr>
    </ng-container>
  </table>

  <table class="not-wide d-table d-md-none">
    <tr class="table-title">
      <th colspan="3">{{ subjectSummary.tableName }}</th>
    </tr>

    <ng-container *ngFor="let category of subjectSummary.categories">
      <tr class="category-title">
        <th colspan="3">{{ category.subTable }}</th>
      </tr>

      <tr class="operators">
        <th
          *ngFor="let operator of subjectSummary.providers"
          [class]="operator | lowercase"
        >
          {{ operator }}
        </th>
      </tr>

      <ng-container *ngFor="let row of category.data">
        <tr class="data-title">
          <th colspan="3">
            {{ row.header }} <span class="data-unit">({{ row.ratio }})</span>
          </th>
        </tr>

        <tr class="data-row">
          <td *ngFor="let value of row.values">{{ value }}</td>
        </tr>
      </ng-container>
    </ng-container>
  </table>

  <h3 class="mt-5">Data results</h3>

  <table class="wide d-none d-md-table">
    <tr class="column-titles">
      <th class="title" colspan="3">{{ subjectDataSummary.tableName }}</th>
      <th
        *ngFor="let operator of subjectDataSummary.providers"
        [className]="'data-column ' + operator | lowercase"
      >
        {{ operator }}
      </th>
    </tr>

    <ng-container *ngFor="let category of subjectDataSummary.categories">
      <tr class="inbetween-title">
        <th colspan="6">{{ category.subTable }}</th>
      </tr>

      <ng-container *ngFor="let row of category.rows">
        <tr class="data-row" *ngFor="let subRow of row.subRows; let i = index">
          <th
            class="data-row-title"
            *ngIf="i === 0"
            [attr.rowspan]="row.subRows.length"
          >
            {{ row.header }}
          </th>
          <th
            class="data-row-subcategory"
            *ngIf="i === 0 || row.subRows.length === row.info.length"
            [attr.rowspan]="row.subRows.length / row.info.length"
          >
            {{ row.info[i] }}
          </th>
          <th class="data-row-unit">{{ subRow.ratio }}</th>
          <ng-container *ngFor="let value of subRow.values">
            <td class="data-column">{{ value }}</td>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>
  </table>

  <table class="not-wide d-table d-md-none">
    <tr class="table-title">
      <th colspan="3">{{ subjectDataSummary.tableName }}</th>
    </tr>

    <tr class="operators">
      <th
        *ngFor="let operator of subjectDataSummary.providers"
        [class]="operator | lowercase"
      >
        {{ operator }}
      </th>
    </tr>

    <ng-container *ngFor="let category of subjectDataSummary.categories">
      <tr class="category-title">
        <th colspan="3">{{ category.subTable }}</th>
      </tr>

      <ng-container *ngFor="let row of category.rows">
        <tr class="row-title">
          <th colspan="3">
            {{ row.header }}
            <ng-container *ngIf="row.info.length === 1">
              <br />
              <span class="altlabel">{{ row.info[0] }}</span>
            </ng-container>
          </th>
        </tr>

        <ng-container *ngFor="let subRow of row.subRows; let i = index">
          <tr class="data-title">
            <th colspan="3">
              <ng-container *ngIf="row.subRows.length === row.info.length">
                <span class="subrow-label">{{ row.info[i] }}</span>
                <br />
              </ng-container>
              {{ subRow.ratio }}
            </th>
          </tr>

          <tr class="data-row">
            <td *ngFor="let value of subRow.values">{{ value }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
  </table>

  <div class="centered mt-5">
    <a
      class="btn btn-lg btn-primary"
      href="{{ 'ui.projects.drivetesting.download-report-link' | translate }}"
      target="_blank"
    >
      {{ "ui.projects.drivetesting.download-report" | translate }}
      <fa-icon [icon]="icons.falFilePdf" class="ml-2"></fa-icon>
    </a>
  </div>
</div>
