import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { take } from 'rxjs/operators';
import { TranslationHelperService } from '../services/translations/translation-helper.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../utils/helpers';

@Injectable()
export class AdminGuard implements CanActivate, OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(
    protected authenticationService: AuthenticationService,
    private translationHelper: TranslationHelperService,
    private router: Router,
  ) {
    unsubscribe(this.subscriptions);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let isAdmin = this.authenticationService.isAdmin();

    if (!isAdmin) {
      this.subscriptions.push(
        this.translationHelper.getLanguage().pipe(take(1)).subscribe(
          (language) => {
            this.router.navigate([language + '/login'], {
              queryParams: {
                redirect: state.url
              }
            });
          },
          (err) => console.error(this.constructor.name, 'getLanguage', err),
        ))
    }

    return isAdmin;
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
