import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { SidenavService } from '../services/sidenav-service';

@Directive({
  selector: '[appCloseSidenav]'
})
export class SidenavCloseDirective {

  @Input('appCloseSidenav') sidenavKey: string;

  @HostListener('click') onClick() {
    this.sidenavService.toggle(this.sidenavKey, false);
  }

  constructor(private sidenavService: SidenavService) {

  }
}
