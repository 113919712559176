import { Dataset, MapType } from './dataset';
import { environment } from '../../environments/environment';

export class CrowdsourcingSignalDataset extends Dataset {

  protected mapBaseUrl: string = environment.tileserver.dataLayers.signalPoints.url;

  protected mapPositionInfoUrl?: string;

  public legend = environment.tileserver.dataLayers.signalPoints.legend;

  protected statisticsBaseUrl: string = environment.statistics.backend.url + '/signal';

  protected attribution: string = environment.tileserver.dataLayers.signalPoints.attribution || undefined;

  getMapType(): MapType {
    return MapType.RASTER;
  }
}
