import { Directive, Input, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from '../../utils/helpers';
import { Subscription } from 'rxjs';
import { IndicatorsService } from './indicators.service';

@Directive({ selector: '[saveInputValue]' })
export class SaveInputValueDirective implements OnDestroy {

  private readonly subscriptions: Subscription[] = [];

  @Input('saveInputValue') queryParamName: string;

  set value(val: string) {
    this.indicatorsService.set(this.queryParamName, val);
    this.router.navigate(['./'], {
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  constructor(
    private model: NgModel,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private indicatorsService: IndicatorsService,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.model.valueChanges.subscribe(val => {
        this.value = val;
      }),
    );
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions);
  }

}
