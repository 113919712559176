import { HttpHeaders } from '@angular/common/http';

export function fromKeyValueArray<V>(array: Array<{ key: string, value: V }>): { [key: string]: V } {
  let object = {};

  for (let pair of array) {
    object[pair.key] = pair.value;
  }

  return object;
}

export function forAllCombinations<T = any>(
  sets: { [setName: string]: Array<T> },
  setsNames: Array<string> = Object.keys(sets),
  accumulatedValues: { [setName: string]: Array<T> }
) {
  if (setsNames.length > 1) {

  } else {

  }
}

export function forEachCombination<T = any>(
  sets: { [setName: string]: Array<T> },
  callback: (filter: { [setName: string]: T }) => void,
  keys: Array<string> = Object.keys(sets),
  accumulatedValues: { [setName: string]: T } = {},
) {
  if (keys.length === 0) {
    callback({});
    return;
  }

  let key = keys[0];
  let values = sets[key];

  for (let value of values) {
    let newAccumulatedValues = Object.assign({}, accumulatedValues, { [key]: value });
    if (keys.length > 1) {
      forEachCombination(
        sets,
        callback,
        keys.slice(1),
        newAccumulatedValues,
      );
    } else {
      callback(newAccumulatedValues);
    }
  }
}

export async function forEachCombinationAsync<T = any>(
  sets: { [setName: string]: Array<T> },
  callback: (filter: { [setName: string]: T }) => Promise<void>,
  keys: Array<string> = Object.keys(sets),
  accumulatedValues: { [setName: string]: T } = {},
) {
  if (keys.length === 0) {
    await callback({});
    return;
  }

  let key = keys[0];
  let values = sets[key];

  for (let value of values) {
    let newAccumulatedValues = Object.assign({}, accumulatedValues, { [key]: value });
    if (keys.length > 1) {
      await forEachCombination(
        sets,
        callback,
        keys.slice(1),
        newAccumulatedValues,
      );
    } else {
      await callback(newAccumulatedValues);
    }
  }
}

export function angularHttpHeaderToObject(headers: HttpHeaders): { [name: string]: string } {
  const result: { [name: string]: string } = {};
  const keys = headers.keys();
  for (const key of keys) {
    result[key] = headers.get(key);
  }
  return result;
}
