import { Injectable } from '@angular/core';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { OLMapService } from './ol-map.service';
import GeoJSON from "ol/format/GeoJSON";
import { Control } from 'ol/control';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';


export class LegendControl extends Control { }
export class DetailControl extends Control { }
export class FilterControl extends Control { }

@Injectable({
  providedIn: 'root'
})

export abstract class OlAtlasBaseService {
  eventsKey: EventsKey;

  constructor(
    protected olMapService: OLMapService
  ) {
  }

  addVectorLayer(feature: Feature<Geometry>, format: GeoJSON) {
    const vectorSource: VectorSource<Geometry> = new VectorSource({
      features: [feature],
      format: format,
    });

    const vector: VectorLayer<VectorSource<Geometry>> = new VectorLayer({
      source: vectorSource,
      style: new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "../../../assets/images/marker-icon.png",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 255, 1.0)",
          width: 2,
        }),
      }),
    });

    this.olMapService.layers.push({ name: "vector", layer: vector });
    this.olMapService.map.addLayer(vector);
  }
  removeVectorLayers() {
    this.olMapService.details = [];
    this.olMapService.detailsLoading = false;
    this.olMapService.layers.forEach((l) => {
      if (l.name === "vector") {
        this.olMapService.map.removeLayer(l.layer);
      }
    });
    this.olMapService.layers = this.olMapService.layers.filter((l) => l.name !== "vector");
  }
  getLegends(element: string) {
    this.olMapService.map.getControls().forEach((c) => {
      if (c instanceof LegendControl) {
        this.olMapService.map.removeControl(c)
      }
    })
    var el = document.getElementById(element);
    this.olMapService.map.addControl(new LegendControl({
      element: el
    }))
  }
  getFilter(element: string) {
    this.olMapService.map.getControls().forEach((c) => {
      if (c instanceof FilterControl) {
        this.olMapService.map.removeControl(c)
      }
    })
    var el = document.getElementById(element);
    this.olMapService.map.addControl(new FilterControl({
      element: el
    }))
  }
  getDetails(element: string) {    
    this.olMapService.map.getControls().forEach((c) => {
      if (c instanceof DetailControl) {
        this.olMapService.map.removeControl(c)
      }
    })
    var el = document.getElementById(element);
    this.olMapService.map.addControl(new DetailControl({
      element: el
    }))
  }
  mapOperationsUn() {
    unByKey(this.eventsKey)
  }
}
