import { Area } from "../services/area/area";
import { Observable, Subject } from "rxjs";

export interface FilterConfig {
  label: string;
  key: string;
  choices: { label: string; value: any }[];
  multiple?: boolean;
  compareWith?: (o1: any, o2: any) => boolean;
}

export interface FilterChangeEvent {
  key: string;
  value: any;
}

export class Filter {
  protected changesSubject: Subject<FilterChangeEvent> = new Subject();

  protected data: { [key: string]: any } = {};

  constructor(data: { [key: string]: any } = {}) {
    for (let key in data) {
      let value = data[key];
      if (Array.isArray(value)) {
        this.data[key] = data[key].slice(0);
      } else {
        this.data[key] = data[key];
      }
    }
  }

  set(key: string, value: any): void {
    if (this.data[key] !== value) {
      this.data[key] = value;
      this.changesSubject.next({ key: key, value: value });
    }
  }

  get(key: string): any {
    return this.data[key];
  }

  unset(key: string) {
    delete this.data[key];
  }

  forEach(callback: (key: string, value: any) => void): void {
    for (let key in this.data) {
      callback(key, this.data[key]);
    }
  }

  observeChanges(): Observable<FilterChangeEvent> {
    return this.changesSubject.asObservable();
  }

  getPlain(): any {
    let values: any = {};
    for (let key in this.data) {
      let value = this.data[key];
      if (value && value.getPlain) {
        values[key] = value.getPlain();
      } else {
        values[key] = value;
      }
    }

    return values;
  }

  copy(other: Filter): void {
    other.forEach((key, value) => this.set(key, value));
  }

  clone(): Filter {
    let filter = new Filter();
    for (let key in this.data) {
      filter.set(key, this.data[key]);
    }

    return filter;
  }

  updateWith(model: Filter) {
    model.forEach((key, value) => {
      if (this.get(key) != value) {
        this.set(key, value);
      }
    });
  }

  static fromPlain(plain: any): Filter {
    let filter = new Filter();

    for (let key in plain) {
      let value = plain[key];
      if (value === null) {
        continue;
      }

      if (key === "period") {
        filter.set(key, {
          from: new Date(value.from),
          to: new Date(value.to),
        });
      } else if (key === "area") {
        filter.set(key, Area.fromPlain(value));
      } else {
        filter.set(key, value);
      }
    }

    return filter;
  }
}
