import { TimeSeries } from '../../timeseries';
import { DeclareExtract } from '../../declare-extract.decorator';

@DeclareExtract('count_timeseries')
export class CountTimeseries extends TimeSeries {

  name = 'count_timeseries';

  groupingFunction = (values: Array<number>): number => {
    return values.reduce((a, b) => a + b);
  }

}
