import { Component } from '@angular/core';
import { Dataset } from '../../datasets/dataset';
import { CrowdsourcingSignalDataset } from '../../datasets/crowdsourcing-signal.dataset';
import { Filter, FilterConfig } from '../../datasets/filter';
import { Extract } from '../../datasets/extracts/extract';
import { CountComparison } from '../../datasets/extracts/crowdsourcing/comparison/count-comparison.extract';
import { ClientCountTimeseries } from '../../datasets/extracts/crowdsourcing/timeseries/clientcount-timeseries.extract';
import { DbmComparison } from '../../datasets/extracts/crowdsourcing/comparison/dbm-comparison.extract';
import { ClientCountComparison } from '../../datasets/extracts/crowdsourcing/comparison/clientcount-comparison.extract';
import { DbmTimeseries } from '../../datasets/extracts/crowdsourcing/timeseries/dbm-timeseries.extract';
import { CountTimeseries } from '../../datasets/extracts/crowdsourcing/timeseries/count-timeseries.extract';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { AbstractPresentationPage } from '../abstract-presentation-page/abstract-presentation-page';
import { DOMAIN } from '../../models/domain/domain';
import { comparePeriod, PeriodEnum } from '../../models/domain/period-enum';
import { TechnologyEnum } from '../../models/domain/technology-enum';
import { ChartConfig } from '../../components/abstract-chartjs/abstract-chartjs.component';
import { PROJECT_DESCRIPTIONS } from '../../services/project.service';
import { environment } from '../../../environments/environment';
import { MetadataService } from '../../services/metadata.service';
import { DataSourceDescriptionData } from 'src/app/models/data-source-description-data.model';

@Component({
  selector: 'app-crowdsourcing-page',
  templateUrl: './crowdsourcing-page.component.html',
  styleUrls: ['./crowdsourcing-page.component.scss']
})
export class CrowdsourcingPageComponent extends AbstractPresentationPage {

  dataset: Dataset = new CrowdsourcingSignalDataset();

  mapFilters: FilterConfig[] = [
    {
      label: 'controls.technologies',
      key: 'technologies',
      choices: DOMAIN.technologies,
      multiple: true,
    },
    {
      label: 'controls.period',
      key: 'period',
      choices: DOMAIN.periods,
    },
  ];

  mapFilter: Filter = new Filter({
    technologies: DOMAIN.technologies.map(kv => kv.value),
    period: PeriodEnum._6_MONTHS,
  });

  chartFilters: FilterConfig[] = [
    { label: 'controls.period', key: 'period', choices: DOMAIN.periods, compareWith: comparePeriod },
  ];

  defaultChartFilter = {
    technologies: [TechnologyEnum._2G, TechnologyEnum._3G, TechnologyEnum._4G],
    period: PeriodEnum._6_MONTHS,
  };

  timeseriesDefaultConfig = {
    split: 'TECHNOLOGY',
  };

  timeseriesDefaultConfigList: ChartConfig[] = [{
    label: 'controls.split_by',
    key: 'split',
    choices: [
      { label: 'Technology', value: 'TECHNOLOGY' },
      { label: 'None', value: 'NONE' },
    ],
  }];

  comparisonDefaultConfigList: ChartConfig[] = [
    // {
    //   label: 'controls.compare',
    //   key: 'groupKey',
    //   choices: [
    //     {label: 'Technology', value: 'TECHNOLOGY'},
    //   ],
    // },
    // {
    //   label: 'controls.compare_by',
    //   key: 'comparisonKey',
    //   choices: [
    //     {label: 'Technology', value: 'TECHNOLOGY'},
    //     {label: 'ALL', value: 'ALL'},
    //   ],
    // },
  ];

  extracts: Array<Extract> = [
    new DbmTimeseries({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.timeseriesDefaultConfigList,
      config: this.timeseriesDefaultConfig,
    }),
    new DbmComparison({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.comparisonDefaultConfigList,
      config: { groupKey: 'TECHNOLOGY', comparisonKey: 'ALL' },
    }),
    new CountTimeseries({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.timeseriesDefaultConfigList,
      config: this.timeseriesDefaultConfig,
    }),
    new CountComparison({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.comparisonDefaultConfigList,
      config: { groupKey: 'TECHNOLOGY', comparisonKey: 'ALL' },
    }),
    new ClientCountTimeseries({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.timeseriesDefaultConfigList,
      config: this.timeseriesDefaultConfig,
    }),
    new ClientCountComparison({
      dataset: this.dataset,
      filters: this.chartFilters,
      filter: new Filter(this.defaultChartFilter),
      configList: this.comparisonDefaultConfigList,
      config: { groupKey: 'TECHNOLOGY', comparisonKey: 'ALL' },
    }),
  ];

  dataDescription: DataSourceDescriptionData = PROJECT_DESCRIPTIONS.crowdsourcing;

  constructor(
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
  ) {
    super(statistics);
    this.metadata.setImage(environment.baseHref + 'assets/images/og/data-portal.png');
    this.metadata.setSubtitle('ui.projects.crowdsourcing.title');
    this.metadata.setDescription('ui.projects.crowdsourcing.description');
  }
}
