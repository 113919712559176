import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AreaFilter } from "./area-filter";
import { HttpClient } from "@angular/common/http";
import { objectToQueryString } from "../../utils/url";
import { Area } from "./area";
import { map } from "rxjs/operators";
import { forkJoin, Observable } from "rxjs";

@Injectable()
export class AreaService implements OnDestroy {
  baseUrl: string = environment.statistics.backend.url;

  constructor(protected http: HttpClient) {}
  ngOnDestroy() {}

  search(filter: AreaFilter): Observable<Array<Area>> {
    let query = {
      filter: filter,
    };

    return this.http
      .get<Array<any>>(this.baseUrl + "/areas" + objectToQueryString(query))
      .pipe(map((areas) => areas.map(Area.fromPlain)));
  }

  searchAddress(
    searchFilter: string
  ): Observable<any> {
    if (isNaN(parseInt(searchFilter))) {
      return  this.http.get<Area[]>(
          environment.elasticSearch.municipality + "?name=" + searchFilter
        ).pipe(map((areas) => areas.map(Area.fromPlain)))

    } else {
      return this.http.get<Area[]>(
        environment.elasticSearch.postcode + "?postcode=" + searchFilter
      ).pipe(map((areas) => areas.map(Area.fromPlain)))
    }
  }
  searchStreet(
    searchFilter: string,
    searchStreet: string
  ): Observable<Array<any>> {
    return this.http.get<Area[]>(
      environment.elasticSearch.street +
        "?niscodeOrPostcode=" +
        searchFilter +
        "&name=" +
        searchStreet
    )
  }

  getGeometry(area: Area): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/areas/geometry/" + area.id);
  }

  getCountryGeometry(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/areas/country-geometry");
  }
}
