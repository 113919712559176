import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';

// https://stackoverflow.com/questions/49131094/is-there-a-way-to-make-a-multiselection-in-autocomplete-angular4/51467701
@Component({
  selector: 'app-chips-autocomplete',
  styles: [`
    :host {
      position: relative;
    }

    .chip-remove-icon {
      position: relative;
      bottom: -2px;
    }

    .loader {
      position: absolute;
      right: 0;
      bottom: 5px;
    }

    .loader:not(.shown) {
      display: none;
    }

    .btn-links {
      position: relative;
      top: -15px;
    }

    .btn-link:hover {
      text-decoration: underline;
    }
  `],
  template: `
    <mat-form-field class="w-100">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let val of selectedOptions"
          (removed)="remove(val)">
          {{val}}
          <fa-icon [icon]="icons.delete" matChipRemove class="ml-2 chip-remove-icon">cancel</fa-icon>
        </mat-chip>
        <input
          [placeholder]="placeholder"
          #input
          [formControl]="control"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="selected($event)"
                        [autoActiveFirstOption]="true"
      >
        <cdk-virtual-scroll-viewport itemSize="48" style="height: 256px">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
          <mat-option *ngIf="((filteredOptions | async) || []).length <= 0" [disabled]="true">No data</mat-option>
          <mat-option *ngIf="isResultCropped" [disabled]="true">Some results were not displayed, please be more specific in your search</mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
      <fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
    </mat-form-field>
    <div class="btn-links">
      <a (click)="selectAll()" class="btn-link mr-3">{{'indicators.buttons.selectAll' | translate}} ({{placeholder.toLowerCase()}})</a>
      <a (click)="unselectAll()" class="btn-link">{{'indicators.buttons.unselectAll' | translate}} ({{placeholder.toLowerCase()}})</a>
    </div>
  `
})
export class ChipsAutocompleteComponent implements OnChanges {

  @Input() choices: string[] = [];
  @Input() placeholder: string;

  _selectedOptions: string[] = [];
  @Output() selectedOptionsChange = new EventEmitter();

  get selectedOptions(): string[] {
    return this._selectedOptions;
  }

  @Input()
  set selectedOptions(vals: string[]) {
    this._selectedOptions = vals;
    this.selectedOptionsChange.emit(vals);
  }

  readonly icons = {
    delete: faTimes,
    falSpinnerThird: faSpinnerThird,
  };
  separatorKeysCodes: number[] = [ENTER, COMMA];
  control = new FormControl(null);
  availableChoices: string[] = [];
  filteredOptions: Observable<string[]>;
  maxResults = 10;

  loading = false;
  hasTerms = false;
  isResultCropped = false;

  @ViewChild('input') input: ElementRef;

  constructor() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(null as string),
      tap(val => this.hasTerms = !!val),
      debounceTime(300),
      distinctUntilChanged((x, y) => !!x && !!y && x === y),
      tap(() => this.loading = true),
      map((terms: string | null) => {
        if (terms && terms.length >= 3) {
          return this.filter(terms);
        }
        const cropped = this.availableChoices.slice(0, 50);
        if (cropped.length !== this.availableChoices.length) {
          this.isResultCropped = true;
        }
        return cropped;
      }),
      tap(() => this.loading = false),
    );
  }

  private resetAvailableChoices() {
    this.availableChoices = this.choices
      .filter(val => this.selectedOptions.indexOf(val) === -1);
    this.control.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;

    if (!(value || '').trim() || this.availableChoices.indexOf(value) === -1) {
      return;
    }

    this.availableChoices = this.availableChoices.filter(val => val !== value);
    this.selectedOptions.push(value);
    this.control.setValue(null);
    event.input.value = '';
  }

  remove(val: string): void {
    this.selectedOptions = [...this.selectedOptions.filter(option => option !== val)];
    this.resetAvailableChoices();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions = [...this.selectedOptions, event.option.value];
    this.availableChoices = this.availableChoices.filter(val => val !== event.option.value);
    this.control.setValue(null);
    this.input.nativeElement.value = '';
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const results = this.availableChoices
      .filter(val => !this.control.value || val !== this.control.value)
      .filter(val => val.toLowerCase().indexOf(filterValue) === 0);

    const croppedResults = results.slice(0, this.maxResults);

    this.isResultCropped = results.length !== croppedResults.length;

    return croppedResults;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['choices']) {
      this.resetAvailableChoices();
    }
  }

  selectAll() {
    this.selectedOptions = [...this.choices];
    this.resetAvailableChoices();
  }

  unselectAll() {
    this.selectedOptions = [];
    this.resetAvailableChoices();
  }
}
