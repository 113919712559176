<div class="container">
  <fa-icon class="loader" [class.shown]="!(atlasFtthFilterService.atlasFtthService.olMapService.filters[0].choices.length > 0)" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
  <ng-container *ngIf="atlasFtthFilterService.atlasFtthService.olMapService.filters[0].choices.length > 0">
    <app-area-select
      [(selectedArea)]="selectedArea"
      (selectedAreaChange)="onSearchArea($event)"
    ></app-area-select>
    <div >
      <mat-form-field *ngFor="let fil of atlasFtthFilterService.atlasFtthService.olMapService.filters" class="w-100">
        <mat-select
          [placeholder]="fil.label | translate"
          [ngModel]="atlasFtthFilterService.atlasFtthService.olMapService.filter.get(fil.key)"
          (ngModelChange)="setFilter(fil.key, $event)"
          [multiple]="fil.multiple"
        >
          <mat-option
            *ngFor="let choice of fil.choices"
            [value]="choice.value"
            >{{ choice.label | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <span
        >{{ "labels.selectedYear" | translate }}:
        {{ atlasFtthFilterService.atlasFtthService.layerPostfix }}
      </span>
      <mat-slider
        thumbLabel
        [displayWith]="formatLabel"
        tickInterval="1"
        step="0.25"
        [min]=Min_Quarter
        max="{{ max }}"
        [(ngModel)]="atlasFtthFilterService.value"
        aria-label="units"
        (change)="onChangeSlider(atlasFtthFilterService.value)"
      ></mat-slider>
    </div>
  </ng-container>
</div>
