import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { Extract } from "../../datasets/extracts/extract";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { FilterChangeEvent } from "../../datasets/filter";

@Component({
  selector: "app-extract-info",
  templateUrl: "./extract-info.component.html",
  styleUrls: ["./extract-info.component.scss"],
})
export class ExtractInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() extract: Extract;

  info: string = "";

  language: string;

  languageSubscription: Subscription;
  infoSubscription: Subscription;
  filterSubscription: Subscription;

  cache: { [url: string]: string } = {};

  constructor(
    protected translationHelper: TranslationHelperService,
    protected http: HttpClient
  ) {
    this.unSubscribeAll();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("extract" in changes) {
      this.observeFilterChanges();
      this.updateInfo();
    }
  }

  ngOnInit() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
    this.languageSubscription = this.translationHelper.getLanguage().subscribe(
      (language) => {
        if (language !== this.language) {
          this.language = language;
          this.updateInfo();
        }
      },
      (error) => console.log(this.constructor.name, "getLanguage", error)
    );
  }

  ngOnDestroy() {
    this.unSubscribeAll();
  }
  unSubscribeAll() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }

    if (this.infoSubscription) {
      this.infoSubscription.unsubscribe();
    }

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  observeFilterChanges() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.extract && this.extract.filter) {
      this.filterSubscription = this.extract.filter.observeChanges().subscribe(
        (change: FilterChangeEvent) => {
          this.updateInfo();
        },
        (error) =>
          console.log(this.constructor.name, "observeFilterChanges", error)
      );
    }
  }

  updateInfo() {
    if (this.infoSubscription) {
      this.infoSubscription.unsubscribe();
    }

    if (this.language && this.extract && this.extract.getInformationUrl()) {
      let language = this.language;
      let url = this.extract.getInformationUrl().replace(":language", language);

      if (this.cache[url] !== undefined) {
        this.info = this.cache[url];
      } else {
        this.infoSubscription = this.http
          .get(url, { responseType: "text" })
          .subscribe(
            (info) => {
              this.info = info;
              this.cache[url] = info;
            },
            (error) => console.log(this.constructor.name, "get " + url, error)
          );
      }
    }
  }
}
