import { Component, OnInit } from '@angular/core';
import { OLLegendDefinition } from 'src/app/models/layer';

@Component({
  selector: 'app-atlas-fix-legend',
  templateUrl: './atlas-fix-legend.component.html',
  styleUrls: ['./atlas-fix-legend.component.scss']
})
export class AtlasFixLegendComponent implements OnInit {

  legend: OLLegendDefinition;

  ngOnInit() {

    this.legend = {
      values: [
        { position: 0, label: "0" },
        { position: 1, label: "50" },
        { position: 2, label: "100" },
      ],
    };
  }
}