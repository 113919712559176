import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { unsubscribe } from "../../utils/helpers";
import { Subscription } from "rxjs";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { DataSourceDescriptionData } from 'src/app/models/data-source-description-data.model';

@Component({
  selector: "app-data-description",
  templateUrl: "./data-description.component.html",
  styleUrls: ["./data-description.component.scss"],
})
export class DataDescriptionComponent implements OnInit, OnDestroy {
  @Input() data: DataSourceDescriptionData;
  locale: string;
  datePipe: DatePipe;
  showValidAndTestText: boolean = true;

  private subscriptions: Subscription[] = [];

  constructor(
    protected translationHelperService: TranslationHelperService,
    protected _router: Router,
  ) {
    unsubscribe(this.subscriptions)
    this.subscriptions.push(
      translationHelperService.getLocale().subscribe((locale) => {
        this.locale = locale;
        this.datePipe = new DatePipe(locale);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  ngOnInit() {
    const currentRoute = this._router.routerState.snapshot.url.split("/").pop();
    this.showText(currentRoute);
  }
  showText(currentRoute: string) {
    if (!(currentRoute === "landline")) {
      return;
    }
    this.showValidAndTestText = false;
  }

  getDescription() {
    if (!this.locale) {
      return;
    }
    const prop = this.locale.replace("-", "_");
    return this.data.description[prop];
  }

  getLocalizedDate(lastValidatedAt: Date): string {
    return this.datePipe
      ? this.datePipe.transform(lastValidatedAt, "MMMM y")
      : "N/A";
  }
}
