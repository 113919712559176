import { TimeSeries } from '../../timeseries';
import { DeclareExtract } from '../../declare-extract.decorator';

@DeclareExtract('dbm_timeseries')
export class DbmTimeseries extends TimeSeries {

  name = 'dbm_timeseries';

  groupingFunction = (values: Array<number>): number => {
    let sum = 0;
    for (let value of values) {
      sum += DbmTimeseries.dbmToMilliwats(value);
    }
    return Math.round(DbmTimeseries.milliwatsToDbm(sum / values.length));
  };

  public static milliwatsToDbm(milliwats: number): number {
    return 10 * Math.log10(milliwats);
  }

  public static dbmToMilliwats(dbm: number): number {
    return Math.pow(10, dbm / 10);
  }
}
