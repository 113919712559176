import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';
import { DOMAIN } from '../../../models/domain/domain';

@DeclareExtract('repair_delay_comparison')
export class RepairDelayComparisonExtract extends AbstractBarometerExtract {

  name = 'repair_delay_comparison';

  xUnit = 'hours';

  get title() {
    return this.name + '.' + this.filter.get('measure');
  }

  choices = {
    measure: ['PERCENTILE_80', 'PERCENTILE_95'],
    dataLabel: DOMAIN.barometerPeriods,
  };
}
