import { BrowserModule, TransferState } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { StatisticsService } from "./services/statistics/statistics.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ChartDistributionComponent } from "./components/chart-distribution/chart-distribution.component";
import { TranslationHelperService } from "./services/translations/translation-helper.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ChartTimeseriesComponent } from "./components/chart-timeseries/chart-timeseries.component";
import { AreaService } from "./services/area/area.service";
import { ChartComparisonComponent } from "./components/chart-comparison/chart-comparison.component";
import "./components/chart-comparison/comparison-bar.chart";
import { MapComponent } from "./components/map/map.component";
import { CrowdsourcingPageComponent } from "./pages/crowdsourcing-page/crowdsourcing-page.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { AdminGuard } from "./guards/AdminGuard";
import { AuthenticatedGuard } from "./guards/AuthenticatedGuard";
import { AuthenticationService } from "./services/authentication/authentication.service";
import { AtlasMobilePageComponent } from "./pages/atlas-mobile-page/atlas-mobile-page.component";
import { BarometerPageComponent } from "./pages/barometer-page/barometer-page.component";
import { AtlasFixPageComponent } from "./pages/atlas-fix-page/atlas-fix-page.component";
import { MapLegendComponent } from "./components/map-legend/map-legend.component";
import { ExtractInfoComponent } from "./components/extract-info/extract-info.component";
import { LanguageResolver } from "./services/language-resolver";
import { ProjectLayoutComponent } from "./pages/project-layout/project-layout.component";
import { ProjectService } from "./services/project.service";
import { ProjectResolver } from "./services/project-resolver.service";
import { FooterComponent } from "./components/footer/footer.component";
import { ChartFiltersComponent } from "./components/abstract-chartjs/chart-filters/chart-filters.component";
import { MapFiltersComponent } from "./components/map/map-filters/map-filters.component";
import { BootstrapMediaService } from "./services/bootstrap-media.service";
import { StoreBadgeComponent } from "./components/store-badge/store-badge.component";
import { SidenavService } from "./services/sidenav-service";
import { SidebarModule } from "ng-sidebar";
import { NavLinkComponent } from "./components/nav-link/nav-link.component";
import { LangRouterLinkDirective } from "./directives/lang-router-link.directive";
import { SidenavCloseDirective } from "./directives/sidenav-close.directive";
import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { DriveTestPageComponent } from "./pages/drive-test-page/drive-test-page.component";
import { OpendataPageComponent } from "./pages/opendata-page/opendata-page.component";
import { ApplicationsPageComponent } from "./pages/applications-page/applications-page.component";
import { DownloadAppBannerComponent } from "./pages/download-app-banner/download-app-banner.component";
import { DataDescriptionComponent } from "./components/data-description/data-description.component";
import { RedirectToLanguageGuard } from "./guards/RedirectToLanguageGuard";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { TranslateBrowserLoader } from "./services/translations/translate-browser-loader.service";
import "../../node_modules/moment/locale/de.js";
import "../../node_modules/moment/locale/fr.js";
import "../../node_modules/moment/locale/nl.js";
import { TermsPageComponent } from "./pages/terms-page/terms-page.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { IndicatorsPageComponent } from "./pages/indicators/indicators-page.component";
import { IndicatorsValuesComponent } from "./pages/indicators/indicators-values/indicators-values.component";
import { IndicatorChartComponent } from "./pages/indicators/indicators-chart/indicator-chart.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { IndicatorsDetailsComponent } from "./pages/indicators/indicators-details/indicators-details.component";
import { IsBrowserDirective } from "./directives/is-browser.directive";
import { IndicatorButtonsComponent } from "./pages/indicators/indicator-buttons/indicator-buttons.component";
import { ChipsAutocompleteComponent } from "./pages/indicators/chips-autocomplete/chips-autocomplete.component";
import { SaveInputValueDirective } from "./pages/indicators/save-input-value.directive";
import { SocialButtonsComponent } from "./pages/indicators/social-buttons/social-buttons.component";
import { AtlasFtthPageComponent } from "./pages/atlas-ftth-page/atlas-ftth-page.component";
import { AtlasFixMapComponent } from "./components/ol-map/components/atlas-fix-map/atlas-fix-map.component";
import { AtlasFtthMapComponent } from "./components/ol-map/components/atlas-ftth-map/atlas-ftth-map.component";
import { AtlasMobileMapComponent } from "./components/ol-map/components/atlas-mobile-map/atlas-mobile-map.component";
import { OlMapComponent } from "./components/ol-map/ol-map.component";
import { AtlasFixFilterComponent } from "./components/ol-map/ol-map-filters/components/atlas-fix-filter/atlas-fix-filter.component";
import { AtlasFixDescriptionComponent } from "./components/data-description/components/atlas-fix-description/atlas-fix-description.component";
import { AtlasFtthFilterComponent } from "./components/ol-map/ol-map-filters/components/atlas-ftth-filter/atlas-ftth-filter.component";
import { AtlasFtthDescriptionComponent } from "./components/data-description/components/atlas-ftth-description/atlas-ftth-description.component";
import { AtlasMobileFilterComponent } from "./components/ol-map/ol-map-filters/components/atlas-mobile-filter/atlas-mobile-filter.component";
import { AtlasMobileDescriptionComponent } from "./components/data-description/components/atlas-mobile-description/atlas-mobile-description.component";
import { AtlasFixLegendComponent } from "./components/ol-map/ol-map-legend/components/atlas-fix-legend/atlas-fix-legend.component";
import { AtlasFtthLegendComponent } from "./components/ol-map/ol-map-legend/components/atlas-ftth-legend/atlas-ftth-legend.component";
import { AtlasMobileLegendComponent } from "./components/ol-map/ol-map-legend/components/atlas-mobile-legend/atlas-mobile-legend.component";
import { AtlasPageComponent } from "./pages/atlas-page/atlas-page.component";
import { DataPageComponent } from "./pages/data-page/data-page.component";
import { TreinmetingenModule } from "./treinmetingen/treinmetingen.module";
import { SharedModule } from "./_shared/shared.module";

export function exportTranslateStaticLoader(
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateBrowserLoader(
    "/assets/i18n/",
    ".json",
    transferState,
    http
  );
}

@NgModule({
  imports: [
    // Angular
    BrowserModule.withServerTransition({ appId: "bipt-data-app" }), // Angular Universal
    TransferHttpCacheModule, // Angular Universal
    AppRoutingModule,
    SharedModule,
    TreinmetingenModule,
    // Vendor
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState],
      },
    }),
    TooltipModule.forRoot(),
    SidebarModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    ChartDistributionComponent,
    ChartTimeseriesComponent,
    ChartComparisonComponent,
    MapComponent,
    CrowdsourcingPageComponent,
    HeaderComponent,
    HomePageComponent,
    LoginPageComponent,
    BarometerPageComponent,
    TermsPageComponent,
    MapLegendComponent,
    ExtractInfoComponent,
    ProjectLayoutComponent,
    FooterComponent,
    ChartFiltersComponent,
    MapFiltersComponent,
    AtlasFixMapComponent,
    AtlasFixLegendComponent,
    AtlasFixFilterComponent,
    AtlasFixDescriptionComponent,
    AtlasFixPageComponent,
    AtlasFtthMapComponent,
    AtlasFtthLegendComponent,
    AtlasFtthFilterComponent,
    AtlasFtthDescriptionComponent,
    AtlasFtthPageComponent,
    AtlasMobileMapComponent,
    AtlasMobileLegendComponent,
    AtlasMobileFilterComponent,
    AtlasMobileDescriptionComponent,
    AtlasMobilePageComponent,
    OlMapComponent,
    StoreBadgeComponent,
    NavLinkComponent,
    LangRouterLinkDirective,
    SidenavCloseDirective,
    LanguageSelectorComponent,
    DriveTestPageComponent,
    ApplicationsPageComponent,
    DownloadAppBannerComponent,
    OpendataPageComponent,
    DataDescriptionComponent,
    IndicatorsPageComponent,
    IndicatorsValuesComponent,
    IndicatorChartComponent,
    LoaderComponent,
    IndicatorsDetailsComponent,
    IsBrowserDirective,
    IndicatorButtonsComponent,
    ChipsAutocompleteComponent,
    SaveInputValueDirective,
    SocialButtonsComponent,
    AtlasPageComponent,
    DataPageComponent,
  ],
  providers: [
    StatisticsService,
    TranslationHelperService,
    AreaService,
    AuthenticationService,
    AuthenticatedGuard,
    RedirectToLanguageGuard,
    AdminGuard,
    LanguageResolver,
    ProjectService,
    ProjectResolver,
    BootstrapMediaService,
    SidenavService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
