import { Injectable } from '@angular/core';
import { AtlasFtthService } from '../ol-map/atlas-ftth.service';
import { formatYearQuarter } from 'src/app/utils/helpers';

@Injectable({
  providedIn: 'root'
})
export class AtlasFtthFilterService {
  value: any | undefined;

  constructor(
    public atlasFtthService: AtlasFtthService
  ) { }

  setFilter(filter: string, value: any) {
    this.atlasFtthService.olMapService.filter.set(filter, value);
    this.atlasFtthService.loadLayer();
  }
  setQueryParams() {
    this.atlasFtthService.eventsKey = this.atlasFtthService.olMapService.map.on('moveend', () => {
      this.atlasFtthService.changeUrl();
    })
  }

  changeSlider(value: number): string {
    this.atlasFtthService.layerPostfix = formatYearQuarter(value);
    this.atlasFtthService.loadLayer()
    this.atlasFtthService.changeUrl()
    return this.atlasFtthService.layerPostfix
  }
}
