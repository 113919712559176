interface Condition {
  group?: string;
  short?: string;
  breakdown?: string;
}

export interface PreSelection {
  subjects: string[];
}

const preSelections: { condition: Condition, preSelection: PreSelection }[] = [
  {
    condition: {
      breakdown: 'Municipality',
    },
    preSelection: {
      subjects: ['Brussel', 'Namen', 'Antwerpen'],
    }
  },
];

export function getPreSelection(group: string, short: string, breakdown: string): PreSelection {
  return preSelections
    .map(entry => {
      let count = 0;
      if (entry.condition.group === group) {
        count++;
      }
      if (entry.condition.short === short) {
        count++;
      }
      if (entry.condition.breakdown === breakdown) {
        count++;
      }
      return {
        preSelection: entry.preSelection,
        count: count,
      };
    })
    .sort((a, b) => b.count - a.count)
    .find(val => val.count !== 0)
    ?.preSelection
    ;
}
