import { Injectable } from '@angular/core';
import { concat, filter, map, Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from '../utils/helpers';

export class SidenavToggleEvent {
  key: string;
  open: boolean;

  constructor(key: string, open: boolean) {
    this.key = key;
    this.open = open;
  }
}

@Injectable()
export class SidenavService {

  private state: Map<string, boolean> = new Map();

  private onToggle: Subject<SidenavToggleEvent> = new Subject<SidenavToggleEvent>();

  public watch(key: string): Observable<boolean> {
    const initialValue: Observable<boolean> = of(this.state.get(key));
    const stream: Observable<boolean> = this.onToggle.asObservable().pipe(
      filter(e => e.key === key),
      map(e => e.open),
    );
    return concat(initialValue, stream);
  }

  private publish(key: string): void {
    this.onToggle.next(new SidenavToggleEvent(key, this.state.get(key)));
  }

  public init(key: string, open: boolean): void {
    this.state.set(key, open);
    this.publish(key);
  }

  public toggle(key: string, open?: boolean) {
    this.state.set(key, isNullOrUndefined(open) ? !this.state.get(key) : open);
    this.publish(key);
  }
}

export enum SidenavKey {
  MAIN = 'MAIN',
}
