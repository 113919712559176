<div class="legend-container" [style.width]="width + 2 +'px'">
  <img [src]="source" class="legend">

  <div class="legend-values">
    <div *ngFor="let entry of valuesEntries" class="legend-value" [style.left]="entry.position * 100 +'%'">
      <div class="legend-label">{{ entry.label | label | async }}</div>
      <div class="legend-mark" [style.height]="height + 8 +'px'"></div>
    </div>
  </div>
</div>
