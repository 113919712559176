import { Authority } from './Authority';

export class ClientState {

  id: string;

  authorities: Array<Authority> = [];

  toPlain(): any {
    return {
      id: this.id,
      authorities: this.authorities.map(authority => ({ authority: Authority[authority] })),
    };
  }

  static fromPlain(plain: any): ClientState {
    let clientState = new ClientState();
    if (plain) {
      clientState.id = plain.id;
      if (plain.authorities) {
        clientState.authorities = plain.authorities.map(serializedAuthority => Authority[serializedAuthority.authority]);
      }
    }

    return clientState;
  }

  static fromAuthorizationString(str: string): ClientState {
    let elements = str
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .split('.')
      ;
    let payload = JSON.parse(atob(elements[1]));

    return ClientState.fromPlain(payload.tokenState);
  }

}
