import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { concat, Observable, ReplaySubject, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { DEFAULT_LOCALE, locales, SUPPORTED_LOCALES } from "../../locales";
import { registerLocaleData } from "@angular/common";
import { first, flatMap, map, toArray } from "rxjs/operators";
import * as moment from "moment";
import { unsubscribe } from 'src/app/utils/helpers';

@Injectable()
export class TranslationHelperService implements OnDestroy {
  subscriptions: Subscription[] = [];
  readonly LANGUAGES_REGEX: RegExp;
  readonly locales = SUPPORTED_LOCALES;
  readonly languages: string[];

  private configured: boolean = false;

  fallbackLocale: string = DEFAULT_LOCALE;
  currentLocale: string;

  protected localeSubject = new ReplaySubject<string>(1);

  constructor(protected translate: TranslateService, protected router: Router) {
    unsubscribe(this.subscriptions);
    this.languages = this.locales.map((locale) => locale.split("-")[0]);
    this.LANGUAGES_REGEX = new RegExp(
      "^\\/" + this.languages.map((lang) => `(${lang})`).join("|"),
      "i"
    );
    this.subscriptions.push(
      this.getLocale().subscribe((locale) => {
        // console.log(this.constructor.name, 'Chosen locale:', locale);
        this.currentLocale = locale;
      }))
  }


  init() {
    if (this.configured) {
      throw new Error("Already initialized");
    }

    this.configured = true;

    this.translate.setDefaultLang(this.fallbackLocale);
  }

  public onRouteEvent(language: string) {
    if (language) {
      let locale = language + "-BE";
      if (locale !== this.currentLocale) {
        // console.log(this.constructor.name, 'Switching locale (route with locale):', locale);
        this.changeLocale(locale);
      }
    } else {
      let defaultLocale = this.getDefaultLocale();
      // console.log(this.constructor.name, 'Switching locale (route without locale):', defaultLocale);
      this.changeLocale(defaultLocale);
    }
  }

  changeLocale(locale: string): void {
    let language = locale.split("-")[0];
    moment.locale(language);
    registerLocaleData(locales[locale].data);
    this.subscriptions.push(
      this.translate.use(locale).subscribe(
        () => this.localeSubject.next(locale)
        // (err) => console.log(this.constructor.name, 'changeLocale', JSON.stringify(err)),
      ))
  }

  getLocale(): Observable<string> {
    return this.localeSubject.asObservable();
  }

  getLanguage(): Observable<string> {
    return this.localeSubject
      .asObservable()
      .pipe(map((locale) => locale.split("-")[0]));
  }

  getDefaultLanguage() {
    return this.getDefaultLocale().split("-")[0];
  }

  getDefaultLocale() {
    let locale = (navigator && navigator.language) || "";

    if (locales[locale] === undefined) {
      locale = locale.split("-")[0].toLocaleLowerCase() + "-BE";
      if (locales[locale] === undefined) {
        locale = this.fallbackLocale;
      }
    }
    // console.log(this.constructor.name, 'getDefaultLocale', locale);
    return locale;
  }

  get(key: string): Observable<string> {
    return this.translate.get(key);
  }

  getAll(...keys: Array<string>): Observable<Array<string>> {
    return concat(...keys.map((key) => this.translate.get(key))).pipe(
      toArray()
    );
  }

  getAllLabels(...keys: Array<string>): Observable<Array<string>> {
    const prefix = "labels.";
    return concat(...keys.map((key) => this.translate.get(prefix + key))).pipe(
      map((translation) =>
        translation.startsWith(prefix)
          ? translation.substr(prefix.length)
          : translation
      ),
      toArray()
    );
  }

  observe(key: string): Observable<string> {
    return this.getLocale().pipe(flatMap((locale) => this.translate.get(key)));
  }

  observeAll(...keys: Array<string>): Observable<Array<string>> {
    return this.getLocale().pipe(
      flatMap((locale) =>
        concat(...keys.map((key) => this.translate.get(key))).pipe(toArray())
      )
    );
  }

  observeAllLabels(...keys: Array<string>): Observable<Array<string>> {
    const prefix = "labels.";
    return this.getLocale().pipe(
      flatMap((locale) =>
        concat(...keys.map((key) => this.translate.get(prefix + key))).pipe(
          map((translation) =>
            translation.startsWith(prefix)
              ? translation.substr(prefix.length)
              : translation
          ),
          toArray()
        )
      )
    );
  }

  getLocalizedMoment(): Observable<moment.Moment> {
    return this.getLocale().pipe(
      first(),
      map((locale) => {
        let localizedMoment = moment();
        localizedMoment.locale(locale);

        return localizedMoment;
      })
    );
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
