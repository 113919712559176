import { Component } from '@angular/core';

@Component({
  selector: 'app-download-app-banner',
  templateUrl: './download-app-banner.component.html',
  styleUrls: ['./download-app-banner.component.scss']
})
export class DownloadAppBannerComponent {

}
