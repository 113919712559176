import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
  Resolve,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlSegmentGroup,
  UrlTree
} from '@angular/router';
import { TranslationHelperService } from './translations/translation-helper.service';
import { Observable, Subscription } from 'rxjs';
import { unsubscribe } from '../utils/helpers';

@Injectable()
export class LanguageResolver implements Resolve<any>, OnDestroy {
  subscriptions: Subscription[] = [];
  constructor(
    private translationHelperService: TranslationHelperService,
    private router: Router,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.translationHelperService.getLanguage().subscribe(lang => {
        const tree: UrlTree = router.parseUrl(this.router.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (g) {
          const s: UrlSegment[] = g.segments;
          s[0].path = lang;
          this.router.navigateByUrl(tree);
        } else {
          // console.debug('skipping language redirect as \'tree.root.children[PRIMARY_OUTLET]\' is undefined');
        }
      }))
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const language = route.paramMap.get('language');

    if (this.translationHelperService.languages.indexOf(language) === -1) {
      const defaultLocale: string = this.translationHelperService.getDefaultLanguage();
      const newRoute = [defaultLocale].concat([this.router.url]).join('');
      this.router.navigateByUrl(newRoute);
    }

    this.translationHelperService.onRouteEvent(language);

    return language;
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
