import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';
import { DOMAIN } from '../../../models/domain/domain';

@DeclareExtract('respect_date_start_comparison')
export class RespectDateStartComparisonExtract extends AbstractBarometerExtract {

  name = 'respect_date_start_comparison';

  xUnit = '%';

  choices = {
    service: {
      'TV': 'TV',
      'INTERNET': 'INTERNET',
      'PHONELINE': 'PHONELINE',
      // 'ALL': undefined,
    },
    dataLabel: DOMAIN.barometerPeriods,
  };

  getInformationUrl() {
    return this.filter.get('service') ? this.informationUrlBase + this.name + '.' + this.filter.get('service') + '.html' : undefined;
  }
}
