import { Injectable } from "@angular/core";
import Map from "ol/Map";
import Layer from "ol/layer/Layer";
import { Dataset } from "src/app/datasets/dataset";
import { Filter, FilterConfig } from "src/app/datasets/filter";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import View from "ol/View";
import { transformExtent } from "ol/proj";
import { ATTRIBUTION, DEFAULT_EXTENT } from "src/assets/constants/constants";
import { defaults as defaultInteractions } from "ol/interaction";
import { defaults as defaultControls } from "ol/control";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { DatasetEntry, Datasets } from "src/app/components/map/map.component";
import { setBuffer } from "src/app/utils/helpers";
import Ol3Parser from "jsts/org/locationtech/jts/io/Ol3Parser";
import {
  LinearRing,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "ol/geom";
import { Area } from "../area/area";
import { MapConfig } from "src/app/datasets/map-config";

@Injectable({
  providedIn: "root",
})
export class OLMapService {
  mapConfig: MapConfig;
  datasetEntry: DatasetEntry;

  details: Array<{
    label: string;
    value?: string;
    ratio?: number;
  }>;
  parser: Ol3Parser = new Ol3Parser();

  layers: Array<{ name: string; layer: Layer; workspace?: string }> = [];

  detailsLoading: boolean = false;
  map: Map;

  datasets: Datasets = [];
  dataset: Dataset;
  filter: Filter;
  filters: FilterConfig[];

  constructor() {
    this.parser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );
  }

  createMap() {
    const interactions = defaultInteractions({
      altShiftDragRotate: false,
      pinchRotate: false,
    });
    const controls = defaultControls({ rotate: false });
    this.map = new Map({
      interactions: interactions,
      controls: controls,
      target: "map",
      layers: [
        new TileLayer({
          maxZoom: 18,
          source: new XYZ({
            url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attributions: ATTRIBUTION,
          }),
        }),
      ],
      view: new View({
        center: [0, 0],
        minZoom: 6,
        maxZoom: 18,
      }),
    });
    this.map
      .getView()
      .fit(transformExtent(DEFAULT_EXTENT, "EPSG:4326", "EPSG:3857"));
    this.map.getView();
  }
  setProj4Defs() {
    proj4.defs(
      "EPSG:31370",
      "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs"
    );
    register(proj4);
  }
  searchArea(area: Area) {
    if (!(area && (area.center || area.contour))) return;

    if (area.center) {
      const geom = setBuffer(area.center.coordinates[0], this.parser);
      this.map.getView().fit(geom.getExtent());
    }
    if (area.contour) {
      const geom = new MultiPolygon(area.contour.coordinates[0]).transform(
        "EPSG:31370",
        "EPSG:3857"
      );
      this.map.getView().fit(geom.getExtent());
    }
  }

  readWfsCapabilities(
    val: string
  ): Array<{ featureType: string; name: string }> {
    const capabilities = new DOMParser().parseFromString(val, "text/xml");
    const featureTypeList = capabilities.querySelector("FeatureTypeList");
    const featureTypes = featureTypeList.getElementsByTagName("FeatureType");

    let featureDescriptor = [];
    for (let i = 0; i < featureTypes.length; i++) {
      let featureType = featureTypes[i];
      let name = featureType.getElementsByTagName("Name")[0]?.textContent;
      featureDescriptor.push({ featureType: featureType, name: name });
    }

    return featureDescriptor;
  }
}
