import { RGBColor } from './RGBColor';
// @ts-ignore
import * as randomColor from 'randomcolor';

export function labelToColor(label: string): RGBColor {
  switch (label) {
    case 'Proximus':
      return new RGBColor(92, 45, 145);

    case 'Orange':
    case 'Orange B':
      return new RGBColor(255, 121, 0);

    case 'Base':
    case 'BASE':
    case 'Base - Telenet Group':
    case 'Base / Telenet Group':
    case 'Telenet/Base':
      return new RGBColor(0, 151, 147);

    case 'Scarlet':
      return new RGBColor(239, 62, 51);

    case 'VOO':
      return new RGBColor(212, 0, 122);

    case 'Telenet':
      return new RGBColor(255, 196, 33);

    case 'SFR':
      return new RGBColor(226, 0, 26);

    case 'Mobile Vikings':
      return new RGBColor(0, 0, 0);

    case 'Jim Mobile':
      return new RGBColor(52, 94, 156);

    case 'Carrefour Mobile':
      return new RGBColor(0, 89, 169);

    case 'Ortel Mobile':
      return new RGBColor(0, 0x46, 0x6F);

    case '_2G':
    case '2G':
      return new RGBColor(83, 170, 167);
    case '_3G':
    case '3G':
      return new RGBColor(202, 77, 30);
    case '_4G':
    case '4G':
      return new RGBColor(230, 172, 28);


    default:
      return RGBColor.fromArray(<any>randomColor({ seed: label, format: 'rgbArray' }));
  }
}
