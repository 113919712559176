import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { TranslationHelperService } from './services/translations/translation-helper.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from './utils/helpers';
import { Project } from './models/project';
import { ProjectService } from './services/project.service';
import { environment } from '../environments/environment';
import { TitleCasePipe } from '@angular/common';
import { MetadataService } from './services/metadata.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  titleCasePipe: TitleCasePipe = new TitleCasePipe();

  projects: Project[];
  selectedProject: Project;

  hideFooter: boolean = false;

  private subscriptions: Subscription[] = [];
  isDev: boolean = !environment.production;



  constructor(
    protected translationHelper: TranslationHelperService,
    protected projectService: ProjectService,
    protected metadata: MetadataService,
  ) {
    unsubscribe(this.subscriptions)
    this.translationHelper.init();
    this.projects = this.projectService.projects;
    this.metadata.setCommon();
    this.subscriptions.push(
      this.translationHelper.observeAll('ibpt', 'data_portal').subscribe(
        (translations) => {
          this.metadata.setTitle(translations[0] + ' | ' + this.titleCasePipe.transform(translations[1]));
        },
        (err) => console.error(this.constructor.name, 'observe title translations', err),
      ),
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.projectService.getSelected().subscribe(project => {
        this.selectedProject = project;
        this.hideFooter = project && project.hideFooter;
      }),
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
