import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
import { HttpClient } from "@angular/common/http";
import {
  setBackgroudTable,
  setTextColor,
  unsubscribe
} from "../../../utils/helpers";
import { BreakpointState } from "@angular/cdk/layout";
import {
  BootstrapMediaBreakpoint,
  BootstrapMediaService,
} from "../../../services/bootstrap-media.service";

import Map from "ol/Map";
import { Observable, Subscription } from "rxjs";
import GeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import {
  Geometry,
  LinearRing,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "ol/geom";
import Ol3Parser from "jsts/org/locationtech/jts/io/Ol3Parser";
import { DatasetEntry } from "src/app/models/dataset-entry";
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';

@Component({ template: "" })
export abstract class AtlasBaseMapComponent {
  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  parser: Ol3Parser = new Ol3Parser();

  @Input() datasetEntry: DatasetEntry;
  @Input() allowMultipleFilterValues: boolean = true;
  @Output() filterSet = new EventEmitter<{ key: string; value: any }>();

  subscriptions: Subscription[] = [];
  showControls: boolean = false;
  activeFeatureProperties: any;
  tableDetails: Array<any>;

  detailsLoading: boolean = false;

  isLoading: boolean = false;

  lockSidenavOpen: boolean = false;

  languages: Observable<string>;
  language: string;
  currentRoute: string;
  featureCount: number = 1;
  static DEFAULT_EXTENT: any;

  constructor(
    protected http: HttpClient,
    protected breakpointObserver: BootstrapMediaService,
    protected translationHelper: TranslationHelperService,
    protected olMapService: OLMapService
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.breakpointObserver
        .observe(BootstrapMediaBreakpoint.LG)
        .subscribe((state: BreakpointState) => {
          this.lockSidenavOpen = state.matches;
        })
    );
    this.languages = this.translationHelper.getLanguage();
    this.parser.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon
    );
  }

  getBackgroundColor(value: string): string {
    return setBackgroudTable(value);
  }

  getTextColor(value: string): string {
    return setTextColor(value);
  }

  abstract addVectorLayer(feature: Feature<Geometry>, format: GeoJSON);
  abstract getLayerDetails(feature: Feature<Geometry>);
  abstract getLayerName(): string[] | string;
  abstract getStyleName(): string;
  abstract loadLayer(): void;
  abstract setFilter(layerPostfix?: string): void;
  abstract updateDetails(feature: Feature<Geometry>);
  abstract mapOperations(map: Map): void;
}
