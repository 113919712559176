import { Component, OnDestroy, OnInit } from "@angular/core";
import { AtlasBaseMapComponent } from "../atlas-base-map.component";
import { HttpClient } from "@angular/common/http";
import { BootstrapMediaService } from "src/app/services/bootstrap-media.service";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import {
  FIX_WORKSPACES,
  POINTS_LABEL_ATLAS_FIX,
} from "src/assets/constants/constants";
import GeoJSON from "ol/format/GeoJSON";
import { OLMapService } from "src/app/services/ol-map/ol-map.service";
import { unsubscribe } from "src/app/utils/helpers";
import { AtlasFixFilterService } from "src/app/services/filter/atlas-fix-filter.service";
import { PointDetails } from "src/app/models/pointDetails";
import { forkJoin } from "rxjs";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { GeoserverAPIService } from "src/app/services/geoserver/geoserver-api.service";

@Component({
  selector: "app-atlas-fix-map",
  templateUrl: "./atlas-fix-map.component.html",
  styleUrls: ["./atlas-fix-map.component.scss"],
})
export class AtlasFixMapComponent
  extends AtlasBaseMapComponent
  implements OnInit, OnDestroy
{
  isPoint: boolean = false;

  public readonly LABEL_ATLASFIX_POINTS = POINTS_LABEL_ATLAS_FIX;
  get pointDetail(): PointDetails {
    return this.atlasFixFilterService.atlasFixService.pointDetails;
  }

  constructor(
    public atlasFixFilterService: AtlasFixFilterService,
    protected http: HttpClient,
    protected breakpointObserver: BootstrapMediaService,
    protected translationHelper: TranslationHelperService,
    public olMapService: OLMapService,
    private geoserverService: GeoserverAPIService
  ) {
    super(http, breakpointObserver, translationHelper, olMapService);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.atlasFixFilterService.atlasFixService.polygonSubject.subscribe(
        (val) => {
          if (!val) {
            return;
          }
          this.atlasFixFilterService.setQueryParams();
          this.atlasFixFilterService.atlasFixService.setDefaultFilter();
          this.loadLayer();
          this.loadPoints();
        }
      )
    );
    this.atlasFixFilterService.atlasFixService.getLegends("fix-legend");
    this.atlasFixFilterService.atlasFixService.getFilter("fix-filter");
    this.atlasFixFilterService.atlasFixService.getDetails("fix-detail");
    this.mapOperations();
  }

  mapOperations(): void {
    this.atlasFixFilterService.atlasFixService.eventsKey =
      this.olMapService.map.on(
        "singleclick",
        (event: MapBrowserEvent<MouseEvent>) => {
          const joinEndpoints =
            this.atlasFixFilterService.atlasFixService.mapOperations(event);
          this.subscriptions.push(
            forkJoin(joinEndpoints).subscribe((featureCollections: GeoJSON[]) =>
              featureCollections.map((featureCollection: GeoJSON) => {
                const format = new GeoJSON();
                const features: Feature<Geometry>[] =
                  format.readFeatures(featureCollection);
                if (features.length > 0) {
                  this.updatePointDetails(features);
                  this.updateDetails(features[0]);
                  this.addVectorLayer(features[0], format);
                }
              })
            )
          );
        }
      );
  }
  getLayerDetails(feature: Feature<Geometry>): void {
    this.atlasFixFilterService.atlasFixService.getLayerDetails(feature);
  }

  getLayerName(): string {
    return this.atlasFixFilterService.atlasFixService.getLayerName();
  }
  getStyleName(): string {
    return this.atlasFixFilterService.atlasFixService.getStyleName();
  }
  loadLayer(): void {
    this.atlasFixFilterService.atlasFixService.loadLayer();
  }
  loadPoints(): void {
    this.atlasFixFilterService.atlasFixService.loadPoint(null);
  }
  setFilter(): void {
    this.atlasFixFilterService.atlasFixService.setFilter();
  }
  updateDetails(feature: Feature<Geometry>): void {
    this.atlasFixFilterService.atlasFixService.updateDetails(feature);
  }

  updatePointDetails(features: Feature<Geometry>[]): void {
    this.atlasFixFilterService.atlasFixService.updatePointDetails(features);
  }

  addVectorLayer(feature: Feature<Geometry>, format: GeoJSON) {
    this.atlasFixFilterService.atlasFixService.addVectorLayer(
      feature,
      format
    );
  }
  ngOnDestroy(): void {
    this.atlasFixFilterService.atlasFixService.mapOperationsUn();
    unsubscribe(this.subscriptions);
  }
}
