<!--<ng-sidebar-container class="d-flex w-100">-->
<!--<ng-sidebar [(opened)]="sidenavOpen" position="top" mode="over" [showBackdrop]="true">-->
<!--<div class="w-100 mobile-menu" [class.show]="sidenavOpen">-->
<!--<app-nav-link [project]="project" [selectedProject]="selectedProject" *ngFor="let project of projects"></app-nav-link>-->
<!--</div>-->
<!--</ng-sidebar>-->
<!--<div ng-sidebar-content class="w-100 ">-->
<app-header></app-header>
<app-nav-link></app-nav-link>
<main [class.has-footer]="!hideFooter">
  <router-outlet></router-outlet>
</main>

<app-footer *ngIf="!hideFooter"></app-footer>

<div
  style="
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    height: 65px;
    width: 65px;
    border-top-right-radius: 5px;
    opacity: 1;
    font-weight: bold;
    font-size: 2em;
  "
  *ngIf="isDev"
>
  <div class="d-block d-sm-none">XS</div>
  <div class="d-none d-sm-block d-md-none">SM</div>
  <div class="d-none d-md-block d-lg-none">MD</div>
  <div class="d-none d-lg-block d-xl-none">LG</div>
  <div class="d-none d-xl-block">XL</div>
</div>
<!--</div>-->
<!--</ng-sidebar-container>-->
