import { Component, OnDestroy } from '@angular/core';

import { faGlobe as falGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../utils/helpers';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnDestroy {

  readonly icons = {
    falGlobe,
  };

  language: string;
  languages: string[];

  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  constructor(protected translationHelper: TranslationHelperService) {
    unsubscribe(this.subscriptions);
    this.languages = this.translationHelper.languages;
    this.subscriptions.push(
      this.translationHelper.getLanguage().subscribe(
        language => this.language = language,
        err => console.log(this.constructor.name, 'getLanguage', err),
      ),
    );
  }

  changeLang(lang: string): void {
    this.translationHelper.changeLocale(lang + '-BE');
  }
}
