import { Coordinate } from "ol/coordinate";
import { environment } from "src/environments/environment";

export const DEFAULT_CENTER: Coordinate = [4.36421, 50.845653];
export const DEFAULT_EXTENT: Coordinate = [
  2.54133921349926, 49.4968827519196, 6.40809817433551, 51.5051138604811,
];
export const LABEL_FTTH: string[] = [
  "Aantal",
  "Infra",
  "Infrastruc",
  "Provider",
];
export const LABEL_TREIN: string[] = [
  "provider",
  "rsrp_percentage",
  "treinlijnen",
];
export const LABEL_ATLAS_FIX: string[] = [
  "Naam",
  "Technology",
  "Provider",
  "Download",
  "Upload",
];
export const POINTS_LABEL_ATLAS_FIX: string[] = [
  "Provider",
  "Technology",
  "Download",
  "Upload",
];
export const TYPE_NAME_PREFIX: string = environment.production ? "" : "";
export const ATTRIBUTION: string =
  environment.tileserver.backgroundLayers.minimalist.attribution;

export const FIX_FILTERS: string[] = ["speed", "level", "coverType"];
export const FIX_WORKSPACES: string[] = ["polygon", "point"];
export const TREIN_FILTERS: string[] = [
  "provider",
  "typeofmeasurement",
];
export const TREIN_OPTIONS: { [key: string]: string[] } = {
  provider: [
  "Telenet",
  "Proximus",
  "Orange",
  "GSM-R"
  ],
  typeofmeasurement: [
    "indoor",
    "outdoor"
  ]
};
export const DEFAULT_TREIN_FILTERS: { [key: string]: string } = {
  provider: "Proximus",
  typeofmeasurement: "outdoor",
};
export const TREIN_FILTER_LABELS: { [key: string]: string } = {
  typeofmeasurement: "controls.measureType",
  provider: "controls.provider"
};
export const MOBILE_FILTERS: string[] = [
  "provider",
  "technology",
  "quality",
  "level",
  "coverType",
];
export const MOBILE_FILTER_LABELS: string[] = [
  "controls.provider",
  "controls.technology",
  "controls.quality",
  "controls.level",
  "controls.coverType",
];
export const MOBILE_DATASETS: string[] = ["detailed", "byzone"];
export const MOBILE_DATASET_LABELS: string[] = [
  "labels.detailed",
  "labels.byzone",
];

export const FTTH_FILTERS: string[] = ["executionState"];

export const LAYERPOSTFIX = "layerPostfix";
export const SELECTEDTAB: string = "selectedTab";
export const BBOX: string = "bbox";
export const MINQUARTER = 2022.25;
export const TREIN_LAYER_NAME = "treinmetingen";
export const TREIN_WORKSPACE = "dp_treinmetingen";
