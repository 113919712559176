import { Component } from '@angular/core';
import { AbstractPage } from '../abstract-page';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent extends AbstractPage {

  locale: string;

  text: string = "";

  constructor(
    protected http: HttpClient,
    protected translationHelper: TranslationHelperService,
  ) {
    super();
    this.addSubscription(
      this.translationHelper.getLocale().subscribe(
        (locale) => {
          this.addSubscription(
            this.http.get(this.getUrl(locale), { responseType: 'text' }).subscribe(
              (body) => {
                this.text = body;
              },
              (error) => console.error(this.constructor.name, "get text", error),
            ))
          this.locale = locale;
        },
        (err) => console.error(this.constructor.name, 'getLocale', err),
      ))

  }

  getUrl(locale?: string): string {
    return `assets/terms/${locale}.html`;
  }

}
