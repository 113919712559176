import * as leaflet from 'leaflet';
import { LatLngExpression } from 'leaflet';
import { Filter } from './filter';
import { objectToQueryString } from '../utils/url';
import { MapComponent } from '../components/map/map.component';
import { LegendDefinition } from '../models/layer';
import { LatLng } from 'leaflet';

export interface Filters {
  [key: string]: any;
}

export enum MapType {
  RASTER,
  VECTOR,
}

export abstract class Dataset {

  public readonly MAP_MIN_ZOOM = 6;
  public readonly MAP_MAX_ZOOM = 18;

  protected abstract mapBaseUrl?: string;

  protected abstract mapPositionInfoUrl?: string;

  public abstract legend?: LegendDefinition;

  protected abstract statisticsBaseUrl?: string;

  protected abstract attribution: string;


  public onFeatureClick?: (mapComponent: MapComponent, event: any) => void;

  public getMapType(): MapType {
    return null;
  }

  public getMapBaseUrl(): string {
    return this.mapBaseUrl;
  }

  public getTileUrlTemplate(filter: Filter): string {
    return this.mapBaseUrl + this.getTileQueryString(filter);
  }

  public getPositionInfoUrl(latlng: LatLng, filter: Filter): string {
    if (this.mapPositionInfoUrl) {
      return this.mapPositionInfoUrl + objectToQueryString({
        lat: Math.round(latlng.lat * 10000) / 10000,
        lng: Math.round(latlng.lng * 10000) / 10000,
        filter: filter.getPlain(),
      });
    }
    return null;
  }

  protected getTileQueryString(filter: Filter): string {
    return objectToQueryString({ filter: filter.getPlain() });
  }

  public getBounds(): leaflet.LatLngBounds {
    let bottomLeft = [49.253, 1.769];
    let topRight = [52.295, 7.064];

    return new leaflet.LatLngBounds(<LatLngExpression>bottomLeft, <LatLngExpression>topRight);
  }

  public getStatisticsBackendUrl(): string {
    return this.statisticsBaseUrl;
  }
}
