export interface AreaCoordinate {
  x: number;
  y: number;
  coordinates: [number, number];
}

export interface AreaRectangleBounds {
  points: Array<AreaCoordinate>;
}
export interface Contour{
  type:string;
  coordinates:Array<number>[];
}
export interface Center{
  type:string;
  coordinates:Array<number>[];

}

export class Area {
  id: string;
  level?: string;
  name?: string;
  name_de?: string;
  name_fr?: string;
  name_nl?: string;
  name_en?: string;
  contour?:Contour;
  center?:Center;
  postcode?:string;
  postalCodes?: Array<string>;
  rectangleBounds?: AreaRectangleBounds;
  displayValue?: string;
  niscode?: string;
  niscode_municipality?:string;

  

  getPlain(): any {
    return {
      id: this.id,
      level: this.level,
      name: this.name,
      name_en: this.name_en,
      name_de: this.name_de,
      name_fr: this.name_fr,
      name_nl: this.name_nl,
      postalCodes: this.postalCodes,
      contour: this.contour,
      center: this.center,
      postcode: this.postcode,
      niscode: this.niscode,
      niscode_municipality: this.niscode_municipality
    };
  }

  static fromPlain(plain: any): Area {
    let area = new Area();

    for (let key in plain) {
      area[key] = plain[key];
    }

    return area;
  } 
 
  }