import { Component, OnInit } from '@angular/core';
import { AtlasDescriptionBaseComponent } from '../atlas-description-base.component';
import { Router } from '@angular/router';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';

@Component({
  selector: 'app-atlas-fix-description',
  templateUrl: './atlas-fix-description.component.html',
  styleUrls: ['./atlas-fix-description.component.scss']
})
export class AtlasFixDescriptionComponent extends AtlasDescriptionBaseComponent implements OnInit {

  constructor(
    protected translationHelperService: TranslationHelperService,
    protected _router: Router
  ) { super(translationHelperService, _router) }

  ngOnInit(): void {
  }

}

