import { Injectable } from '@angular/core';
import { Dataset } from '../../datasets/dataset';
import { ExtractsRequest } from './extracts-request';
import { HttpClient } from '@angular/common/http';
import { objectToQueryString } from '../../utils/url';
import { Extract } from '../../datasets/extracts/extract';
import { map, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class StatisticsService {

  constructor(
    protected http: HttpClient,
    protected authenticationService: AuthenticationService,
  ) {
  }

  get(dataset: Dataset, request: ExtractsRequest): Observable<Array<Extract>> {
    let url: string = dataset.getStatisticsBackendUrl();
    let order = Object.keys(request);

    let query = {
      request: request,
    };
    let queryString: string = objectToQueryString(query);

    let options = { headers: {} };
    let authorizationString = this.authenticationService.authorizationString;
    if (authorizationString) {
      options.headers['Authorization'] = authorizationString;
    }

    return this.http.get<Array<{ [key: string]: any }>>(url + queryString, options)
      .pipe(
        map(plains => plains
          .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name))
          .map(p => Extract.fromPlain(p, dataset))
        )
      )
      ;
  }
}
