<mat-form-field class="w-100">
  <input type="text"
  [placeholder]="'controls.postcode' | translate"
  matInput
  [formControl]="searchPostcodeForm"
  [matAutocomplete]="auto"
  (focus)="onFocus($event)"
  >
  <!--(keydown.backspace)="clear()"-->
  <mat-autocomplete #auto="matAutocomplete"
  [displayWith]="getOptionText"
  (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true"
  >
  <mat-option *ngFor="let result of results" [value]="result">
    {{result.name_fr}} 
    {{result.name}}  
    {{result.postcode}}
  </mat-option>
</mat-autocomplete>
<fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
</mat-form-field>

<mat-form-field class="w-100">
  <input type="text"
  [placeholder]="'controls.area' | translate"
  matInput
  [formControl]="searchStreetForm"
  [matAutocomplete]="auto2"
  (focus)="onFocus($event)"
  >
  <!--(keydown.backspace)="clear()"-->
  <mat-autocomplete #auto2="matAutocomplete"
  [displayWith]="getOptionText"
  (optionSelected)="onSelect($event)"
  [autoActiveFirstOption]="true"
  >
  <mat-option *ngFor="let result2 of results2" [value]="result2">
      {{result2.name}}
    </mat-option>
  </mat-autocomplete>
  <fa-icon class="loader" [class.shown]="loading" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
</mat-form-field>
