import { Dataset, MapType } from "./dataset";
import { environment } from "../../environments/environment";

export class AtlasMobileGridDataset extends Dataset {
  protected mapBaseUrl: string =
    environment.geoServer.baseUrl + environment.geoServer.dp_mobile_detailed_overview;

  protected mapPositionInfoUrl?: string;

  public legend = null;

  protected statisticsBaseUrl: string = null;

  protected attribution: string = undefined;

  getMapType(): MapType {
    return MapType.RASTER;
  }
}
