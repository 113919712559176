import { Dataset } from "../../datasets/dataset";
import { Extract } from "../../datasets/extracts/extract";
import { StatisticsService } from "../../services/statistics/statistics.service";
import { Directive, OnInit } from "@angular/core";
import { AbstractPage } from "../abstract-page";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
@Directive()
export abstract class AbstractPresentationPage extends AbstractPage implements OnInit {

  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  extracts: Array<Extract> = [];
  isShowFilter: boolean = false;

  constructor(protected statistics: StatisticsService) {
    super();
  }

  ngOnInit() {
    this.updateExtracts();

  }

  updateExtracts(extracts: Array<Extract> = this.extracts) {
    let request = Object.assign(
      {},
      ...extracts.map((p) => ({ [p.name]: p.toRequest() }))
    );
    // console.log(this.constructor.name, 'updateExtracts request', request);

    let requestByDatasets: Array<{
      dataset: Dataset;
      request: { [name: string]: any };
    }> = [];
    for (let extract of extracts) {
      let found = false;
      if (!this.isIE()) {
        for (let entry of requestByDatasets) {
          if (entry.dataset === extract.dataset) {
            entry.request[extract.name] = extract.toRequest();
            found = true;
            break;
          }
        }
      }
      if (!found) {
        requestByDatasets.push({
          dataset: extract.dataset,
          request: { [extract.name]: extract.toRequest() },
        });
      }
    }

    for (let entry of requestByDatasets) {
      this.doUpdate(entry.dataset, entry.request);
    }
  }

  doUpdate(dataset: Dataset, request: any) {
    let subscription = this.statistics.get(dataset, request).subscribe(
      (extracts: Array<Extract>) => {
        // console.log(this.constructor.name, "updateExtracts updated", extracts);
        for (let updated of extracts) {
          for (let i in this.extracts) {
            let old = this.extracts[i];
            if (old.name === updated.name) {
              this.extracts[i].updateWith(updated);
              this.extracts[i].loading = false;
              break;
            }
          }
        }
        // this.extracts = this.extracts.slice(0);
      },
      (error) => {
        console.error(this.constructor.name, "updateExtracts", error);
      },
      () => this.removeSubscription(subscription)
    );
    this.addSubscription(subscription);
  }

  protected isIE(): boolean {
    // Specific to SSR (Angular Universal), because window is not available when executing on the server
    if (!window) {
      return false;
    }
    // https://stackoverflow.com/a/19999868/3666853
    let ua = (navigator && navigator.userAgent) || "";
    let msie = ua.indexOf("MSIE ");

    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }
  showFilter() {
    this.isShowFilter = !this.isShowFilter
  }
}
