import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';

@DeclareExtract('complaints_invoice_comparison')
export class ComplaintsInvoiceComparisonExtract extends AbstractBarometerExtract {

  name = 'complaints_invoice_comparison';

  xUnit = '%';

}
