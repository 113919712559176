import { Component } from '@angular/core';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-loader',
  template: `
    <fa-icon [icon]="loader" [spin]="true" size="1x"></fa-icon>
  `
})
export class LoaderComponent {

  loader = faSpinnerThird;

}
