import { Component, OnInit } from '@angular/core';

import { faFileCsv as falFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { AbstractPage } from '../abstract-page';
import { forEachCombination } from '../../utils/objects';
import { Stringifiable } from '../../utils/types';
import { replaceVariablesInUrl } from '../../utils/url';
import { environment } from '../../../environments/environment';
import { enumToArray } from '../../utils/arrays';
import { BarometerPeriodEnum } from '../../models/domain/barometer-period-enum';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { RGBColor } from '../../utils/RGBColor';
import { ProjectService } from '../../services/project.service';
import { MetadataService } from '../../services/metadata.service';

export type OpenDataProjects = Array<{
  title: string,
  badges: Array<Badge>,
  description?: string,
  license?: string,
  baseUrl?: string,
  variables?: { [name: string]: Array<Stringifiable> },
  urls?: Array<{ label: string, value: string }>,
}>;

export interface Badge {
  label: string;
  colors: {
    text: RGBColor;
    background: RGBColor;
  };
}

export const BADGE_COLORS_BY_TYPE = {
  filetype: {
    text: new RGBColor(255, 255, 255),
    background: new RGBColor(199, 52, 62),
  },
  service: {
    text: new RGBColor(255, 255, 255),
    background: new RGBColor(133, 77, 148),
  },
};

@Component({
  selector: 'app-opendata-page',
  templateUrl: './opendata-page.component.html',
  styleUrls: ['./opendata-page.component.scss']
})
export class OpendataPageComponent extends AbstractPage implements OnInit {

  readonly icons = {
    falFileCsv,
  };


  projects: OpenDataProjects = [
    // {
    //   title: 'opendata.atlasMobileGrid.title',
    //   description: 'opendata.atlasMobileGrid.description',
    //   baseUrl: environment.opendata.atlasMobileGrid.url,
    //   variables: {
    //     provider: DOMAIN.providers.map(provider => provider.value),
    //     technology: [TechnologyEnum._3G, TechnologyEnum._4G],
    //   },
    // },
    {
      title: 'opendata.atlasMobileArea.title',
      badges: [
        { label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype },
        { label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service },
        { label: 'Mobile', colors: BADGE_COLORS_BY_TYPE.service },
      ],
      description: 'opendata.atlasMobileArea.description',
      // license: '&copy; BIPT',
      baseUrl: environment.opendata.atlasMobileArea.url,
      variables: {
        level: ['STATISTICAL_SECTOR', 'MUNICIPALITY'],
      },
    },
    {
      title: 'opendata.atlasFix.title',
      badges: [
        {label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype},
        {label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service},
        {label: 'Landline', colors: BADGE_COLORS_BY_TYPE.service},
      ],
      description: 'opendata.atlasFix.description',
      // license: '&copy; BIPT',
      baseUrl: environment.opendata.atlasFix.url,
      variables: {
        level: ['STATISTICAL_SECTOR', 'MUNICIPALITY', 'DISTRICT','PROVINCE' ], 
        // [
        //   {label: 'area.level.STATISTICAL_SECTOR', value: AreaLevel.STATISTICAL_SECTOR},
        //   {label: 'area.level.MUNICIPALITY', value: AreaLevel.MUNICIPALITY},
        //   {label: 'area.level.DISTRICT', value: AreaLevel.DISTRICT},
        //   {label: 'area.level.PROVINCE', value: AreaLevel.PROVINCE},
        //   {label: 'area.level.REGION', value: AreaLevel.REGION},
        //   {label: 'area.level.COUNTRY', value: AreaLevel.COUNTRY},
        // ].map(level => level.value),
      },
    },
    {
      title: 'opendata.barometer.title',
      badges: [
        { label: 'CSV', colors: BADGE_COLORS_BY_TYPE.filetype },
        { label: 'Internet', colors: BADGE_COLORS_BY_TYPE.service },
        { label: 'TV', colors: BADGE_COLORS_BY_TYPE.service },
        { label: 'Telephone', colors: BADGE_COLORS_BY_TYPE.service },
      ],
      description: 'opendata.barometer.description',
      // license: '&copy; BIPT',
      baseUrl: environment.opendata.barometer.url,
      variables: {
        period: enumToArray(BarometerPeriodEnum),
      },
    },
    // {
    //   title: 'opendata.driveTesting.title',
    //   description: 'opendata.driveTesting.description',
    //   // baseUrl: environment.opendata.atlasFix.url,
    //   // variables: {
    //   //   level: DOMAIN.areasLevels.map(level => level.value),
    //   // },
    // },
  ];

  constructor(
    protected translationHelper: TranslationHelperService,
    protected projectService: ProjectService,
    protected metadata: MetadataService,
  ) {
    super();
    this.metadata.setImage(environment.baseHref + 'assets/images/og/data-portal.png');
    this.metadata.setSubtitle('opendata.title');
    this.metadata.setDescription('opendata.description');
    this.projectService.select(null);
    const group = projectService.groups.filter(c=>c.group == "Data")[0]
    projectService.selectGroup(group)
  }

  ngOnInit() {
    this.fillProjectsUrls();
  }

  fillProjectsUrls() {
    for (let project of this.projects) {
      if (!project.urls && project.baseUrl && project.variables) {
        this.addSubscription(
          this.translationHelper.getLocale().subscribe((locale) => {
            project.urls = [];
            forEachCombination<Stringifiable>(project.variables, (combination) => {
              let labels = Object.values(combination).map(value => value.toString());
              this.addSubscription(
                this.translationHelper.getAllLabels(...labels)
                  .subscribe(
                    (translations) => {
                      let url = replaceVariablesInUrl(project.baseUrl, combination);
                      project.urls.push({
                        label: translations.join(' '),
                        value: url,
                      });
                    },
                    (error) => {
                      console.error(this.constructor.name, 'fillProjectsUrls', error);
                    }
                  ))
            });
          }))
      }
    }
  }

}
