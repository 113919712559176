import { Component, OnDestroy } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { environment } from '../../../environments/environment';
import { MetadataService } from '../../services/metadata.service';
import { faBalanceScaleRight, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faAndroid, faApple, faInternetExplorer } from '@fortawesome/free-brands-svg-icons';
import { unsubscribe } from 'src/app/utils/helpers';
import { Subscription } from 'rxjs';

export interface Application {
  name: string;
  description: string;
  image?: string;
  icon?: IconDefinition;
  link?: string;
  links?: { [locale: string]: string };
  comingSoon?: boolean;
  platforms?: IconDefinition[];
}

@Component({
  selector: 'app-applications',
  templateUrl: './applications-page.component.html',
  styleUrls: ['./applications-page.component.scss'],
})
export class ApplicationsPageComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  language: string;

  applications: Application[] = [
    {
      name: 'ui.applications.apps.myspeed.name',
      description: 'ui.applications.apps.myspeed.description',
      image: '/assets/images/applications/bipt-myspeed.png',
      links: {
        'fr': 'http://www.bipt-speedtest.be?lang=fr',
        'nl': 'http://www.bipt-speedtest.be?lang=nl',
        'de': 'http://www.bipt-speedtest.be?lang=de',
        'en': 'http://www.bipt-speedtest.be?lang=en',
      },
      platforms: [faAndroid, faApple, faInternetExplorer],
    },
    {
      name: 'ui.applications.apps.tariffs.name',
      description: 'ui.applications.apps.tariffs.description',
      icon: faBalanceScaleRight,
      comingSoon: true,
      links: {
        'fr': 'http://www.meilleurtarif.be',
        'nl': 'http://www.bestetarief.be',
        'de': 'http://www.meilleurtarif.be',
        'en': 'http://www.besttariff.be',
      },
      platforms: [faInternetExplorer],
    },
  ];

  constructor(
    protected projectService: ProjectService,
    protected translationHelper: TranslationHelperService,
    protected metadata: MetadataService,
  ) {
    unsubscribe(this.subscriptions);
    this.metadata.setImage(environment.baseHref + 'assets/images/og/data-portal.png');
    this.metadata.setSubtitle('ui.projects.applications.title');
    this.metadata.setDescription('ui.projects.applications.description');
    this.projectService.select(null);
    this.subscriptions.push(
      this.translationHelper.getLanguage().subscribe(
        (language) => this.language = language,
        (err) => console.error(this.constructor.name, 'getLanguage', err),
      ))
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
