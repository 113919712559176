import { MobileProviderEnum } from "./mobile-provider-enum";
import { TechnologyEnum } from "./technology-enum";
import { PeriodEnum } from "./period-enum";
import { ServiceEnum } from "./service-enum";
import { BarometerPeriodEnum } from "./barometer-period-enum";
import { LandlineProviderEnum } from "./landline-provider-enum";
import { AreaLevel } from "./arealevel-enum";
import { BarometerMeasureEnum } from "./barometer-measure-enum";
import { ExecutionStateEnum } from "./execution-state-enum";
import { CoverTypeEnum } from "./covertype-enum";
import { SpeedEnum } from "./speed-enum";
import { QualityEnum } from "./quality-enum";

export const DOMAIN = {
  providers: [
    { label: "labels.BASE", value: MobileProviderEnum.BASE },
    { label: "labels.ORANGE", value: MobileProviderEnum.ORANGE },
    { label: "labels.PROXIMUS", value: MobileProviderEnum.PROXIMUS },
  ],
  landlineProviders: [
    { label: "labels.PROXIMUS", value: LandlineProviderEnum.PROXIMUS },
    { label: "labels.TELENET", value: LandlineProviderEnum.TELENET },
    { label: "labels.VOO", value: LandlineProviderEnum.VOO },
  ],
  landlineProvidersAll: {
    label: "labels.ALL",
    value: LandlineProviderEnum.ALL,
  },
  technologies: [
    { label: "labels._2G", value: TechnologyEnum._2G },
    { label: "labels._3G", value: TechnologyEnum._3G },
    { label: "labels._4G", value: TechnologyEnum._4G },
  ], // , 'WIFI'
  areasLevels: [
    {
      label: "area.level.STATISTICAL_SECTOR",
      value: AreaLevel.STATISTICAL_SECTOR,
    },
    { label: "area.level.MUNICIPALITY", value: AreaLevel.MUNICIPALITY },
    { label: "area.level.DISTRICT", value: AreaLevel.DISTRICT },
    { label: "area.level.PROVINCE", value: AreaLevel.PROVINCE },
    { label: "area.level.REGION", value: AreaLevel.REGION },
    { label: "area.level.COUNTRY", value: AreaLevel.COUNTRY },
  ],
  periods: [
    // {label: 'labels.1 week', value: PeriodEnum._1_WEEK},
    { label: "labels.1 month", value: PeriodEnum._1_MONTH },
    { label: "labels.3 months", value: PeriodEnum._3_MONTHS },
    { label: "labels.6 months", value: PeriodEnum._6_MONTHS },
    { label: "labels.1 year", value: PeriodEnum._1_YEAR },
  ],
  services: [
    { label: "labels.TV", value: ServiceEnum.TV },
    { label: "labels.INTERNET", value: ServiceEnum.INTERNET },
    { label: "labels.PHONELINE", value: ServiceEnum.PHONELINE },
    { label: "labels._2G", value: ServiceEnum._2G },
    { label: "labels._3G", value: ServiceEnum._3G },
    { label: "labels._4G", value: ServiceEnum._4G },
  ],
  barometerPeriods: [
    { label: "2016 S1", value: BarometerPeriodEnum._2016S1 },
    { label: "2016 S2", value: BarometerPeriodEnum._2016S2 },
    { label: "2017 S1", value: BarometerPeriodEnum._2017S1 },
    { label: "2017 S2", value: BarometerPeriodEnum._2017S2 },
    { label: "2018 S1", value: BarometerPeriodEnum._2018S1 },
    { label: "2018 S2", value: BarometerPeriodEnum._2018S2 },
    { label: "2019 S1", value: BarometerPeriodEnum._2019S1 },
    { label: "2019 S2", value: BarometerPeriodEnum._2019S2 },
  ],
  measures: [
    {
      label: "labels.PERCENTILE_80",
      value: BarometerMeasureEnum.PERCENTILE_80,
    },
    {
      label: "labels.PERCENTILE_95",
      value: BarometerMeasureEnum.PERCENTILE_95,
    },
    { label: "labels.AVERAGE", value: BarometerMeasureEnum.AVERAGE },
    { label: "labels.TERRITORY", value: BarometerMeasureEnum.TERRITORY },
    { label: "labels.POPULATION", value: BarometerMeasureEnum.POPULATION },
    {
      label: "labels.PERCENTAGE_120S",
      value: BarometerMeasureEnum.PERCENTAGE_120S,
    },
  ],
  executionStates: [
    {
      label: "labels.executedAndPlanned",
      value: ExecutionStateEnum.EXECUTED_AND_PLANNED,
    },
    { label: "labels.executedRollout", value: ExecutionStateEnum.EXECUTED },
    { label: "labels.plannedRollout", value: ExecutionStateEnum.PLANNED },
  ],
  speeds: [
    { label: "labels.0MBPS", value: SpeedEnum.MBPS0 },
    { label: "labels.1MBPS", value: SpeedEnum.MBPS1 },
    { label: "labels.10MBPS", value: SpeedEnum.MBPS10 },
    { label: "labels.30MBPS", value: SpeedEnum.MBPS30 },
    { label: "labels.50MBPS", value: SpeedEnum.MBPS50 },
    { label: "labels.100MBPS", value: SpeedEnum.MBPS100 },
    { label: "labels.350MBPS", value: SpeedEnum.MBPS350 },
    { label: "labels.1000MBPS", value: SpeedEnum.MBPS1000 },
    { label: "labels.25000MBPS", value: SpeedEnum.MBPS2500 },
    { label: "labels.5000MBPS", value: SpeedEnum.MBPS5000 },
    { label: "labels.8500MBPS", value: SpeedEnum.MBPS8500 },
    { label: "labels.10000MBPS", value: SpeedEnum.MBPS10000 },
  ],
  coverType: [
    { label: "labels.HOUSEHOLDS", value: CoverTypeEnum.HOUSEHOLDS },
    { label: "labels.POPULATION", value: CoverTypeEnum.POPULATION },
  ],
  mobileQualities: [
    { label: "labels.Satisfactory", value: QualityEnum.SATISFACTORY },
    { label: "labels.Good", value: QualityEnum.GOOD },
    { label: "labels.VeryGood", value: QualityEnum.VERYGOOD },
  ],
};

export const DEFAULT_FILTER = {
  period: PeriodEnum._3_MONTHS,
  technology: [TechnologyEnum._2G, TechnologyEnum._3G, TechnologyEnum._4G],
};
