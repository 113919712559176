import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IndicatorsService {

  private val = {};

  valueChanges = new BehaviorSubject({});

  constructor() {
  }

  set(key: string, val: any): void {
    this.val[key] = val;
    this.valueChanges.next(this.val);
  }

  get(): any {
    return this.val;
  }

}
