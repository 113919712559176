import { Component, OnInit } from '@angular/core';
import { AbstractPresentationPage } from '../abstract-presentation-page/abstract-presentation-page';
import { Dataset } from '../../datasets/dataset';
import { Extract } from '../../datasets/extracts/extract';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { RespectDateStartComparisonExtract } from '../../datasets/extracts/barometer/respect-date-start-comparison.extract';
import { BarometerDataset } from '../../datasets/barometer.dataset';
import { ComplaintsStartComparisonExtract } from '../../datasets/extracts/barometer/complaints-start-comparison.extract';
import { DisruptionComparisonExtract } from '../../datasets/extracts/barometer/disruption-comparison.extract';
import { RepairDelayComparisonExtract } from '../../datasets/extracts/barometer/repair-delay-comparison.extract';
import { HelpdeskTimeComparisonExtract } from '../../datasets/extracts/barometer/helpdesk-time-comparison.extract';
import { IssuesSolvedDirectlyComparisonExtract } from '../../datasets/extracts/barometer/issues-solved-directly-comparison.extract';
import { ComplaintsInvoiceComparisonExtract } from '../../datasets/extracts/barometer/complaints-invoice-comparison.extract';
import { SignalCoverageComparisonExtract } from '../../datasets/extracts/barometer/signal-coverage-comparison.extract';
import { Filter, FilterConfig } from '../../datasets/filter';
import { DOMAIN } from '../../models/domain/domain';
import { BarometerPeriodEnum } from '../../models/domain/barometer-period-enum';
import { ServiceEnum } from '../../models/domain/service-enum';
import { BarometerMeasureEnum } from '../../models/domain/barometer-measure-enum';
import { enumToArray } from '../../utils/arrays';
import { MetadataService } from '../../services/metadata.service';
import { environment } from '../../../environments/environment';

const allPeriods = enumToArray<BarometerPeriodEnum>(BarometerPeriodEnum);

@Component({
  selector: 'app-barometer-page',
  templateUrl: './barometer-page.component.html',
  styleUrls: ['./barometer-page.component.scss']
})
export class BarometerPageComponent extends AbstractPresentationPage implements OnInit {

  dataset: Dataset = new BarometerDataset();

  defaultChartFilters: FilterConfig[] = [
    { label: 'controls.period', key: 'dataLabels', choices: DOMAIN.barometerPeriods, multiple: true },
  ];

  protected oldPeriodsValues: { [constructor: string]: { line?: Array<string>, comparisonBar?: Array<string> } } = {};

  defaultSelectedBarometerPeriods: BarometerPeriodEnum[] = allPeriods.slice(-1);

  extracts: Array<Extract> = [
    new RespectDateStartComparisonExtract({
      dataset: this.dataset,
      filters: [
        ...this.defaultChartFilters,
        {
          label: 'controls.service',
          key: 'service',
          choices: [
            { label: 'labels.TV', value: ServiceEnum.TV },
            { label: 'labels.INTERNET', value: ServiceEnum.INTERNET },
            { label: 'labels.PHONELINE', value: ServiceEnum.PHONELINE },
          ],
        },
      ],
      filter: new Filter({ service: ServiceEnum.INTERNET, dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new ComplaintsStartComparisonExtract({
      dataset: this.dataset,
      filters: [
        ...this.defaultChartFilters,
        {
          label: 'controls.service',
          key: 'service',
          choices: [
            { label: 'labels.TV', value: ServiceEnum.TV },
            { label: 'labels.INTERNET', value: ServiceEnum.INTERNET },
            { label: 'labels.PHONELINE', value: ServiceEnum.PHONELINE },
          ],
        }
      ],
      filter: new Filter({ service: ServiceEnum.INTERNET, dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new DisruptionComparisonExtract({
      dataset: this.dataset,
      filters: this.defaultChartFilters,
      filter: new Filter({ dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new RepairDelayComparisonExtract({
      dataset: this.dataset,
      filters: [
        ...this.defaultChartFilters,
        {
          label: 'controls.measure',
          key: 'measure',
          choices: [
            { label: 'labels.PERCENTILE_80', value: BarometerMeasureEnum.PERCENTILE_80 },
            { label: 'labels.PERCENTILE_95', value: BarometerMeasureEnum.PERCENTILE_95 },
          ],
        }
      ],
      filter: new Filter({ measure: BarometerMeasureEnum.PERCENTILE_80, dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new HelpdeskTimeComparisonExtract({
      dataset: this.dataset,
      filters: [
        ...this.defaultChartFilters,
        {
          label: 'controls.measure',
          key: 'measure',
          choices: [
            { label: 'labels.AVERAGE', value: BarometerMeasureEnum.AVERAGE },
            { label: 'labels.PERCENTAGE_120S', value: BarometerMeasureEnum.PERCENTAGE_120S },
          ],
        },
      ],
      filter: new Filter({ measure: BarometerMeasureEnum.AVERAGE, dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new IssuesSolvedDirectlyComparisonExtract({
      dataset: this.dataset,
      filters: this.defaultChartFilters,
      filter: new Filter({ dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new ComplaintsInvoiceComparisonExtract({
      dataset: this.dataset,
      filters: this.defaultChartFilters,
      filter: new Filter({ dataLabels: this.defaultSelectedBarometerPeriods }),
    }),
    new SignalCoverageComparisonExtract({
      dataset: this.dataset,
      filters: [
        { label: 'controls.period', key: 'dataLabels', choices: DOMAIN.barometerPeriods, multiple: true },
        {
          label: 'controls.measure',
          key: 'measure',
          choices: [
            { label: 'labels.TERRITORY', value: BarometerMeasureEnum.TERRITORY },
            { label: 'labels.POPULATION', value: BarometerMeasureEnum.POPULATION },
          ],
        },
        {
          label: 'controls.service',
          key: 'service',
          choices: [
            { label: 'labels._2G', value: ServiceEnum._2G },
            { label: 'labels._3G', value: ServiceEnum._3G },
            { label: 'labels._4G', value: ServiceEnum._4G },
          ],
        },
      ],
      filter: new Filter({
        measure: BarometerMeasureEnum.TERRITORY,
        service: ServiceEnum._4G,
        dataLabels: this.defaultSelectedBarometerPeriods,
      }),
    }),
  ];

  constructor(
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
  ) {
    super(statistics);
    this.metadata.setImage(environment.baseHref + 'assets/images/og/barometer.png');
    this.metadata.setSubtitle('ui.projects.barometre.title');
    this.metadata.setDescription('ui.projects.barometre.description');
  }

  onChartTypeChange(extract: Extract, newType: string) {
    let filter = extract.filter;
    let values;
    if (newType === 'line') {
      this.setOldPeriodsValues(extract, 'bar', filter.get('dataLabels'));
      values = this.getOldPeriodsValues(extract, 'line');
      if (!values) {
        values = allPeriods; // .slice(-4);
      }
    } else if (newType === 'bar') {
      this.setOldPeriodsValues(extract, 'line', filter.get('dataLabels'));
      values = this.getOldPeriodsValues(extract, 'bar');
      if (!values) {
        values = allPeriods.slice(-1);
      }
    }

    filter.set('dataLabels', values);

    this.updateExtracts([extract]);
  }

  setOldPeriodsValues(extract: Extract, type: 'line' | 'bar', values: Array<string>): void {
    let constructor = extract.constructor.name;
    let oldValues = this.oldPeriodsValues[constructor];
    if (oldValues === undefined) {
      oldValues = this.oldPeriodsValues[constructor] = {};
    }

    oldValues[type] = values;
  }

  getOldPeriodsValues(extract: Extract, type: 'line' | 'bar'): Array<string> {
    let constructor = extract.constructor.name;
    let oldValues = this.oldPeriodsValues[constructor];

    return this.oldPeriodsValues[constructor] && this.oldPeriodsValues[constructor][type];
  }
}
