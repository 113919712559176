import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';

@DeclareExtract('issues_solved_directly_comparison')
export class IssuesSolvedDirectlyComparisonExtract extends AbstractBarometerExtract {

  name = 'issues_solved_directly_comparison';

  xUnit = '%';
}
