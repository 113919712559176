import { Dataset } from './dataset';
import { environment } from '../../environments/environment';

export class BarometerDataset extends Dataset {

  protected mapBaseUrl: string = undefined;

  protected mapPositionInfoUrl?: string;

  public legend = undefined;

  protected statisticsBaseUrl: string = environment.statistics.backend.url + '/barometer';

  protected attribution: string = undefined;

}
