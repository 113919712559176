import { TimeSeries } from '../../timeseries';
import { DeclareExtract } from '../../declare-extract.decorator';

@DeclareExtract('clientcount_timeseries')
export class ClientCountTimeseries extends TimeSeries {

  name = 'clientcount_timeseries';

  groupingFunction = null;

}
