<div
  class="pl-3 pr-3 d-flex align-items-center language"
  [matMenuTriggerFor]="languageMenu"
>
  <fa-icon [icon]="icons.falGlobe" class="pr-2"></fa-icon>
  {{ language }}
</div>
<mat-menu #languageMenu class="language-menu">
  <button
    *ngFor="let lang of languages"
    mat-menu-item
    (click)="changeLang(lang)"
    [disabled]="lang === language"
  >
    {{ lang }}
  </button>
</mat-menu>
