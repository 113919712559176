import localeDe from '@angular/common/locales/de-BE';
import localeEn from '@angular/common/locales/en-BE';
import localeFr from '@angular/common/locales/fr-BE';
import localeNl from '@angular/common/locales/nl-BE';

import { localeToLang } from './utils/helpers';

/**
 * @warning Don't forget to add moment.js locale import in app.module.ts
 */
export const locales: { [key: string]: Locale } = {
  'de-BE': {
    title: 'de',
    data: localeDe,
    key: 'de-BE',
  },
  'en-BE': {
    title: 'en',
    data: localeEn,
    key: 'en-BE',
  },
  'fr-BE': {
    title: 'fr',
    data: localeFr,
    key: 'fr-BE',
  },
  'nl-BE': {
    title: 'nl',
    data: localeNl,
    key: 'nl-BE',
  },
};

export interface Locale {
  title: string;
  data: any;
  key: string;
}

export const SUPPORTED_LOCALES = Object.keys(locales);
export const SUPPORTED_LANGUAGES = SUPPORTED_LOCALES.map(loacle => localeToLang(loacle));
export const DEFAULT_LOCALE = 'en-BE';
export const DEFAULT_LANGUAGE = localeToLang(DEFAULT_LOCALE);
