import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, flatMap, map } from 'rxjs/operators';
import { TranslationHelperService } from '../services/translations/translation-helper.service';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform {

  constructor(
    protected translate: TranslateService,
    protected translationHelper: TranslationHelperService,
  ) {

  }

  transform(value: any, prefix: string = 'labels.'): any {
    let key = (prefix !== null ? prefix : '') + value;
    // let translation = this.translate.instant(key);

    return this.translationHelper.getLocale().pipe(
      flatMap((locale) => this.translate.get(key)),
      map((translation) => (translation === key) ? value : translation),
      catchError((err) => value)
    );

  }

}
