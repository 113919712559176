import { Injectable } from '@angular/core';
import { AtlasFixService } from '../ol-map/atlas-fix.service';

@Injectable({
  providedIn: 'root'
})
export class AtlasFixFilterService {

  constructor(
    public atlasFixService: AtlasFixService
  ) { }

  setFilter(filter: string, value: any) {
    this.atlasFixService.olMapService.filter.set(filter, value);
    this.atlasFixService.loadLayer();
  }
  setQueryParams() {
    this.atlasFixService.eventsKey = this.atlasFixService.olMapService.map.on('moveend', () => {
      this.atlasFixService.changeUrl();
    })
  }
}