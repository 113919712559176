import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../utils/helpers';
import { TranslationHelperService } from '../services/translations/translation-helper.service';
import { Router } from '@angular/router';

@Directive({
  selector: '[appLangRouterLink]'
})
export class LangRouterLinkDirective implements OnDestroy {

  @Input('appLangRouterLink') routerLink: string | string[];

  private subscriptions: Subscription[] = [];
  private lang: string;

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (!event.ctrlKey && event.button === 0) {
      this.navigate();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  constructor(
    private transHelper: TranslationHelperService,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.transHelper.getLanguage().subscribe(lang => {
        this.lang = lang;
        this.renderer.setAttribute(this.elementRef.nativeElement, 'href', this.computeLink());
      }),
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  navigate(): void {
    this.router.navigateByUrl(this.computeLink());
  }

  computeLink() {
    const path = Array.isArray(this.routerLink) ? this.routerLink.join('/') : this.routerLink;
    return `/${this.lang}/${path}`;
  }
}
