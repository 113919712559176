import { Injectable } from '@angular/core';
import { Authority } from './Authority';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ClientState } from './ClientState';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class AuthenticationService {

  protected static readonly LOCALSTORAGE_KEY = 'be.charliebravo.qos3.authorization';

  protected backendUrl: string = environment.statistics.backend.baseUrl;

  authorizationString: string;

  clientState: ClientState;

  // clientStateSubject: Subject<ClientState> = new ReplaySubject(1);

  constructor(
    protected http: HttpClient,
  ) {
    this.load();
  }

  get authorities(): Array<Authority> {
    return this.clientState && this.clientState.authorities;
  }

  isAuthenticated(): boolean {
    return this.hasAuthority(Authority.ROLE_PRESENTER_USER);
  }

  isAdmin(): boolean {
    return this.hasAuthority(Authority.ROLE_PRESENTER_ADMIN);
  }

  hasAuthority(authority: Authority): boolean {
    return !!(this.authorities && this.authorities.indexOf(authority) !== -1);
  }

  save(): void {
    localStorage.setItem(AuthenticationService.LOCALSTORAGE_KEY, JSON.stringify({
      authorizationString: this.authorizationString,
      clientState: this.clientState.toPlain(),
    }));
  }

  load(): void {
    try {
      let str = localStorage.getItem(AuthenticationService.LOCALSTORAGE_KEY);
      if (str) {
        let raw = JSON.parse(str);
        this.authorizationString = raw.authorizationString;
        this.clientState = ClientState.fromPlain(raw.clientState);
      }
    } catch (err) {
      // console.error(this.constructor.name, 'load', err);
    }
  }

  authenticate(username: string, password: string): Observable<boolean> {
    let url = this.backendUrl + '/user/login';
    let body = { username: username, password: password };
    return this.http.post(url, body, { observe: 'response' }).pipe(
      map((response) => {
        console.log(this.constructor.name, 'authenticate', response);

        let authorizationString = response.headers.get('Authorization');
        let clientState = ClientState.fromAuthorizationString(authorizationString);

        this.authorizationString = authorizationString;
        this.clientState = clientState;
        // this.clientStateSubject.next(clientState);

        this.save();

        console.log(this.constructor.name, 'clientState', this.clientState);

        return true;
      }),
      catchError((err) => {
        console.error(this.constructor.name, 'authenticate', err);
        return of(false);
      })
    );
  }

  logout() {
    this.clientState = undefined;
    this.authorizationString = undefined;
  }

}
