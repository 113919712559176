<ng-container *ngIf="data">
    <h2>{{ "general.descriptions.title.description" | translate }}</h2>
  
    <div
      class="description mt-3"
      [innerHTML]="getDescription() | translate"
    ></div>
  
    <h2>{{ "general.descriptions.title.information" | translate }}</h2>
  
    <table class="metadata">
      <tr>
        <td class="label">{{ "general.descriptions.providedBy" | translate }}</td>
        <td class="value">
          {{
            data.metadata.dataProvidedBy || "general.notApplicable" | translate
          }}
        </td>
      </tr>
      <tr>
        <td class="label">
          {{ "general.descriptions.lastValidatedAt" | translate }}
        </td>
        <td class="value capitalize">
          {{
            data.metadata.lastValidatedAt
              ? getLocalizedDate(data.metadata.lastValidatedAt)
              : data.metadata.lastValidated
              ? (data.metadata.lastValidated | translate)
              : "N/A"
          }}
        </td>
      </tr>
    </table>
  </ng-container>
  