<div class="pt-4 d-none d-xl-block filter-component">
  <div class="d-flex justify-content-center w-100">
    <ul class="nav nav-pills nav-top" id="group-list">
      <li
        class="nav-item"
        role="presentation"
        *ngFor="let group of groups; index as i"
      >
        <a
          routerLink="{{ lang }}/{{ group.routerNew }}"
          [ngClass]="{'active': checkGroup(group)}"
          class="nav-link upper-group-link rounded-0"
          id="group{{ i }}"
          type="button"
          role="tab"
          aria-selected="true"
          appCloseSidenav="MAIN"
          (click)="onClickGroup(group.group)"
        >
          {{ group.group | translate }}
        </a>
      </li>
    </ul>
    <div class="links">
      <app-language-selector></app-language-selector>
    </div>
  </div>
  <div class="d-flex w-100 nav-member-parent">
    <div class="container">
      <ul class="nav nav-pills nav-top" id="member-list">
        <li
          class="nav-item"
          role="presentation"
          *ngFor="let member of selectedGroup?.members; index as j"
        >
          <a
            *ngIf="route"
            [class.active]="checkMember(member)"
            class="rounded-0 nav-member"
            id="member{{ j }}"
            data-toggle="pill"
            data-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
            appCloseSidenav="MAIN"
            routerLink="{{ lang }}/{{ member.routerLink }}"
            [attr.disabled]="member.routerLink ? null : true"
            [class.disabled]="!member.routerLink"
            (click)="onClickMember(member.title)"
          >
            {{ member.title | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
