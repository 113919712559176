import { Extract, ExtractOptions, ExtractType } from '../extract';
import { DOMAIN } from '../../../models/domain/domain';

export abstract class AbstractBarometerExtract extends Extract {

  type = ExtractType.COMPARISON;
  chartType = 'bar';
  canChangeChartType = true;
  informationUrlBase = './assets/extract-info/:language/';

  choices = {
    dataLabel: DOMAIN.barometerPeriods,
  };

  constructor(options: ExtractOptions) {
    super(options);
  }

  getInformationUrl() {
    return this.informationUrlBase + this.title + '.html';
  }
}
