import { Component, OnInit } from '@angular/core';
import { OLLegendDefinition } from 'src/app/models/layer';

@Component({
  selector: 'app-atlas-ftth-legend',
  templateUrl: './atlas-ftth-legend.component.html',
  styleUrls: ['./atlas-ftth-legend.component.scss']
})
export class AtlasFtthLegendComponent implements OnInit {

  legend: OLLegendDefinition;

  ngOnInit() {
    this.legend = {
      values: [
        { color: "#F8595B", position: 0, label: "labels.planned" },
        { color: "#32A8E0", position: 1, label: "labels.1_network" },
        { color: "#292A81", position: 2, label: "labels.2_networks" },
        { color: "#D06516", position: 3, label: "labels.3plus_networks" },
      ],
    };
  }
}