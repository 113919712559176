export enum BarometerPeriodEnum {
  _2016S1 = '2016 S1',
  _2016S2 = '2016 S2',
  _2017S1 = '2017 S1',
  _2017S2 = '2017 S2',
  _2018S1 = '2018 S1',
  _2018S2 = '2018 S2',
  _2019S1 = '2019 S1',
  _2019S2 = '2019 S2',
}
