import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { AreaService } from "../../services/area/area.service";
import { Subject, Subscription } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { Area } from "../../services/area/area";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { FormControl } from "@angular/forms";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrEmpty,
  unsubscribe,
} from "../../utils/helpers";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { isThisTypeNode } from "typescript";

@Component({
  selector: "app-area-select",
  templateUrl: "./area-select.component.html",
  styleUrls: ["./area-select.component.scss"],
})
export class AreaSelectComponent implements OnInit, OnDestroy {
  readonly icons = {
    falSpinnerThird,
  };

  subscriptions: Subscription[] = [];
  private _selectedArea: Area;
  // @formatter:off

  @Input() get selectedArea() {
    return this._selectedArea;
  }

  @Output() selectedAreaChange = new EventEmitter();

  set selectedArea(val: Area) {
    this._selectedArea = val;
    this.selectedAreaChange.emit(this._selectedArea);
  }

  // @formatter:on

  searchPostcodeForm = new FormControl("");
  searchStreetForm = new FormControl({ value: "", disabled: true });

  searchTermSubject: Subject<string | Area> = new Subject();

  results: Area[];
  results2: Area[];
  loading: boolean = false;

  constructor(
    protected areaService: AreaService,
    protected translateHelperService: TranslationHelperService
  ) {
    unsubscribe(this.subscriptions)
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.searchPostcodeForm.valueChanges.subscribe((value) => {
        this.searchStreetForm.setValue("");
        isNullOrUndefinedOrEmpty(value)
          ? this.searchStreetForm.disable()
          : this.searchStreetForm.enable();
        this.search(this.searchPostcodeForm.value);
        if (
          !isNaN(parseInt(this.searchPostcodeForm.value)) &&
          this.searchPostcodeForm.value.length > 3
        ) {
          this.searchStreetForm.enable();
        }
      })
    );
    this.subscriptions.push(
      this.searchStreetForm.valueChanges.subscribe(() =>
        this.searchStreet(
          this.searchPostcodeForm.value,
          this.searchStreetForm.value
        )
      ))
  }

  private search(searchFirstTerm: Area) {
    this.loading = true;
    this.subscriptions.push(
      this.areaService
        .searchAddress(
          typeof searchFirstTerm === "string"
            ? searchFirstTerm
            : searchFirstTerm.postcode
              ? searchFirstTerm.postcode
              : searchFirstTerm.niscode
        )
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(
          (res: any) => {
            if (res) {
              this.results = (res as Area[]).filter(
                (area) => !isNaN(Number(area.id))
              );
            } else {
              this.results = res;
            }
          }
        ))
  }
  private searchStreet(searchFirstTerm: Area, searchSecondTerm: Area) {
    this.loading = true;
    this.subscriptions.push(
      this.areaService
        .searchStreet(
          typeof searchFirstTerm === "string"
            ? searchFirstTerm
            : searchFirstTerm.postcode
              ? searchFirstTerm.postcode
              : searchFirstTerm.niscode,
          typeof searchSecondTerm === "string"
            ? searchSecondTerm
            : searchSecondTerm.name
        )
        .pipe(
          finalize(() => (this.loading = false)),
          map((areas) => areas.map(Area.fromPlain))
        )
        .subscribe(
          (res: Area[]) => {
            this.results2 = res;
          }
        ))
  }

  onSelect($event: MatAutocompleteSelectedEvent) {
    if (!isNaN(parseInt(this.searchPostcodeForm.value.postcode))) {
      this.searchStreetForm.enable();
    }
    this.selectedArea = $event.option.value;
  }

  onFocus(event: FocusEvent) {
    let element: any = event.target;
    element.setSelectionRange(0, element.value.length);
  }

  clear() {
    if (!isNullOrUndefined(this.selectedArea)) {
      this.selectedArea = undefined;
    }
  }

  getOptionText(option) {
    return option.postcode || option.name || option.name_fr;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
  // TODO
  // clear() {
  //   this.selectedArea = undefined;
  //   this.oldValue = undefined;
  //   this.searchTerm = '';
  //   this.results = [];
  //   this.loading = false;
  // }
  //
  // reset() {
  //   this.selectedArea = this.oldValue;
  //   this.oldValue = undefined;
  //   this.loading = false;
  // }

  // onClick(event) {
  //   this.oldValue = this.selectedArea;
  //   this.selectedArea = undefined;
  //   setTimeout(() => {
  //     this.searchInput.nativeElement.focus();
  //     this.searchInput.nativeElement.select();
  //     if (this.searchTerm.length > 0) {
  //       this.search();
  //     }
  //   }, 0);
  // }

  // onFocusOut(event) {
  //   if (this.oldValue) {
  //     setTimeout(() => {
  //       this.results = [];
  //       if (this.selectedArea == undefined) {
  //         this.reset();
  //       }
  //     }, 300);
  //   }
  // }
}
