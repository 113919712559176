import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { objectToQueryString } from '../../utils/url';
import { LegendDefinition } from '../../models/layer';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit, OnChanges {

  @Input() definition: LegendDefinition;

  @Input() width: number = 100;

  @Input() height: number = 15;

  source: string;

  valuesEntries: Array<{position: number, label: string}> = [];

  constructor(
    protected http: HttpClient,
  ) { }

  ngOnInit() {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  update() {
    this.valuesEntries = Object.keys(this.definition.values).map((key) => ({position: this.definition.values[key], label: key}));
    this.source = this.definition.url + objectToQueryString({width: this.width, height: this.height});
  }

}
