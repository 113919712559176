export class HSLColor {
  constructor(
    public readonly h: number = 0, // hue: 0..360
    public readonly s: number = 0, // saturation: 0..100(%)
    public readonly l: number = 0, // lightness: 0..100(%)
    public readonly a: number = 1, // alpha: 0...1
  ) {

  }

  setHue(hue: number) {
    return new HSLColor(
      hue,
      this.s,
      this.l,
      this.a,
    );
  }

  setSaturation(saturation: number) {
    return new HSLColor(
      this.h,
      saturation,
      this.l,
      this.a,
    );
  }

  setLightness(lightness: number) {
    return new HSLColor(
      this.h,
      this.s,
      lightness,
      this.a,
    );
  }

  setAlpha(alpha: number) {
    return new HSLColor(
      this.h,
      this.s,
      this.l,
      alpha,
    );
  }

  darken(percent: number): HSLColor {
    return this.lighten(-percent);
  }

  lighten(percent: number): HSLColor {
    return new HSLColor(
      this.h,
      this.s,
      Math.min(Math.max(this.l + percent, 0), 100),
      this.a,
    );
  }

  rotateHue(degrees: number): HSLColor {
    return new HSLColor(
      (this.h + degrees) % 360,
      this.s,
      this.l,
      this.a,
    );
  }

  saturate(percent: number): HSLColor {
    return new HSLColor(
      this.h,
      Math.min(Math.max(this.s + percent, 0), 100),
      this.l,
      this.a,
    );
  }

  desaturate(percent: number): HSLColor {
    return this.saturate(-percent);
  }

  transparentize(percent: number) {
    return this.opacify(-percent);
  }

  opacify(percent: number) {
    return new HSLColor(
      this.h,
      this.s,
      this.l,
      Math.min(Math.max(this.a + (percent / 100), 0), 1),
    );
  }

  clone() {
    return new HSLColor(
      this.h,
      this.s,
      this.l,
      this.a,
    );
  }

  toHSLString() {
    return `hsla(${this.h}, ${this.s}%, ${this.l}%)`;
  }

  toHSLAString() {
    return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${this.a})`;
  }

  toJSON(): { h: number, s: number, l: number, a: number } {
    return {
      h: this.h,
      s: this.s,
      l: this.l,
      a: this.a,
    };
  }

  static fromJSON(json: { h: number, s: number, l: number, a: number }): HSLColor {
    return new HSLColor(
      json.h,
      json.s,
      json.l,
      json.a,
    );
  }

  static fromHSLString(rgbString: string): HSLColor {
    let matches = rgbString.match(/hsla?\(\s*([0-9]+)\s*,\s*([0-9]+)%\s*,\s*([0-9]+)%(?:,\s*([0-9.]+))?\s*\)/i);
    return new HSLColor(
      parseInt(matches[1], 10),
      parseInt(matches[2], 10),
      parseInt(matches[3], 10),
      parseInt(matches[4], 10) || 1,
    );
  }

  static fromArray(array: [number, number, number] | [number, number, number, number]): HSLColor {
    return new HSLColor(
      array[0],
      array[1],
      array[2],
      array[2] || 1,
    );
  }
}
