import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TranslationHelperService } from '../services/translations/translation-helper.service';

@Injectable()
export class RedirectToLanguageGuard implements CanActivate {

  constructor(
    private translationHelper: TranslationHelperService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let language = this.translationHelper.getDefaultLanguage();
    this.router.navigate([language]);

    return false;
  }

}
