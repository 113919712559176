<app-download-app-banner></app-download-app-banner>
<div class="container-fluid">
  <div class="row">
    <app-map [dataset]="dataset" [filter]="mapFilter" [filters]="mapFilters" *isBrowser></app-map>
  </div>
</div>
<div class="container">
  <div class="row breathe">
    <app-data-description [data]="dataDescription"></app-data-description>
  </div>
  <div class="row breathe charts">
    <h2>{{ 'general.descriptions.title.charts' | translate }}</h2>
    <div *ngFor="let extract of extracts" class="w-100 mb-5">
      <!--<div class="h-100 pr-3 pb-3 chart-wrapper">-->

      <app-chart-distribution *ngIf="extract.type.toString() == 'DISTRIBUTION'" [extract]="extract" (updateRequest)="updateExtracts([$event])"></app-chart-distribution>
      <app-chart-timeseries *ngIf="extract.type.toString() == 'TIMESERIES'" [extract]="extract" (updateRequest)="updateExtracts([$event])"></app-chart-timeseries>
      <app-chart-comparison *ngIf="extract.type.toString() == 'COMPARISON'" [extract]="extract" (updateRequest)="updateExtracts([$event])"></app-chart-comparison>

      <!--</div>-->
    </div>
  </div>
</div>
