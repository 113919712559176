import { Injectable, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslationHelperService } from './translations/translation-helper.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../utils/helpers';

@Injectable({
  providedIn: 'root'
})
export class MetadataService implements OnDestroy {
  subscriptions: Subscription[] = [];

  protected title?: string;
  protected subtitle?: string;

  constructor(
    private meta: Meta,
    protected translation: TranslationHelperService,
  ) {
    unsubscribe(this.subscriptions);
  }

  setCommon() {
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    // this.meta.updateTag({property: 'og:app_id', content: environment.facebookAppId})
    this.meta.updateTag({ property: 'og:image:width', content: '1280' });
    this.meta.updateTag({ property: 'og:image:height', content: '670' });
  }

  setTitle(title: string) {
    this.title = title;
    this.updateTitle();
  }

  setSubtitle(label: string) {
    this.subscriptions.push(
      this.translation.get(label).subscribe(
        subtitle => {
          this.subtitle = subtitle;
          this.updateTitle();
        },
        (err) => console.error(this.constructor.name, 'setSubtitle', err),
      ))
  }

  updateTitle() {
    const fullTitle = this.title + (this.subtitle ? ' - ' + this.subtitle : '');
    document.title = fullTitle;
    this.meta.updateTag({ property: 'og:title', content: fullTitle });
  }

  setImage(url: string) {
    this.meta.updateTag({ property: 'og:image:url', content: url });
  }

  setDescription(label: string) {
    this.subscriptions.push(
      this.translation.get(label).subscribe(
        description => this.meta.updateTag({ property: 'og:description', content: description }),
        (err) => console.error(this.constructor.name, 'setDescription', err),
      ))
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
