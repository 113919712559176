import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';
import { DOMAIN } from '../../../models/domain/domain';
import { ServiceEnum } from '../../../models/domain/service-enum';

@DeclareExtract('signal_coverage_comparison')
export class SignalCoverageComparisonExtract extends AbstractBarometerExtract {

  name = 'signal_coverage_comparison';

  xUnit = '%';

  get title() {
    return this.name + '.' + this.filter.get('service') + '.' + this.filter.get('measure');
  }

  getInformationUrl() {
    return this.informationUrlBase + this.name + '.' + this.filter.get('service') + '.html';
  }

  choices = {
    service: [ServiceEnum._2G, ServiceEnum._3G, ServiceEnum._4G],
    measure: ['TERRITORY', 'POPULATION'],
    dataLabel: DOMAIN.barometerPeriods,
  };
}
