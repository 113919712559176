import { Component, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  faMousePointer,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { unsubscribe } from "../../utils/helpers";
import { Subscription } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { IndicatorMap } from "./models/indicator-map";
import { Indicator } from "./models/indicator";
import { ActivatedRoute } from "@angular/router";
import { arraySet } from "../../utils/arrays";
import { ProjectService } from "src/app/services/project.service";

@Component({
  selector: "app-indicators-page-component",
  templateUrl: "./indicators-page.component.html",
  styleUrls: ["./indicators-page.component.scss"],
})
export class IndicatorsPageComponent implements OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  readonly icons = {
    loader: faSpinnerThird,
    noSelection: faMousePointer,
  };

  indicators: Indicator[];
  loading = true;
  error: any;

  group: string;
  short: string;
  breakdown: string;
  unit: string;

  get groups(): string[] {
    return arraySet(this.indicators.map((i) => i.group));
  }

  get shorts(): string[] {
    return arraySet(
      this.indicators.filter((i) => i.group === this.group).map((i) => i.short)
    );
  }

  get breakdowns(): string[] {
    return arraySet(
      this.indicators
        .filter((i) => i.group === this.group && i.short === this.short)
        .map((i) => i.breakdown)
    );
  }

  get units(): string[] {
    return arraySet(
      this.indicators
        .filter(
          (i) =>
            i.group === this.group &&
            i.short === this.short &&
            i.breakdown === this.breakdown
        )
        .map((i) => i.unit)
    );
  }

  get indicator(): Indicator {
    return this.indicators.find(
      (i) =>
        i.group === this.group &&
        i.short === this.short &&
        i.breakdown === this.breakdown &&
        i.unit === this.unit
    );
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly translationHelper: TranslationHelperService,
    private readonly route: ActivatedRoute,
    private projectService:ProjectService
  ) {
    const group = projectService.groups.filter(c=>c.group == "Data")[0]
    projectService.selectGroup(group)
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.translationHelper
        .getLanguage()
        .pipe(
          tap(() => {
            this.group = undefined;
            this.short = undefined;
            this.breakdown = undefined;
            this.unit = undefined;
          }),
          switchMap((language) =>
            this.httpClient.get<IndicatorMap>(
              environment.indicators.api.url + "/v1/indicators",
              { params: { language } }
            )
          ),
          tap((value) => {
            this.indicators = value as any;
          }),
          switchMap(() => this.route.queryParamMap.pipe(take(1)))
        )
        .subscribe({
          next: () => {
            this.updateGroups();
            this.loading = false;
          },
          error: (err) => {
            this.error = err;
            console.log(err);
            this.loading = false;
          },
        }))
  }

  ngOnDestroy() {
    unsubscribe(this.subscriptions);
  }

  updateGroups() {
    const groups = this.groups;

    if (groups.length) {
      const group = this.route.snapshot.queryParamMap.get("group");
      this.group = group || groups[0];
    } else {
      this.group = undefined;
    }
    this.updateShorts();
  }

  updateShorts() {
    const shorts = this.shorts;

    if (shorts.length) {
      const short = this.route.snapshot.queryParamMap.get("short");
      this.short = short || shorts[0];
    } else {
      this.short = undefined;
    }
    this.updateBreakdown();
  }

  updateBreakdown() {
    const breakdowns = this.breakdowns;

    if (breakdowns.length) {
      const breakdown = this.route.snapshot.queryParamMap.get("breakdown");
      this.breakdown = breakdown || breakdowns[0];
    } else {
      this.breakdown = undefined;
    }

    this.updateUnit();
  }

  updateUnit() {
    const units = this.units;

    if (units.length) {
      const unit = this.route.snapshot.queryParamMap.get("unit");
      this.unit = unit || units[0];
    } else {
      this.unit = undefined;
    }
  }
}
