<div class="container-fluid page-header bg-1 bg-image">
  <div class="bg-image-overlay"></div>
  <div class="page-header-inner">
    <h1>{{'ui.applications.title' | translate}}</h1>
    <h2 class="subtitle">{{ 'ui.applications.description' | translate }}</h2>
  </div>
</div>

<div class="container-fluid data-sources">
  <div class="container breathe">
    <div class="row no-gutters">

      <div class="app-card-wrapper col-xl-4 col-lg-6 col-md-12 col-sm-12 p-3" *ngFor="let app of applications">
        <a class="app-card" [attr.href]="app.link || (app.links && app.links[language]) || null" target="_blank" [class.disabled]="!app.link">
          <div class="app-icon">
            <div *ngIf="app.image" class="image" [style.background-image]="'url('+app.image+')'"></div>
            <div *ngIf="app.icon && !app.image" class="icon">
              <fa-icon [icon]="app.icon"></fa-icon>
            </div>
          </div>
          <div class="app-data pl-3">
            <div class="name">{{app.name | translate}}</div>
            <div class="description">{{app.description | translate}}</div>
            <div class="platforms" *ngIf="!app.comingSoon">
              <fa-icon [icon]="platform" *ngFor="let platform of app.platforms"></fa-icon>
            </div>
            <div class="coming-soon" *ngIf="app.comingSoon">
              <span class="badge badge-primary">{{ 'ui.applications.soon' | translate }}</span>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
</div>
