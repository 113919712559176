import { NgModule } from "@angular/core";
import { AtlasTreinLegendComponent } from "./components/atlas-trein-legend/atlas-trein-legend.component";
import { AtlasTreinMapComponent } from "./map/atlas-trein-map.component";
import { AtlasTreinPageComponent } from "./pages/atlas-trein-page/atlas-trein-page.component";
import { AtlasTreinFilterComponent } from "./filter/atlas-trein-filter.component";
import { SharedModule } from "../_shared/shared.module";
import { AtlasTreinDescriptionComponent } from "./components/atlas-trein-description/atlas-trein-description.component";
import { TreinFormService } from "./services/trein-form.service";
import { TreinMapService } from "./services/trein-map.service";

@NgModule({
  declarations: [
    AtlasTreinLegendComponent,
    AtlasTreinMapComponent,
    AtlasTreinPageComponent,
    AtlasTreinFilterComponent,
    AtlasTreinDescriptionComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [],
  providers: [TreinFormService, TreinMapService],
})
export class TreinmetingenModule {}
