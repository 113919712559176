import { todayMidnight } from '../moment-utils';

const TODAY_MIDNIGHT = todayMidnight();

export class PeriodEnum {
  static readonly _1_WEEK = { from: TODAY_MIDNIGHT.subtract(1, 'week').toDate(), to: TODAY_MIDNIGHT.toDate() };
  static readonly _1_MONTH = { from: TODAY_MIDNIGHT.clone().subtract(1, 'month').toDate(), to: TODAY_MIDNIGHT.toDate() };
  static readonly _3_MONTHS = { from: TODAY_MIDNIGHT.clone().subtract(3, 'month').toDate(), to: TODAY_MIDNIGHT.toDate() };
  static readonly _6_MONTHS = { from: TODAY_MIDNIGHT.clone().subtract(6, 'month').toDate(), to: TODAY_MIDNIGHT.toDate() };
  static readonly _1_YEAR = { from: TODAY_MIDNIGHT.clone().subtract(1, 'year').toDate(), to: TODAY_MIDNIGHT.toDate() };
}

export function comparePeriod(a, b) {
  return (a && b || !a && !b) && (a.from.getTime() - b.from.getTime() === 0) && (a.to.getTime() - b.to.getTime() === 0);
}
