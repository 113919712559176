import { Component} from "@angular/core";
import { ProjectService } from "../../services/project.service";
import { environment } from "../../../environments/environment";
import { MetadataService } from "../../services/metadata.service";
import { Subscription } from "rxjs";
import { Project } from "src/app/models/project";
import { NavigationEnd, Router } from "@angular/router";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";

@Component({
  selector: "app-atlas-page",
  templateUrl: "./atlas-page.component.html",
  styleUrls: ["./atlas-page.component.scss"],
})
export class AtlasPageComponent {
  subscriptions: Subscription[] = [];
  lang: string;
  projects: Project[];

  constructor(
    protected projectService: ProjectService,
    protected metadata: MetadataService,
    private router:Router,
    protected translationHelper: TranslationHelperService,
  ) {
    this.metadata.setImage(
      environment.baseHref + "assets/images/og/data-portal.png"
    );
    this.metadata.setSubtitle("home.title");
    this.metadata.setDescription("home.subtitle");
    
    this.translationHelper.getLanguage().subscribe(lang => {
      this.lang = lang;
    })
    this.projects = this.projectService.projects.filter(c=>c.routerLink.includes("atlas"));
    this.projectService.select(null);
    const group = projectService.groups.filter(c=>c.group == "Atlas")[0]
    projectService.selectGroup(group)
  }
  selectProject(project: Project) {
    this.router.navigate([this.lang + '/' + project.routerLink])
    this.projectService.select(project);
  }
}
