import { Component, OnInit } from "@angular/core";

import { faFilePdf as falFilePdf } from "@fortawesome/pro-light-svg-icons/faFilePdf";
import { AbstractPage } from "../abstract-page";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { MetadataService } from "../../services/metadata.service";
import { environment } from "../../../environments/environment";
import { UserExperienceService } from "src/app/services/user-experience/user-experience.service";
import {
  TestCategory,
  TestData,
  DataSummary,
  TestSummary,
  UserExperience,
} from "src/app/datasets/user-experience.model";
import { ProjectService } from "src/app/services/project.service";

@Component({
  selector: "app-drive-test-page",
  templateUrl: "./drive-test-page.component.html",
  styleUrls: ["./drive-test-page.component.scss"],
})
export class DriveTestPageComponent extends AbstractPage implements OnInit {
  readonly icons = {
    falFilePdf,
  };
  constructor(
    protected translationHelper: TranslationHelperService,
    protected metadata: MetadataService,
    private userExperienceService: UserExperienceService,
    private projectService:ProjectService
  ) {
    super();
    this.metadata.setImage(
      environment.baseHref + "assets/images/og/drive-test.png"
    );
    this.metadata.setSubtitle("ui.projects.drivetesting.title");
    this.metadata.setDescription("ui.projects.drivetesting.description");
    const group = projectService.groups.filter(c=>c.group == "Data")[0]
    projectService.selectGroup(group)
  }
  tabNames: string[];
  tableNames: string[];
  providers: string[];
  AllValues: UserExperience[];

  driveTestSummary: TestSummary;
  driveDataSummary: DataSummary;
  trainTestSummary: TestSummary;
  trainDataSummary: DataSummary;

  selectedData: string;
  subjectSummary: TestSummary;
  subjectDataSummary: DataSummary;

  ngOnInit() {
    this.addSubscription(
      this.userExperienceService
        .getTableValues()
        .subscribe((val: UserExperience[]) => {
          this.AllValues = val;
          this.distinct(val);
          this.mapTestSummaries();
          this.mapDataSummary();
        }))
  }
  distinct(val: UserExperience[]) {
    this.tabNames = [...new Set(val.map((obj) => obj.tabName))].sort();
    this.tableNames = [...new Set(val.map((obj) => obj.tableName))].sort();
    this.providers = [...new Set(val.map((obj) => obj.provider).filter(provider => provider !== ''))];
    this.selectedData = this.tabNames[0];    
  }
  mapTestSummaries() {
    const processTestData = (
      summary: TestSummary,
      categoryMap: { [key: string]: TestCategory },
      tabName: string,
      tableName: string
    ) => {
      this.AllValues.filter(
        (t) => t.tabName === tabName && t.tableName === tableName
      ).forEach((data) => {
        const { subTable, header, provider, value } = data;

        summary.tableName = summary.tableName || tableName;

        if (!summary.providers.includes(provider) && provider !== '') {
          summary.providers.push(provider);
        }

        const categoryKey = `${tableName}|${subTable}`;

        if (!categoryMap[categoryKey]) {
          const category: TestCategory = {
            subTable,
            data: [],
          };
          categoryMap[categoryKey] = category;
          summary.categories.push(category);
        }

        const existingTestData = categoryMap[categoryKey].data.find(
          (testData) => testData.header === header
        );

        if (existingTestData) {
          existingTestData.values.push(value);
        } else {
          const testData: TestData = {
            header,
            ratio: data.ratio,
            values: [value],
          };
          categoryMap[categoryKey].data.push(testData);
        }
      });      
    };

    const driveTestSummary: TestSummary = {
      tableName: "",
      providers: [],
      categories: [],
    };

    const driveCategoryMap: { [key: string]: TestCategory } = {};

    processTestData(
      driveTestSummary,
      driveCategoryMap,
      this.tabNames[0],
      this.tableNames[1]
    );
    this.driveTestSummary = this.subjectSummary = driveTestSummary;

    const trainTestSummary: TestSummary = {
      tableName: "",
      providers: [],
      categories: [],
    };

    const trainCategoryMap: { [key: string]: TestCategory } = {};

    processTestData(
      trainTestSummary,
      trainCategoryMap,
      this.tabNames[1],
      this.tableNames[1]
    );
    this.trainTestSummary = trainTestSummary;
  }

  mapDataSummary() {
    const transformedDriveData: DataSummary = {
      tableName: this.tableNames[0],
      providers: [],
      categories: [],
    };
    const transformedTrainData: DataSummary = {
      tableName: this.tableNames[0],
      providers: [],
      categories: [],
    };

    const processItem = (item, transformedData) => {
      const existingCategory = transformedData.categories.find(
        (category) => category.subTable === item.subTable
      );
      if (existingCategory) {
        const existingRow = existingCategory.rows.find(
          (row) => row.header === item.header
        );
        if (existingRow) {
          const existingSubRow = existingRow.subRows.find(
            (subRow) => subRow.ratio === item.ratio
          );
          if (existingSubRow) {
            existingSubRow.values.push(item.value);
          } else {
            existingRow.subRows.push({
              ratio: item.ratio,
              values: [item.value],
            });
          }
        } else {
          existingCategory.rows.push({
            header: item.header,
            info: [item.info],
            subRows: [{ ratio: item.ratio, values: [item.value] }],
          });
        }
      } else {
        transformedData.providers = this.providers;
        transformedData.categories.push({
          subTable: item.subTable,
          rows: [
            {
              header: item.header,
              info: [item.info],
              subRows: [{ ratio: item.ratio, values: [item.value] }],
            },
          ],
        });
      }
    };

    this.AllValues.filter(
      (av) =>
        av.tableName === this.tableNames[0] && av.tabName === this.tabNames[0]
    ).forEach((item) => processItem(item, transformedDriveData));

    this.AllValues.filter(
      (av) =>
        av.tableName === this.tableNames[0] && av.tabName === this.tabNames[1]
    ).forEach((item) => processItem(item, transformedTrainData));

    this.subjectDataSummary = transformedDriveData;
    this.driveDataSummary = transformedDriveData;
    this.trainDataSummary = transformedTrainData;
  }
  onSelectionChange($event) {
    switch ($event) {
      case this.tabNames[0]:
        this.selectedData = this.tabNames[0];
        this.subjectSummary = this.driveTestSummary;
        this.subjectDataSummary = this.driveDataSummary;
        break;
      case this.tabNames[1]:
        this.selectedData = this.tabNames[1];
        this.subjectSummary = this.trainTestSummary;
        this.subjectDataSummary = this.trainDataSummary;
        break;
      default:
        break;
    }
  }
}
