import { Component, OnDestroy } from "@angular/core";
import { StatisticsService } from "../../services/statistics/statistics.service";
import { AbstractPresentationPage } from "../abstract-presentation-page/abstract-presentation-page";
import { PROJECT_DESCRIPTIONS, ProjectService } from "../../services/project.service";
import { MetadataService } from "../../services/metadata.service";
import { environment } from "../../../environments/environment";
import { TranslationHelperService } from "../../services/translations/translation-helper.service";
import { DataSourceDescriptionData } from 'src/app/models/data-source-description-data.model';
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';
import { Area } from 'src/app/services/area/area';
import { AtlasMobileService } from 'src/app/services/ol-map/atlas-mobile.service';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({
  selector: "app-atlas-page",
  templateUrl: "./atlas-mobile-page.component.html",
  styleUrls: ["./atlas-mobile-page.component.scss"],
})
export class AtlasMobilePageComponent extends AbstractPresentationPage implements OnDestroy {

  dataDescription: DataSourceDescriptionData = PROJECT_DESCRIPTIONS.atlasMobile;

  constructor(
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
    protected translationHelper: TranslationHelperService,
    public olMapService: OLMapService,
    private atlasMobilService: AtlasMobileService,
    private projectService:ProjectService
  ) {

    super(statistics);
    const group = projectService.groups.filter(c=>c.group == "Atlas")[0]
    projectService.selectGroup(group)
    this.metadata.setImage(
      environment.baseHref + "assets/images/og/atlas-mobile3.png"
    );
    this.setParameters();
    this.setByZone();
    this.setDetailed();
    this.metadata.setSubtitle("ui.projects.atlasmobile.title");
    this.metadata.setDescription("ui.projects.atlasmobile.description");
  }

  private setByZone() {
    this.subscriptions.push(this.atlasMobilService.setByzone())
  }
  private setDetailed() {
    this.subscriptions.push(this.atlasMobilService.setDetailed())
  }
  private setParameters() {
    this.atlasMobilService.setDefaultParams();
  }
  searchArea(area: Area) {
    this.olMapService.searchArea(area);
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
