<div class="container breathe mt-5">
  <div class="row" *ngFor="let extract of extracts; let i = index">
    <div class="container-fluid">
      <div class="row">
        <h2 class="title" [id]="extract.name">{{ 'title.' + extract.title | translate }}</h2>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <app-chart-distribution *ngIf="extract.type.toString() == 'DISTRIBUTION'" [extract]="extract" (updateRequest)="updateExtracts([$event])" [hideTitle]="true"></app-chart-distribution>
          <app-chart-timeseries *ngIf="extract.type.toString() == 'TIMESERIES'" [extract]="extract" (updateRequest)="updateExtracts([$event])" [hideTitle]="true"></app-chart-timeseries>
          <app-chart-comparison
            *ngIf="extract.type.toString() == 'COMPARISON'"
            [extract]="extract"
            (updateRequest)="updateExtracts([$event])"
            (typeChange)="onChartTypeChange(extract, $event)"
            [hideTitle]="true"></app-chart-comparison>
        </div>
        <div class="col-xl-6 p-3 info">
          <app-extract-info [extract]="extract"></app-extract-info>
        </div>
      </div>
      <div class="row breathe" *ngIf="i+1 !== extracts.length">
        <div class="separator"></div>
      </div>
    </div>
  </div>
</div>
