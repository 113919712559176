import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserExperience } from "src/app/datasets/user-experience.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserExperienceService {
  constructor(private http: HttpClient) {}
  getTableValues(): Observable<UserExperience[]> {
    return this.http.get<UserExperience[]>(environment.userExperience.api.url);
  }
}
