import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../utils/helpers';

@Component({
  selector: 'app-store-badge',
  templateUrl: './store-badge.component.html',
  styleUrls: ['./store-badge.component.scss']
})
export class StoreBadgeComponent implements OnDestroy, OnChanges {

  @Input() store: 'ios' | 'android';
  @Input() appUrl: string;

  badgeUrl: string;

  private subscriptions: Subscription[] = [];
  private lang: string;

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  constructor(private translateHelper: TranslationHelperService) {
    unsubscribe(this.subscriptions);
    this.subscriptions.push(
      this.translateHelper.getLanguage().subscribe(lang => {
        this.lang = lang;
        this.updateBadgeUrl();
      }),
    );
  }

  private updateBadgeUrl(): void {
    if (!this.store || !this.lang) {
      return;
    }
    this.badgeUrl = `/assets/images/badges/${this.store}-${this.lang.toUpperCase()}.${this.store === 'android' ? 'png' : 'svg'}`;
  }

  ngOnChanges(): void {
    this.updateBadgeUrl();
  }
}
