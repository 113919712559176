import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Area } from 'src/app/services/area/area';
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faSpinnerThird as falSpinnerThird } from "@fortawesome/pro-light-svg-icons/faSpinnerThird";
import { faFilter as falFilter } from "@fortawesome/pro-light-svg-icons/faFilter";
import { Subscription } from 'rxjs';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({ template: "" })
export abstract class AtlasFilterBaseComponent implements OnDestroy {

  @Output() selectedAreaChange = new EventEmitter();

  max: any | undefined;
  isLoading: boolean = false;
  lockSidenavOpen: boolean = true;

  selectedArea: Area;
  subscriptions: Subscription[] = [];

  readonly icons = {
    falTimes,
    falSpinnerThird,
    falFilter,
  };

  constructor() {
    unsubscribe(this.subscriptions)
  }
  abstract setFilter(filter: string, value: any)

  onSearchArea(area: Area): void {
    this.selectedAreaChange.emit(area);
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
