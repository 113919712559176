import { Component, OnDestroy, OnInit } from '@angular/core';
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss']
})
export class OlMapComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(
    public olMapService: OLMapService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(qp => {
      this.olMapService.mapConfig = qp;
    }));
    this.createMap();
    this.setOldLayers();
    this.setProj4Defs();
  }

  createMap() {
    this.olMapService.createMap();
  }
  setProj4Defs() {
    this.olMapService.setProj4Defs();
  }
  setOldLayers() {
    this.olMapService.layers.forEach((l) => {
      this.olMapService.map.addLayer(l.layer)
    })
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions)
  }
}
