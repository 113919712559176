import { DeclareExtract } from '../declare-extract.decorator';
import { AbstractBarometerExtract } from './abstract-barometer-extract';

@DeclareExtract('disruption_comparison')
export class DisruptionComparisonExtract extends AbstractBarometerExtract {

  name = 'disruption_comparison';

  xUnit = '%';

}
