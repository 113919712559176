import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataSourceDescriptionData } from 'src/app/models/data-source-description-data.model';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({ template: "" })
export abstract class AtlasDescriptionBaseComponent implements OnDestroy {

  @Input() data: DataSourceDescriptionData;

  locale: string;

  datePipe: DatePipe;

  showValidAndTestText: boolean = true;

  private subscriptions: Subscription[] = [];

  constructor(
    protected translationHelperService: TranslationHelperService,
    protected _router: Router,
  ) {
    unsubscribe(this.subscriptions)
    this.subscriptions.push(
      translationHelperService.getLocale().subscribe((locale) => {
        this.locale = locale;
        this.datePipe = new DatePipe(locale);
      })
    );
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  getDescription() {
    if (!this.locale) {
      return;
    }
    const prop = this.locale.replace("-", "_");
    return this.data.description[prop];
  }
  getDisclaimer() {
    if (!this.locale) {
      return;
    }
    const prop = this.locale.replace("-", "_");
    return this.data.disclaimer[prop];
  }
  getLocalizedDate(lastValidatedAt: Date): string {
    return this.datePipe
      ? this.datePipe.transform(lastValidatedAt, "MMMM y")
      : "N/A";
  }
}
