import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AtlasFilterBaseComponent } from '../../atlas-filter-base.component';
import { AtlasFtthFilterService } from 'src/app/services/filter/atlas-ftth-filter.service';
import { formatYearQuarter, getCurrentYearQuarter, reFormatYearQuarter } from 'src/app/utils/helpers';
import { MINQUARTER } from 'src/assets/constants/constants';
@Component({
  selector: 'app-atlas-ftth-filter',
  templateUrl: './atlas-ftth-filter.component.html',
  styleUrls: ['./atlas-ftth-filter.component.scss']
})
export class AtlasFtthFilterComponent extends AtlasFilterBaseComponent implements OnInit, OnChanges {
  public readonly Min_Quarter = MINQUARTER;
  @Input() lastQuarterDataAvailable?: boolean;

  constructor(
    public atlasFtthFilterService: AtlasFtthFilterService
  ) { super() }

  ngOnInit(): void {
    if (!this.lastQuarterDataAvailable) {
      this.max = getCurrentYearQuarter() - 0.25;
    } else {
      this.max = getCurrentYearQuarter();
    }
    if (this.atlasFtthFilterService.atlasFtthService.layerPostfix) {
      this.atlasFtthFilterService.value = reFormatYearQuarter(this.atlasFtthFilterService.atlasFtthService.layerPostfix)
      return
    }
    this.atlasFtthFilterService.value = this!.max;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.lastQuarterDataAvailable) {
      this.max = getCurrentYearQuarter() - 0.25;
    } else {
      this.max = getCurrentYearQuarter();
    }
    if (this.atlasFtthFilterService.atlasFtthService.layerPostfix) {
      this.atlasFtthFilterService.value = reFormatYearQuarter(this.atlasFtthFilterService.atlasFtthService.layerPostfix)
      return
    }
    this.atlasFtthFilterService.value = this!.max;
  }

  onChangeSlider(value: number): string {
    return this.atlasFtthFilterService.changeSlider(value);
  }

  setFilter(filter: string, value: any) {
    this.atlasFtthFilterService.setFilter(filter, value);
  }

  formatLabel(value: number) {
    return formatYearQuarter(value);;
  }
}
