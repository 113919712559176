import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Indicator } from '../models/indicator';

interface Detail {
  label: string;
  value: string;
}

@Component({
  selector: 'app-indicator-details-component',
  templateUrl: './indicators-details.component.html',
  styleUrls: ['./indicators-details.component.scss']
})
export class IndicatorsDetailsComponent implements OnChanges {

  @Input() indicator: Indicator;

  details: Detail[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['indicator']) {
      this.details = [
        { label: 'indicators.details.indicatorLong', value: this.indicator.indicatorLong },
        { label: 'indicators.details.definition', value: this.indicator.definition },
        { label: 'indicators.details.source', value: this.indicator.source },
      ];
    }
  }

}
