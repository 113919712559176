import { Subscription } from 'rxjs';
import { Directive } from '@angular/core';

@Directive()
export class AbstractPage {

  subscriptions: Array<Subscription> = [];

  addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  removeSubscription(subscription: Subscription) {
    let index = this.subscriptions.indexOf(subscription);
    if (index !== -1) {
      this.subscriptions.splice(index, 1);
    }
  }
}
