import { Component, OnInit } from '@angular/core';
import { AtlasDescriptionBaseComponent } from '../atlas-description-base.component';
import { TranslationHelperService } from 'src/app/services/translations/translation-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-atlas-ftth-description',
  templateUrl: './atlas-ftth-description.component.html',
  styleUrls: ['./atlas-ftth-description.component.scss']
})
export class AtlasFtthDescriptionComponent extends AtlasDescriptionBaseComponent implements OnInit {

  constructor(
    protected translationHelperService: TranslationHelperService,
    protected _router: Router
  ) { super(translationHelperService, _router) }

  ngOnInit(): void {
  }

}
