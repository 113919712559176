// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { LayerDefinition } from "../app/models/layer";

export const environment = {
  production: false,

  baseHref: "https://www.bipt-data.geodemo.be/",

  tileserver: {
    backgroundLayers: <{ [layerId: string]: LayerDefinition }>{
      minimalist: {
        url: "https://www.bipt-data.geodemo.be/tiles/{z}/{x}/{y}.png",
        attribution:
          'Cartography &copy; <a target="_blank" href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      },
      countryBoundaries: {
        url: "https://www.bipt-data.geodemo.be/mapnik/tiles/country_borders/{z}/{x}/{y}",
      },
    },
    dataLayers: <{ [layerId: string]: LayerDefinition }>{
      signalPoints: {
        url: "https://www.bipt-data.geodemo.be/tilemaker/tiles/signal_heatmapish/{z}/{x}/{y}",
        legend: {
          url: "https://www.bipt-data.geodemo.be/tilemaker/legend/signal_heatmapish",
          values: { 0: 0, 1: 0.33, 2: 0.67, 3: 1 },
        },
      },
      driveTestingWebBrowsing: {
        url: "https://www.bipt-data.geodemo.be/tilemaker/tiles/drive_test_web_browsing/{z}/{x}/{y}",
        legend: {
          url: "https://www.bipt-data.geodemo.be/tilemaker/legend/drive_test_web_browsing",
          values: { "2s": 0, "4s": 0.33, "8s": 1 },
        },
      },
      driveTestingThroughputDownlink: {
        url: "https://www.bipt-data.geodemo.be/tilemaker/tiles/drive_test_throughput_downlink/{z}/{x}/{y}",
        legend: {
          url: "https://www.bipt-data.geodemo.be/tilemaker/legend/drive_test_throughput_downlink",
          values: { "0": 0, "35mbps": 0.47, "75mbps": 1 },
        },
      },
      geoServer: {
        url: "https://bipt-geoserver-dev.northeurope.cloudapp.azure.com/geoserver",
      },
    },
  },
  geoServer: {
    baseUrl:
      "https://bipt-geoserver-dev.northeurope.cloudapp.azure.com/geoserver",
      dp_landline_overview: "dp_landline_overview",
      dp_landline_details: "dp_landline_details",
      dp_fiber_planned: "dp_fiber_planned",
      dp_fiber_rolledout: "dp_fiber_rolledout",
      statsec_details: "statsec_details",
      dp_mobile_detailed_overview: "dp_mobile_detailed_overview",
      dp_mobile_byzone_overview: "dp_mobile_byzone_overview",
  },
  statistics: {
    backend: {
      url: "https://www.bipt-data.geodemo.be/presenter/statistics",
      baseUrl: "https://www.bipt-data.geodemo.be/presenter",
    },
  },

  opendata: {
    atlasFix: {
      url: "https://www.bipt-data.geodemo.be/tilemaker/opendata/atlas_fix/{level}"
    },
    atlasMobileGrid: {
      url: "https://www.bipt-data.geodemo.be/tilemaker/opendata/atlas_mobile/grid/{provider}/{technology}",
    },
    atlasMobileArea: {
      url: "https://www.bipt-data.geodemo.be/tilemaker/opendata/atlas_mobile/area/{level}",
    },
    barometer: {
      url: "https://www.bipt-data.geodemo.be/presenter/opendata/barometer/{period}",
    },
  },

  indicators: {
    api: {
      url: "https://www.bipt-data.geodemo.be/indicators-backend/",
    },
  },
  userExperience: {
    api: {
      url: "https://www.bipt-data.geodemo.be/userexperience/api/v1/userexperience/getvalues",
    },
  },
  GeoServer: {
    api: {
      mobileUrl:
        "https://www.bipt-data.geodemo.be/geoserver/api/v1/mobile/getalldatastorenames",
      landlineUrl:
        "https://www.bipt-data.geodemo.be/geoserver/api/v1/landline/getalldatastorenames",
    },
  },
  elasticSearch: {
    municipality:
      "https://bipt-postaalpunt-be.azurewebsites.net/api/municipality",
    borough: "https://bipt-postaalpunt-be.azurewebsites.net/api/borough",
    street: "https://bipt-postaalpunt-be.azurewebsites.net/api/street",
    postcode: "https://bipt-postaalpunt-be.azurewebsites.net/api/postdistrict",
  },

  facebook: {
    appId: "833103614178556",
  },
};
