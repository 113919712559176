import { Component, NgZone } from '@angular/core';
import { AbstractChartJsComponent } from '../abstract-chartjs/abstract-chartjs.component';
import { ChartDataSets } from 'chart.js';
import { labelToColor } from '../../utils/labelToColor';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { Subscription } from 'rxjs';
import { BootstrapMediaService } from '../../services/bootstrap-media.service';

@Component({
  selector: 'app-chart-distribution',
  templateUrl: '../abstract-chartjs/abstract-chartjs.component.html',
  styleUrls: ['./chart-distribution.component.scss']
})
export class ChartDistributionComponent extends AbstractChartJsComponent {

  types: Array<string> = ['doughnut', 'pie'];

  updateSubscription: Subscription;

  constructor(
    protected statistics: StatisticsService,
    protected translationHelper: TranslationHelperService,
    protected breakpointObserver: BootstrapMediaService,
    protected zone: NgZone,
  ) {
    super(statistics, translationHelper, breakpointObserver, zone);
  }

  updateChart() {
    if (this.chart && this.extract && this.extract.data) {

      if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
        this.updateSubscription = undefined;
      }

      this.updateSubscription = this.translateLabels().subscribe(
        (translations: Array<string>) => {
          let chartData = this.chart.data;
          this.emptyChart();

          translations.sort();

          translations.forEach((translation) => {
            chartData.labels.push(translation);
          });

          let colors = translations.map(labelToColor);

          let dataset = <ChartDataSets>{
            data: Object.values(this.extract.data),
            backgroundColor: colors.map(c => c.toRGBAString()),
            borderColor: colors.map(c => c.darken(10).toRGBAString()),
            borderWidth: 2,
          };

          chartData.datasets.push(dataset);
          this.updateChartSize(false);
          this.chart.update();
        },
        (error) => console.error(this.constructor.name, 'updateChart', error),
      );
    }
  }

}
