export function sortLike<T>(reference: Array<T>) {
  return (a: T, b: T) => reference.indexOf(a) - reference.indexOf(b);
}

export function parallelSort<M, O>(main: Array<M>, other: Array<O>): { main: Array<M>, other: Array<O> } {
  let groups: Array<{ main: M, other: O }> = main.map((mainElement, index) => ({
    main: mainElement,
    other: other[index],
  }));

  groups.sort((groupA, groupB) => compare(groupA.main, groupB.main));

  return {
    main: groups.map(group => group.main),
    other: groups.map(group => group.other),
  };
}

export function compare(a, b) {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

export function inverse<T>(element: T, index: any, array: T[]): T {
  return array[array.length - 1 - index];
}

export function flatten(a, b) {
  return a.concat(b);
}

export function unique(e, i, a) {
  return a.indexOf(e) === i;
}

export function enumToArray<T>(myEnum: any): T[] {
  return <T[]>Object.values(myEnum).filter(unique);
}

export function arraySet(arr: string[]) {
  return Array.from(new Set(arr));
}
