import { Component, NgZone } from '@angular/core';
import { AbstractChartJsComponent } from '../abstract-chartjs/abstract-chartjs.component';
import { map } from 'rxjs/operators';
import { ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import { labelToColor } from '../../utils/labelToColor';
import { Observable, Subscription } from 'rxjs';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { flatten, parallelSort, unique } from '../../utils/arrays';
import { BootstrapMediaService } from '../../services/bootstrap-media.service';

@Component({
  selector: 'app-chart-comparison',
  templateUrl: '../abstract-chartjs/abstract-chartjs.component.html',
  styleUrls: ['./chart-comparison.component.scss']
})
export class ChartComparisonComponent extends AbstractChartJsComponent {

  types: Array<string> = ['bar', 'line'];

  updateSubscription: Subscription;

  constructor(
    protected statistics: StatisticsService,
    protected translationHelper: TranslationHelperService,
    protected breakpointObserver: BootstrapMediaService,
    protected zone: NgZone,
  ) {
    super(statistics, translationHelper, breakpointObserver, zone);
  }

  updateChart() {
    if (this.chart && this.extract && this.extract.data) {
      let unsortedLabels = Object.keys(this.extract.data);
      let dataLabelsGroups = [];
      for (let label of unsortedLabels) {
        let data: { [dataLabel: string]: any } = this.extract.data[label];
        let dataLabels = Object.keys(data);
        dataLabelsGroups.push(dataLabels);
      }

      if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
        this.updateSubscription = undefined;
      }

      this.updateSubscription = this.translateLabelsGroups(...[unsortedLabels].concat(dataLabelsGroups)).subscribe(
        (translationsGroups: Array<Array<string>>) => {
          let dataLabelsTranslationsGroups: Array<Array<string>> = translationsGroups.slice(1);

          let { main: labelTranslations, other: labels } = parallelSort(translationsGroups[0], unsortedLabels);

          let unsortedDataLabelsTranslations: Array<string> = dataLabelsTranslationsGroups
            .reduce(flatten, [] as Array<string>)
            .filter(unique)
            ;
          let unsortedDataLabels: Array<string> = dataLabelsGroups
            .reduce(flatten, [] as Array<string>)
            .filter(unique)
            ;
          let { main: dataLabelsTranslations, other: dataLabels } = parallelSort(unsortedDataLabelsTranslations, unsortedDataLabels);

          let chartData = this.chart.data;
          this.emptyChart();

          let groupLabels = [];

          let min;
          for (let i in labels) {
            let label = labels[i];
            let dataset = this.createChartJsDataset(i, labelTranslations[i]);

            let data = this.extract.data[label];

            for (let j in dataLabels) {
              let key = dataLabels[j];
              let dataLabelTranslation = dataLabelsTranslations[j];
              let value = data[<any>key];
              if (min === undefined || value < min) {
                min = value;
              }
              (<Array<ChartPoint>>dataset.data).push({
                x: dataLabelTranslation,
                y: value,
              });
              groupLabels.push(dataLabelTranslation);
            }
            chartData.datasets.push(dataset);
          }

          chartData.labels = groupLabels.filter(unique);

          if (min !== undefined) {
            (<any>this.chart.config.options.scales.yAxes[0]).ticks.suggestedMin = min - Math.abs(min * 0.1);
          }

          this.updateChartSize(false);
          this.chart.update();
        }
      );
    }
  }

  createChartJsDataset(i: string, label: string): ChartDataSets {
    let color = labelToColor(label);

    let options = {
      label: label,
      data: [],
      backgroundColor: color.toRGBAString(),
      borderColor: color.transparentize(25).toRGBAString(),
    };

    if (this.extract.chartType === 'line') {
      return Object.assign(options, {
        fill: false,
        lineTension: 0.1,
      });
    } else {
      return options;
    }
  }

  getOptions(): Observable<ChartOptions> {
    return super.getOptions().pipe(
      map(options => {
        if (this.extract.chartType === 'bar') {
          return Object.assign(options, {
            scales: {
              yAxes: [{
                scaleLabel: {
                  labelString: this.extract.xUnit,
                },
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
            animation: { duration: 0 },
          });
        } else {
          return options;
        }
      })
    );
  }
}
