import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ProjectService } from './project.service';
import { Project } from '../models/project';

@Injectable()
export class ProjectResolver implements Resolve<Project> {

  constructor(
    private projectService: ProjectService,
    private router: Router,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> | Promise<Project> | Project {
    const project = this.projectService.projects
      .filter(p => p.routerLink)
      .find(p => state.url.indexOf(p.routerLink) !== -1);
    if (!project) {
      return this.goToHomePage();
    }

    this.projectService.select(project);
    
    return project;
  }

  private goToHomePage(): undefined {
    this.router.navigateByUrl('/');
    return undefined;
  }
}
