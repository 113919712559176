import { HSLColor } from './HSLColor';

export class RGBColor {
  public static readonly WHITE       = new RGBColor(255, 255, 255, 1);
  public static readonly GREY        = new RGBColor(128, 128, 128, 1);
  public static readonly BLACK       = new RGBColor(  0,   0,   0, 1);
  public static readonly RED         = new RGBColor(255,   0,   0, 1);
  public static readonly GREEN       = new RGBColor(  0, 255,   0, 1);
  public static readonly BLUE        = new RGBColor(  0,   0, 255, 1);
  public static readonly TRANSPARENT = new RGBColor(  0,   0,   0, 0);

  constructor(
    public readonly r: number = 0, // red: 0..255
    public readonly g: number = 0, // green: 0..255
    public readonly b: number = 0, // blue: 0..255
    public readonly a: number = 1, // alpha: 0...1
  ) {

  }

  setRed(red: number) {
    return new RGBColor(
      red,
      this.g,
      this.b,
      this.a,
    );
  }

  setGreen(green: number) {
    return new RGBColor(
      this.r,
      green,
      this.b,
      this.a,
    );
  }

  setBlue(blue: number) {
    return new RGBColor(
      this.r,
      this.g,
      blue,
      this.a,
    );
  }

  setAlpha(alpha: number) {
    return new RGBColor(
      this.r,
      this.g,
      this.b,
      alpha,
    );
  }

  darken(percent: number): RGBColor {
    return RGBColor.fromHSLColor(
      this.toHSLColor()
        .darken(percent)
    );
  }

  lighten(percent: number): RGBColor {
    return RGBColor.fromHSLColor(
      this.toHSLColor()
        .lighten(percent)
    );
  }

  transparentize(percent: number) {
    return this.opacify(-percent);
  }

  opacify(percent: number) {
    return new RGBColor(
      this.r,
      this.g,
      this.b,
      Math.min(Math.max(this.a + (percent / 100), 0), 1),
    );
  }

  clone() {
    return new RGBColor(
      this.r,
      this.g,
      this.b,
      this.a,
    );
  }

  mix(other: RGBColor, factor: number = 0.5): RGBColor {
    const mix = (a: number, b: number, factor: number) => {
      if (a == null) {
        return b;
      }
      if (b == null) {
        return a;
      }

      return (a + (b - a) * factor);
    };

    return new RGBColor(
      Math.round(mix(this.r, other.r, factor)),
      Math.round(mix(this.g, other.g, factor)),
      Math.round(mix(this.b, other.b, factor)),
      mix(this.a, other.a, factor),
    );
  }

  toHSLColor(): HSLColor {
    // https://gist.github.com/mjackson/5311256
    let r = this.r / 255;
    let g = this.g / 255;
    let b = this.b / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return new HSLColor(
      Math.round(h * 360),
      Math.round(s * 100),
      Math.round(l * 100),
      this.a,
    );
  }

  toRGBString(): string {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  toRGBAString(): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  toHexString(): string {
    let r = this.r.toString(16);
    let g = this.g.toString(16);
    let b = this.b.toString(16);
    return `#${r.length > 1 ? r : '0' + r}${g.length > 1 ? g : '0' + g}${b.length > 1 ? b : '0' + b}`;
  }

  toHexaString(): string {
    let a = this.a.toString(16);
    return this.toHexString() + (a.length > 1 ? a : '0' + a);
  }

  toJSON(): {r: number, g: number, b: number, a: number} {
    return {
      r: this.r,
      g: this.g,
      b: this.b,
      a: this.a,
    };
  }

  static fromJSON(json: {r: number, g: number, b: number, a: number}, normalizeAlpha = false): RGBColor {
    return new RGBColor(
      json.r,
      json.g,
      json.b,
      (normalizeAlpha ? json.a / 255 : json.a),
    );
  }

  static fromHexString(hexString: string): RGBColor {
    if (hexString.charAt(0) === '#') {
      hexString = hexString.substring(1);
    }

    return new RGBColor(
      parseInt(hexString.substr(0, 2), 16),
      parseInt(hexString.substr(2, 2), 16),
      parseInt(hexString.substr(4, 2), 16),
      parseInt(hexString.substr(6, 2) || 'ff', 16),
    );
  }

  static fromRGBString(rgbString: string): RGBColor {
    let matches = rgbString.match(/rgba?\(\s*([0-9]+)\s*,\s*([0-9]+)\s*,\s*([0-9]+)(?:,\s*([0-9.]+))?\s*\)/i);
    return new RGBColor(
      parseInt(matches[1], 10),
      parseInt(matches[2], 10),
      parseInt(matches[3], 10),
      parseInt(matches[4], 10) || 1,
    );
  }

  static fromArray(array: [number, number, number] | [number, number, number, number]): RGBColor {
    return new RGBColor(
      array[0],
      array[1],
      array[2],
      array[2] || 1,
    );
  }

  static fromHSLColor(hslColor: HSLColor): RGBColor {
    // https://gist.github.com/mjackson/5311256
    let h = hslColor.h / 360;
    let s = hslColor.s / 100;
    let l = hslColor.l / 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      let hue2rgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return new RGBColor(
      Math.round(r * 255),
      Math.round(g * 255),
      Math.round(b * 255),
      hslColor.a,
    );
  }
}
