import { Component, OnDestroy, OnInit } from "@angular/core";
import { AtlasBaseMapComponent } from "../atlas-base-map.component";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { HttpClient } from "@angular/common/http";
import { BootstrapMediaService } from "src/app/services/bootstrap-media.service";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import GeoJSON from "ol/format/GeoJSON";
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';
import { AtlasFtthFilterService } from 'src/app/services/filter/atlas-ftth-filter.service';
import { forkJoin } from 'rxjs';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({
  selector: "app-atlas-ftth-map",
  templateUrl: "./atlas-ftth-map.component.html",
  styleUrls: ["./atlas-ftth-map.component.scss"],
})
export class AtlasFtthMapComponent extends AtlasBaseMapComponent implements OnInit, OnDestroy {

  constructor(
    protected http: HttpClient,
    protected breakpointObserver: BootstrapMediaService,
    protected translationHelper: TranslationHelperService,
    private atlasFtthFilterService: AtlasFtthFilterService,
    public olMapService: OLMapService
  ) {
    super(http, breakpointObserver, translationHelper, olMapService);
  }
  ngOnInit(): void {

    this.atlasFtthFilterService.setQueryParams();
    this.atlasFtthFilterService.atlasFtthService.setDefaultFilter();
    this.atlasFtthFilterService.atlasFtthService.getLegends("ftth-legend");
    this.atlasFtthFilterService.atlasFtthService.getFilter("ftth-filter");
    this.atlasFtthFilterService.atlasFtthService.getDetails("ftth-detail");
    this.mapOperations();
    this.checkDataCurrentQuarter();
  }

  mapOperations(): void {
    this.atlasFtthFilterService.atlasFtthService.eventsKey = this.olMapService.map.on("singleclick", (event: MapBrowserEvent<MouseEvent>) => {
      const joinEndpoints = this.atlasFtthFilterService.atlasFtthService.mapOperations(event);
      this.subscriptions.push(forkJoin(joinEndpoints).subscribe((featureCollections: GeoJSON[]) =>
        featureCollections.map((featureCollection: GeoJSON) => {
          const format = new GeoJSON();
          const features: Feature<Geometry>[] =
            format.readFeatures(featureCollection);
          if (features.length > 0) {
            this.updateDetails(features[0]);
            this.addVectorLayer(features[0], format);
          }
        })
      ))
    })
  }
  getLayerDetails(feature: Feature<Geometry>) {
    this.atlasFtthFilterService.atlasFtthService.getLayerDetails(feature);
  }
  getLayerName(): string[] {
    return this.atlasFtthFilterService.atlasFtthService.getLayerName();
  }
  getStyleName(): string {
    return this.atlasFtthFilterService.atlasFtthService.getStyleName();
  }
  loadLayer() {
    this.atlasFtthFilterService.atlasFtthService.loadLayer();
  }
  setFilter(layerPostfix: string) {
    this.atlasFtthFilterService.atlasFtthService.setFilter(layerPostfix);
  }
  updateDetails(feature: Feature<Geometry>) {
    this.atlasFtthFilterService.atlasFtthService.updateDetails(feature);
  }
  checkDataCurrentQuarter(): void {
    this.atlasFtthFilterService.atlasFtthService.checkDataCurrentQuarter();
  }
  getPreviousQuarterPostfix(currentQuarter: string): string {
    return this.atlasFtthFilterService.atlasFtthService.getPreviousQuarterPostfix(currentQuarter);
  }
  addVectorLayer(feature: Feature<Geometry>, format: GeoJSON) {
    this.atlasFtthFilterService.atlasFtthService.addVectorLayer(feature, format);
  }
  ngOnDestroy(): void {
    this.atlasFtthFilterService.atlasFtthService.mapOperationsUn();
    unsubscribe(this.subscriptions)
  }
}
