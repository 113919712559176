export function transformArrayToLabel(
  value: string[],
  upperCase: boolean = false,
  labelName: string = "",
  mark: string = "",
  extraKey: string = ""
) {
  const valueArr = new Array<{ label: string; value: string }>();

  if (upperCase) {
    for (let i = 0; i < value.length; i++) {
      valueArr.push({
        label: labelName + mark + value[i].toUpperCase() + extraKey,
        value: mark + value[i],
      });
    }
  } else {
    for (let i = 0; i < value.length; i++) {
      valueArr.push({
        label: labelName + mark + value[i],
        value: mark + value[i],
      });
    }
  }
  return valueArr;
}
