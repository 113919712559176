<div class="container breathe mt-3 mb-5">
  <div class="row">
    <div class="col">

      <div class="mb-5 title-container">
        <h1 class="mb-0">Indicators</h1>

        <div class="indicator-buttons-wrapper">
          <social-buttons class="d-block mr-3"></social-buttons>
          <app-indicator-buttons
            [group]="group"
            [short]="short"
            [breakdown]="breakdown"
          ></app-indicator-buttons>
        </div>
      </div>

      <div class="d-flex justify-content-center" *ngIf="loading; else __loaded">
        <app-loader></app-loader>
      </div>

      <ng-template #__loaded>
        <ng-container *ngIf="error; else __ok">
          <div class="alert alert-danger">{{'indicators.error.generic' | translate}}</div>
        </ng-container>
        <ng-template #__ok>
          <div class="animated fadeIn">

            <div>
              <div>
                <mat-form-field class="w-100">
                  <mat-label>{{'indicators.group' | translate}}</mat-label>
                  <mat-select [(ngModel)]="group" (ngModelChange)="updateShorts()" class="w-100" saveInputValue="group">
                    <mat-option *ngFor="let option of groups" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                  <mat-option *ngIf="groups.length <= 0" [disabled]="true">No data</mat-option>
                </mat-form-field>
              </div>

              <div>
                <mat-form-field *ngIf="group" class="w-100">
                  <mat-label>{{'indicators.short' | translate}}</mat-label>
                  <mat-select [(ngModel)]="short" (ngModelChange)="updateBreakdown()" class="w-100" saveInputValue="short">
                    <mat-option *ngFor="let option of shorts" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                  <mat-option *ngIf="shorts.length <= 0" [disabled]="true">No data</mat-option>
                </mat-form-field>
              </div>

              <div>
                <mat-form-field *ngIf="short" class="w-100">
                  <mat-label>{{'indicators.breakdown' | translate}}</mat-label>
                  <mat-select [(ngModel)]="breakdown" (ngModelChange)="updateUnit()" class="w-100" saveInputValue="breakdown">
                    <mat-option *ngFor="let option of breakdowns" [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-select>
                  <mat-option *ngIf="breakdowns.length <= 0" [disabled]="true">No data</mat-option>
                </mat-form-field>
              </div>

              <input type="hidden" [(ngModel)]="unit" saveInputValue="unit"/>
            </div>

            <ng-container *ngIf="group && breakdown && short; else __noSelection">
              <app-indicator-values-component
                [indicator]="indicator"
              ></app-indicator-values-component>
            </ng-container>

            <div class="mt-5" *ngIf="indicator">
              <app-indicator-details-component [indicator]="indicator"></app-indicator-details-component>
            </div>

            <ng-template #__noSelection>
              <div class="d-flex align-items-center justify-content-center pt-5 pb-5 border">
                <fa-icon [icon]="icons.noSelection" size="2x"></fa-icon>
                <div class="ml-3">Make a selection</div>
              </div>
            </ng-template>

          </div>
        </ng-template>
      </ng-template>

    </div>
  </div>
</div>
