<div>
  <ng-container *ngIf="extract.configList && extract.configList.length > 0">
    <div class="filter-section-label">{{'ui.charts.config' | translate}}</div>
    <mat-form-field class="w-100" *ngFor="let conf of extract.configList">
      <mat-select [placeholder]="conf.label | translate"
                  [ngModel]="extract.config[conf.key]"
                  (ngModelChange)="setConfig(conf.key, $event)">
        <mat-option *ngFor="let choice of conf.choices" [value]="choice.value">{{choice.label | label | async}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="extract.filters && extract.filters.length > 0">
    <div class="filter-section-label">{{ 'ui.charts.filters' | translate }}</div>

    <mat-form-field class="w-100" *ngFor="let fil of extract.filters">
      <mat-select [placeholder]="fil.label | translate"
                  [ngModel]="extract.filter.get(fil.key)"
                  (ngModelChange)="setFilter(fil.key, $event)"
                  [multiple]="fil.multiple"
                  [compareWith]="fil.compareWith ? fil.compareWith : defaultCompare">
        <mat-option *ngFor="let choice of fil.choices" [value]="choice.value">{{choice.label | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <mat-form-field *ngIf="canChangeType" class="w-100">
    <mat-select [placeholder]="'filters.chartType.title' | translate" [ngModel]="currentType" (ngModelChange)="onChangeType($event)">
      <mat-option *ngFor="let type of chartTypes" [value]="type">
        <fa-icon [icon]="getTypeIcon(type)"></fa-icon>
        {{ 'filters.chartType.values.' + type | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
