<div class="w-100 d-flex justify-content-center align-items-center">
  <div class="login p-5">
    <div class="d-flex justify-content-center align-items-center pt-3 pb-3">
      <fa-icon [icon]="icons.fasLock" size="2x"></fa-icon>
    </div>
    <h2 class="text-center card-title font-weight-bold">{{ 'login.title' | translate }}</h2>

    <p>{{ 'login.description' | translate }}</p>

    <p class="text-danger" *ngIf="failed">{{ 'login.error' | translate }}</p>

    <form>
      <div class="form-group">
        <label>{{ 'login.username' | translate }}</label>
        <input class="form-control" type="text" [(ngModel)]="username" [ngModelOptions]="{standalone: true}">
      </div>

      <div class="form-group">
        <label>{{ 'login.password' | translate }}</label>
        <div class="position-relative">
          <input class="form-control" [type]="showPassword ? 'text' : 'password'" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
          <button class="btn btn-link show-password-toggle" (click)="toggleShowPassword()">
            <fa-icon [icon]="showPassword ? icons.eyeSlash : icons.eye"></fa-icon>
          </button>
        </div>
      </div>

      <div>
        <button type="submit" class="btn btn-primary" (click)="authenticate()" [disabled]="authenticating">{{ 'login.submit' | translate }}</button>
        <button class="btn btn-default" routerLink="/">{{'ui.navigation.back' | translate}}</button>
      </div>
    </form>
  </div>
</div>
