import { Component } from '@angular/core';
import { Filter } from '../../datasets/filter';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { AbstractPresentationPage } from '../abstract-presentation-page/abstract-presentation-page';
import { DOMAIN } from '../../models/domain/domain';
import { PROJECT_DESCRIPTIONS, ProjectService } from '../../services/project.service';
import { MetadataService } from '../../services/metadata.service';
import { environment } from '../../../environments/environment';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { ExecutionStateEnum } from 'src/app/models/domain/execution-state-enum';
import { AtlasFtthDataset } from 'src/app/datasets/atlas-ftth.dataset';
import { DataSourceDescriptionData } from 'src/app/models/data-source-description-data.model';
import { OLMapService } from 'src/app/services/ol-map/ol-map.service';
import { Area } from 'src/app/services/area/area';
import { AtlasFtthService } from 'src/app/services/ol-map/atlas-ftth.service';

@Component({
  selector: 'app-atlas-page',
  templateUrl: './atlas-ftth-page.component.html',
  styleUrls: ['./atlas-ftth-page.component.scss']
})
export class AtlasFtthPageComponent extends AbstractPresentationPage {

  dataDescription: DataSourceDescriptionData = PROJECT_DESCRIPTIONS.atlasFtth;

  constructor(
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
    protected translationHelper: TranslationHelperService,
    public olMapService: OLMapService,
    public atlasFtthSetvice: AtlasFtthService,
    private projectService:ProjectService
  ) {
    super(statistics);
    const group = projectService.groups.filter(c=>c.group == "Atlas")[0]
    projectService.selectGroup(group)
    this.setParameters();
    this.metadata.setImage(environment.baseHref + 'assets/images/og/atlas-ftth3.png');
    this.metadata.setSubtitle('ui.projects.atlasftth.title');
    this.metadata.setDescription('ui.projects.atlasftth.description');
  }

  private setParameters() {
    this.olMapService.datasets = [
      {
        label: 'labels.executedRollout',
        dataset: new AtlasFtthDataset(),
        filter: new Filter({
          executionState: ExecutionStateEnum.EXECUTED_AND_PLANNED,
        }),
        filters: [
          {
            label: 'controls.executionState',
            key: 'executionState',
            choices: DOMAIN.executionStates,
          },
        ],
      },
    ];
    this.olMapService.dataset = this.olMapService.datasets[0].dataset;
    this.olMapService.filter = this.olMapService.datasets[0].filter;
    this.olMapService.filters = this.olMapService.datasets[0].filters;
  }
  searchArea(area: Area) {
    this.olMapService.searchArea(area);
  }
}
