<div class="legend-container" [style.width]="108 + 2 + 'px'" id="ftth-legend">
  <div class="legend-ftth">
    <div
      *ngFor="let value of legend.values"
      class="legend-color"
      [style.left]="value.position * 80 + '%'"
      [style.background-color]="value.color"
    ></div>
  </div>
  <div class="legend-values">
    <div
      *ngFor="let value of legend.values"
      class="legend-value"
      [style.left]="37 + value.position * 80 + '%'"
    >
      <div>{{ value.label | translate }}</div>
    </div>
  </div>
</div>
