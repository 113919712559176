import { Directive, Inject, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({ selector: '[isBrowser]' })
export class IsBrowserDirective {

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
