<div class="container">
  <nav
    class="navbar navbar-expand-lg navbar-light align-items-stretch d-flex justify-content-between"
  >
    <div class="d-flex align-items-center">
      <div class="container">
        <a class="d-flex align-items-center pr-3 brand" routerLink="/">
          <img src="/assets/images/bipt-logo-mini.png" class="ibpt-logo mr-2" />
          <span class="brand-name"
            >{{ "bipt" | translate }}<span class="ml-2 mr-2">|</span
            >{{ "data_portal" | translate }}</span
          >
        </a>
      </div>
    </div>
    <div class="d-xl-none d-flex">
      <div class="d-flex">
        <div class="d-xl-none d-flex align-items-center pr-3">
          <a (click)="toggleMenu()" class="main-menu-toggle">
            <fa-icon [icon]="sidenavOpen ? icons.times : icons.bars"></fa-icon>
          </a>
        </div>
        <div [class.d-none]="sidenavOpen === true" class="links">
          <div class="d-flex align-items-center pb-1">|</div>
          <app-language-selector
            [class.d-none]="sidenavOpen === true"
          ></app-language-selector>
        </div>
      </div>
    </div>
  </nav>
  <nav class="mobile-menu pt-4 d-xl-none" [class.show]="sidenavOpen">
    <a
      [class.active]="checkMember(member)"
      *ngFor="let member of members; index as j"
      class="nav-link"
      type="button"
      routerLink="{{ lang }}/{{ member.routerLink }}"
      [attr.disabled]="member.routerLink ? null : true"
      [class.disabled]="!member.routerLink"
      (click)="toggleMenu()"
    >
      {{ member.title | translate }}
    </a>
  </nav>
</div>
