import { Stringifiable } from './types';

export enum ObjectToQueryConversionStrategy {
  JSON,
}

export function objectToQueryString(
  object: { [key: string]: any },
  subObjectsConversionStrategy: ObjectToQueryConversionStrategy = ObjectToQueryConversionStrategy.JSON
) {
  let subStrings: Array<string> = [];

  for (let key in object) {
    let value = object[key];
    if (typeof value === 'object') {
      if (value instanceof Date) {
        subStrings.push(encodeURIComponent(value.getTime().toString()));
      } else {
        if (subObjectsConversionStrategy === ObjectToQueryConversionStrategy.JSON) {
          subStrings.push(encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value)));
        }
      }
    } else {
      subStrings.push(encodeURIComponent(key) + '=' + encodeURIComponent(value.toString()));
    }
  }

  return subStrings.length > 0 ? '?' + subStrings.join('&') : '';
}

export function replaceVariablesInUrl(
  url: string,
  variables: { [name: string]: Stringifiable },
  variableStart: string = '{', // must be regex friendly
  variableEnd: string = '}', // must be regex friendly
) {
  let regexp = new RegExp(`${variableStart}(\\w+)${variableEnd}`, 'ig');

  let resolver = (all, variableName): string => (variableName in variables ? variables[variableName].toString() : all);

  return url.replace(regexp, resolver);
}
