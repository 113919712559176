import { Injectable } from '@angular/core';
import { AtlasMobileService } from '../ol-map/atlas-mobile.service';

@Injectable({
  providedIn: 'root'
})
export class AtlasMobileFilterService {

  constructor(
    public atlasMobileService: AtlasMobileService
  ) { }

  setFilter(filter: string, value: any) {
    this.atlasMobileService.olMapService.filter.set(filter, value);
    this.atlasMobileService.loadLayer();
  }
  setQueryParams() {
    this.atlasMobileService.eventsKey = this.atlasMobileService.olMapService.map.on('moveend', () => {
      this.atlasMobileService.changeUrl();
    })
  }
}
