import { Injectable } from "@angular/core";
import { OLMapService } from "./ol-map.service";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { HttpClient } from "@angular/common/http";
import { OlAtlasBaseService } from "./ol-atlas-base.service";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { BehaviorSubject, Observable, Subject, finalize } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { DatasetEntry } from "src/app/models/dataset-entry";
import { GeoserverAPIService } from "../geoserver/geoserver-api.service";
import { removeDuplicatedValues } from "src/app/utils/removeDuplicatedValues";
import { transformArrayToLabel } from "src/app/utils/transformArrayToLabel";
import { Filter } from "src/app/datasets/filter";
import { DOMAIN } from "src/app/models/domain/domain";
import { AtlasMobileAreaDataset } from "src/app/datasets/atlas-mobile-area.dataset";
import { AtlasMobileGridDataset } from "src/app/datasets/atlas-mobile-grid.dataset";
import {
  checkCorrectParams,
  checkQueryParameters,
} from "src/app/utils/helpers";
import {
  BBOX,
  MOBILE_DATASETS,
  MOBILE_DATASET_LABELS,
  MOBILE_FILTERS,
  MOBILE_FILTER_LABELS,
  SELECTEDTAB,
} from "src/assets/constants/constants";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AtlasMobileService extends OlAtlasBaseService {
  isByzoneLoaded: boolean = false;
  isDetailedLoaded: boolean = false;

  constructor(
    private http: HttpClient,
    public olMapService: OLMapService,
    private router: Router,
    private route: ActivatedRoute,
    private geoserverService: GeoserverAPIService
  ) {
    super(olMapService);
  }

  mapOperations(event: MapBrowserEvent<MouseEvent>) {
    this.olMapService.details = [];
    this.removeVectorLayers();
    return this.createJoinEndpoints(event);
  }
  createJoinEndpoints(
    event: MapBrowserEvent<MouseEvent>
  ): Observable<Object>[] {
    const style = this.getStyleName();
    if (style.includes(MOBILE_DATASETS[0])) {
      return;
    }
    const tileLayers = event.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l instanceof TileLayer &&
          l.getSource() instanceof TileWMS &&
          l.getVisible()
      ) as TileLayer<TileWMS>[];

    if (!tileLayers) {
      return;
    }
    const tileSources = tileLayers.map((tl) => tl.getSource()) as TileWMS[];

    if (!tileSources) {
      return;
    }

    const viewResolution = this.olMapService.map.getView().getResolution();

    const urls = tileSources.map((ts) => {
      return ts.getFeatureInfoUrl(
        event.coordinate,
        viewResolution,
        "EPSG:3857",
        {
          INFO_FORMAT: "application/json",
          STYLES: style,
          FEATURE_COUNT: 50,
        }
      );
    });
    return urls.filter((url) => url != "").map((url) => this.http.get(url));
  }
  getLayerDetails(feature: Feature<Geometry>): void {
    this.olMapService.detailsLoading = true;
    this.olMapService.details = []
    const style = this.getStyleName();
    feature
      .getKeys()
      .sort()
      .forEach((key) => {
        const column = style.toLowerCase().replace("satisfactory", "1").replace("verygood", "3").replace("good", "2");
        const name = "name";
        if (
          !name.localeCompare(key) &&
          feature.get(key) !== "" &&
          feature.get(key) !== null
        ) {
          this.olMapService.details.push({
            label: name,
            value: feature.get(key),
          });
        }
        if (
          !column.localeCompare(key) &&
          feature.get(key) !== "" &&
          feature.get(key) !== null
        ) {
          this.olMapService.details.push({
            label: "cover",
            ratio: feature.get(key),
          });
        }
      });
    this.olMapService.detailsLoading = false;
  }
  getLayerName(): string {
    const provider = this.olMapService.filter.get(MOBILE_FILTERS[0]);
    const technology = this.olMapService.filter.get(MOBILE_FILTERS[1]);
    const level = this.olMapService.filter.get(MOBILE_FILTERS[3]);
    if (level) {
      return provider + "_" + level + "_" + technology;
    }
    return provider + "_" + technology;
  }
  getStyleName(): string {
    const quality = this.olMapService.filter.get(MOBILE_FILTERS[2]) as string;
    const coverType = this.olMapService.filter.get(MOBILE_FILTERS[4]) as string;

    if (coverType && quality) {
      const style = coverType.toLowerCase() + "_" + quality;
      return style;
    }
    return "mobile_detailed";
  }
  loadLayer() {
    this.removeVectorLayers();
    const layerName = this.getLayerName();
    const styleName = this.getStyleName();
    let existingLayer = undefined;
    const selectedLayerName = `${layerName}_${styleName}`;
    existingLayer = this.olMapService.layers.find(
      (layer) => layer.name === selectedLayerName
    );
    if (existingLayer === undefined) {
      const workspace =
        styleName == "mobile_detailed"
          ? environment.geoServer.dp_mobile_detailed_overview
          : environment.geoServer.dp_mobile_byzone_overview;
      const tileLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServer.baseUrl}/${workspace}/wms`,
          params: {
            LAYERS: layerName,
            FORMAT: "image/png",
            STYLES: styleName,
            TILED: true,
          },
          serverType: "geoserver",
        }),
        opacity: 0.6,
      });
      this.olMapService.map.addLayer(tileLayer);
      this.olMapService.layers.push({
        name: selectedLayerName,
        layer: tileLayer,
        workspace: "polygon",
      });
    } else {
      existingLayer.layer.setVisible(true);
    }
    this.olMapService.layers.forEach((visibleLayer) => {
      if (!selectedLayerName.includes(visibleLayer.name)) {
        visibleLayer.layer.setVisible(false);
      }
    });
    this.changeUrl();
  }
  setFilter(): void {}

  updateDetails(feature: Feature<Geometry>) {
    this.olMapService.detailsLoading = true;
    this.getLayerDetails(feature);
    this.olMapService.detailsLoading = false;
  }

  changeDataset(entry: DatasetEntry) {
    if (!entry) {
      return;
    }
    this.olMapService.datasetEntry = entry;
    this.olMapService.dataset = entry.dataset;
    this.olMapService.filter = entry.filter;
    this.olMapService.filters = entry.filters;

    this.loadLayer();
  }
  setDefault(index: number) {
    const defaultDataset = this.olMapService.datasets[index];
    this.changeDataset(defaultDataset);
  }
  setDefaultFilter() {
    if (!Object.keys(this.olMapService.mapConfig)) {
      this.setDefault(0);
      return;
    }
    const selectedTab = this.olMapService.mapConfig[SELECTEDTAB];
    if (!selectedTab || selectedTab == "") {
      this.setDefault(0);
      return;
    }
    switch (selectedTab) {
      case MOBILE_DATASETS[0]:
        const isDetailedParamsCorrect = this.checkDetailedParamsCorrect([
          MOBILE_FILTERS[0],
          MOBILE_FILTERS[1],
        ]);
        if (!isDetailedParamsCorrect) {
          this.setDefault(0);
          break;
        }
        this.setDetailedFilter(selectedTab);
        break;
      case MOBILE_DATASETS[1]:
        const isByzoneParamsCorrect =
          this.checkByzoneParamsCorrect(MOBILE_FILTERS);
        if (!isByzoneParamsCorrect) {
          this.setDefault(0);
          break;
        }
        this.setByzoneFilter(selectedTab);
        break;
      default:
        this.setDefault(0);
    }
  }
  checkByzoneParamsCorrect(filters: string[]): boolean {
    const haveAllQueryParams = checkQueryParameters(
      this.olMapService.mapConfig,
      filters
    );
    if (!haveAllQueryParams) {
      return false;
    }

    const isCorrectParams = checkCorrectParams(
      this.olMapService.datasets[1].filters,
      this.olMapService.mapConfig,
      filters
    );
    if (isCorrectParams.some((cp) => cp == false)) {
      return false;
    }
    return true;
  }
  setByzoneFilter(selectedTab: string) {
    this.olMapService.filter.set(
      MOBILE_FILTERS[0],
      this.olMapService.mapConfig[MOBILE_FILTERS[0]]
    );
    this.olMapService.filter.set(
      MOBILE_FILTERS[1],
      this.olMapService.mapConfig[MOBILE_FILTERS[1]]
    );
    this.olMapService.filter.set(
      MOBILE_FILTERS[2],
      this.olMapService.mapConfig[MOBILE_FILTERS[2]]
    );
    this.olMapService.filter.set(
      MOBILE_FILTERS[3],
      this.olMapService.mapConfig[MOBILE_FILTERS[3]]
    );
    this.olMapService.filter.set(
      MOBILE_FILTERS[4],
      this.olMapService.mapConfig[MOBILE_FILTERS[4]]
    );

    const bBoxArr = this.olMapService.mapConfig[BBOX]
      ? this.olMapService.mapConfig[BBOX].split(",").map((bbox) => Number(bbox))
      : "";
    if (bBoxArr != "") {
      this.olMapService.map.getView().fit(bBoxArr);
    }
    const byzoneDataset = this.olMapService.datasets.find((ds) =>
      ds.label.includes(selectedTab)
    );
    byzoneDataset.filter = this.olMapService.filter;
    this.changeDataset(byzoneDataset);
  }
  checkDetailedParamsCorrect(filters: string[]): boolean {
    const haveAllQueryParams = checkQueryParameters(
      this.olMapService.mapConfig,
      filters
    );
    if (!haveAllQueryParams) {
      return false;
    }
    const isCorrectParams = checkCorrectParams(
      this.olMapService.datasets[0].filters,
      this.olMapService.mapConfig,
      filters
    );
    if (isCorrectParams.some((cp) => cp == false)) {
      return false;
    }
    return true;
  }
  setDetailedFilter(selectedTab: string) {
    this.olMapService.filter.set(
      MOBILE_FILTERS[0],
      this.olMapService.mapConfig[MOBILE_FILTERS[0]]
    );
    this.olMapService.filter.set(
      MOBILE_FILTERS[1],
      this.olMapService.mapConfig[MOBILE_FILTERS[1]]
    );
    const bBoxArr = this.olMapService.mapConfig[BBOX]
      ? this.olMapService.mapConfig[BBOX].split(",").map((bbox) => Number(bbox))
      : "";
    if (bBoxArr != "") {
      this.olMapService.map.getView().fit(bBoxArr);
    }
    const detailedDataset = this.olMapService.datasets.find((ds) =>
      ds.label.includes(selectedTab)
    );
    detailedDataset.filter = this.olMapService.filter;
    this.changeDataset(detailedDataset);
  }
  changeUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [SELECTEDTAB]: this.olMapService.datasetEntry?.label.split(".")[1],
        [MOBILE_FILTERS[0]]: this.olMapService.filter.get(MOBILE_FILTERS[0]),
        [MOBILE_FILTERS[1]]: this.olMapService.filter.get(MOBILE_FILTERS[1]),
        [MOBILE_FILTERS[2]]: this.olMapService.filter.get(MOBILE_FILTERS[2]),
        [MOBILE_FILTERS[3]]: this.olMapService.filter.get(MOBILE_FILTERS[3]),
        [MOBILE_FILTERS[4]]: this.olMapService.filter.get(MOBILE_FILTERS[4]),
        [BBOX]: [
          this.olMapService.map
            .getView()
            .calculateExtent(this.olMapService.map.getSize()),
        ].toString(),
      },
      replaceUrl: true,
    });
  }
  detailedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  byzoneSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  setDetailed() {
    return this.geoserverService
      .getMobileDetailedLayerNames()
      .pipe(
        finalize(() => {
          this.isDetailedLoaded = true;
          this.detailedSubject.next(true);
        })
      )
      .subscribe((val) => {
        const featureDescriptor = this.olMapService.readWfsCapabilities(val);

        // TODO: use featureDescriptor to get unique values
        const uniqueProviders = ["Telenet", "Proximus", "Orange"];
        const uniqueTechnologies = ["4G","5G"];

        const providerArr = transformArrayToLabel(
          uniqueProviders,
          true,
          "labels."
        );
        const technologyArr = transformArrayToLabel(uniqueTechnologies, true);

        const detailed = this.olMapService.datasets.find(
          (ds) => ds.label == MOBILE_DATASET_LABELS[0]
        );
        const detailedProviders = detailed.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[0]
        );
        const detailedTechnologies = detailed.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[1]
        );

        detailedProviders.choices = providerArr;
        detailedTechnologies.choices = technologyArr;
        const technologyIndex = technologyArr.length - 1;

        detailed.filter = new Filter({
          provider: providerArr[0].value,
          technology: technologyArr[technologyIndex].value,
        });
        this.olMapService.filters = detailed.filters;
      });
  }

  setByzone() {
    return this.geoserverService
      .getMobileByZoneLayerNames()
      .pipe(
        finalize(() => {
          this.isByzoneLoaded = true;
          this.byzoneSubject.next(true);
        })
      )
      .subscribe((val) => {
        const featureDescriptor = this.olMapService.readWfsCapabilities(val);
        const uniqueProviders = ["Telenet", "Orange", "Proximus"];
        const uniqueLevels = [
          "STATISTICAL_SECTOR",
          "MUNICIPALITY",
          "DISTRICT",
          "PROVINCE",
          "REGION",
          "Country"
        ];
        const uniqueTechnologies = ["4G", "5G"];
        const uniqueCovers = ["households", "population", "territory"];
        const uniqueQualities = ["Satisfactory", "Good", "VeryGood"];

        const providerArr = transformArrayToLabel(uniqueProviders);
        const levelArr = transformArrayToLabel(
          uniqueLevels,
          true,
          "area.level."
        );
        const technologyArr = transformArrayToLabel(uniqueTechnologies, true);
        const coverArr = transformArrayToLabel(uniqueCovers, true, "labels.");
        const qualityArr = transformArrayToLabel(
          uniqueQualities,
          false,
          "labels."
        );

        const byzone = this.olMapService.datasets.find(
          (ds) => ds.label == MOBILE_DATASET_LABELS[1]
        );
        const byzoneProviders = byzone.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[0]
        );
        const byzoneTechnologies = byzone.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[1]
        );
        const byzoneQualities = byzone.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[2]
        );
        const byzoneLevels = byzone.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[3]
        );
        const byzoneCovers = byzone.filters.find(
          (fl) => fl.key == MOBILE_FILTERS[4]
        );

        byzoneProviders.choices = providerArr;
        byzoneLevels.choices = levelArr;
        byzoneTechnologies.choices = technologyArr;
        byzoneCovers.choices = coverArr;
        DOMAIN.mobileQualities.forEach((qa) => {
          qualityArr.map((te) => {
            if (qa.value.toString() == te.value) {
              te.label = qa.label;
            }
          });
        });
        byzoneQualities.choices = qualityArr;
        const index = technologyArr.length - 1;

        byzone.filter = new Filter({
          provider: providerArr[0].value,
          technology: technologyArr[index].value,
          quality: qualityArr[1].value,
          level: levelArr[0].value,
          coverType: coverArr[1].value,
        });
      });
  }
  setDefaultParams() {
    this.olMapService.datasets = [
      {
        label: MOBILE_DATASET_LABELS[0],
        dataset: new AtlasMobileGridDataset(),
        filter: new Filter(),
        filters: [
          {
            label: MOBILE_FILTER_LABELS[0],
            key: MOBILE_FILTERS[0],
            choices: [],
          },
          {
            label: MOBILE_FILTER_LABELS[1],
            key: MOBILE_FILTERS[1],
            choices: [],
          },
        ],
      },
      {
        label: MOBILE_DATASET_LABELS[1],
        dataset: new AtlasMobileAreaDataset(),
        filter: new Filter(),
        filters: [
          {
            label: MOBILE_FILTER_LABELS[0],
            key: MOBILE_FILTERS[0],
            choices: [],
          },
          {
            label: MOBILE_FILTER_LABELS[1],
            key: MOBILE_FILTERS[1],
            choices: [],
          },
          {
            label: MOBILE_FILTER_LABELS[2],
            key: MOBILE_FILTERS[2],
            choices: [],
          },
          {
            label: MOBILE_FILTER_LABELS[3],
            key: MOBILE_FILTERS[3],
            choices: [],
          },
          {
            label: MOBILE_FILTER_LABELS[4],
            key: MOBILE_FILTERS[4],
            choices: [],
          },
        ],
      },
    ];

    this.olMapService.dataset = this.olMapService.datasets[0].dataset;
    this.olMapService.filter = this.olMapService.datasets[0].filter;
    this.olMapService.filters = this.olMapService.datasets[0].filters;
  }
}
