import { Component, OnInit } from '@angular/core';
import { OLLegendDefinition } from 'src/app/models/layer';

@Component({
  selector: 'app-atlas-trein-legend',
  templateUrl: `atlas-trein-legend.component.html`,
  styleUrls: ['./atlas-trein-legend.component.scss'],
})
export class AtlasTreinLegendComponent implements OnInit {

  legend: OLLegendDefinition;

  ngOnInit() {
    this.legend = {
      values: [
        { position: 0, label: "0" },
        { position: 1, label: "50" },
        { position: 2, label: "100" },
      ],
    };
  }
}