// // @ts-ignore
// import Chart = require('chart.js');
//
// Chart.defaults._set('comparisonBar', Chart.defaults.bar);
//
// Chart.controllers.comparisonBar = Chart.controllers.bar.extend({
//   name: 'ComparisonBarChart',
//   initialize: function() {
//     Chart.controllers.bar.prototype.initialize.apply(this, arguments);
//   },
//
//   calculateBarValuePixels: function(datasetIndex, index) {
//     let scale = this.getValueScale();
//     let datasets = this.chart.data.datasets;
//     let value = scale.getRightValue(datasets[datasetIndex].data[index]);
//     let start = scale.min;
//
//     let base = scale.getPixelForValue(start);
//     let head = scale.getPixelForValue(start + (value - start));
//     let size = (head - base);
//
//     return {
//       size: size,
//       base: base,
//       head: head,
//       center: head + size / 2
//     };
//   },
// });
