import { DeclareExtract } from '../../declare-extract.decorator';
import { Extract, ExtractType } from '../../extract';

@DeclareExtract('dbm_comparison')
export class DbmComparison extends Extract {

  name = 'dbm_comparison';
  type = ExtractType.COMPARISON;
  chartType = 'bar';

}
