import { Component, NgZone } from '@angular/core';
import { AbstractChartJsComponent } from '../abstract-chartjs/abstract-chartjs.component';
import { ChartDataSets, ChartOptions, ChartPoint, TimeUnit } from 'chart.js';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { labelToColor } from '../../utils/labelToColor';
import * as moment from 'moment';
import { StatisticsService } from '../../services/statistics/statistics.service';
import { TranslationHelperService } from '../../services/translations/translation-helper.service';
import { parallelSort } from '../../utils/arrays';
import { BootstrapMediaService } from '../../services/bootstrap-media.service';

export interface TimeseriesExtractData {
  [splitKey: string]: {
    [time: string]: number;
  };
}

@Component({
  selector: 'app-chart-timeseries',
  templateUrl: '../abstract-chartjs/abstract-chartjs.component.html',
  styleUrls: ['./chart-timeseries.component.scss']
})
export class ChartTimeseriesComponent extends AbstractChartJsComponent<TimeseriesExtractData> {

  types: Array<string> = ['bar'];

  updateSubscription: Subscription;

  constructor(
    protected statistics: StatisticsService,
    protected translationHelper: TranslationHelperService,
    protected breakpointObserver: BootstrapMediaService,
    protected zone: NgZone,
  ) {
    super(statistics, translationHelper, breakpointObserver, zone);
  }

  updateChart() {
    if (this.chart && this.extract && this.extract.data) {
      let unsortedLabels = Object.keys(this.extract.data);

      if (this.updateSubscription) {
        this.updateSubscription.unsubscribe();
        this.updateSubscription = undefined;
      }

      this.updateSubscription = this.translateLabels(unsortedLabels).subscribe(
        (unsortedTranslations: Array<string>) => {
          let { main: translations, other: labels } = parallelSort(unsortedTranslations, unsortedLabels);
          let chartData = this.chart.data;
          this.emptyChart();

          for (let i in labels) {
            if (labels.hasOwnProperty(i)) {
              let label = labels[i];
              let dataset = this.createChartJsDataset(i, translations[i]);

              let timeseries = this.groupTimeseriesByTimeUnit(this.extract.data[label]);
              let times = Object.keys(timeseries).map(t => parseInt(t, 10)).sort();
              for (let time of times) {
                (<Array<ChartPoint>>dataset.data).push({
                  t: new Date(time),
                  y: timeseries[time],
                });
              }
              chartData.datasets.push(dataset);
            }
          }

          this.updateChartSize(false);
          this.chart.update();
        }
      );
    }
  }

  createChartJsDataset(i: string, label: string): ChartDataSets {
    let color = labelToColor(label);

    if (this.type === 'line') {
      return {
        label: label,
        data: [],
        backgroundColor: 'transparent',
        pointBackgroundColor: color.toRGBAString(),
        pointBorderColor: color.darken(10).toRGBAString(),
        borderColor: color.transparentize(25).toRGBAString(),
        lineTension: 0.25,
        // lineTension: 0,
        borderWidth: 3,
      };
    } else {
      return {
        label: label,
        data: [],
        backgroundColor: color.toRGBAString(),
        borderColor: color.transparentize(25).toRGBAString(),
      };
    }
  }

  getOptions(): Observable<ChartOptions> {
    this.getPeriod();
    return super.getOptions().pipe(
      map(options => {
        options.scales = {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: <any>this.getTimeScaleUnit(),
              },
            }
          ],
        };
        options.animation = { duration: 0 };
        return options;
      })
    );
  }

  groupTimeseriesByTimeUnit(
    timeseries: { [time: string]: number },
  ): { [time: string]: number } {
    let timeunit = this.getTimeScaleUnit();
    if (timeunit === false || this.extract.groupingFunction === null) {
      return timeseries;
    } else {
      let stacked: { [time: string]: Array<number> } = {};
      let times = Object.keys(timeseries).map(t => parseInt(t, 10));
      for (let time of times) {
        let key = this.getStartOfGroup(time, timeunit);
        if (stacked[key] === undefined) {
          stacked[key] = [];
        }
        stacked[key].push(timeseries[time]);
      }
      let grouped: { [time: string]: number } = {};
      for (let time in stacked) {
        grouped[time] = this.extract.groupingFunction(stacked[time]);
      }
      return grouped;
    }
  }

  getStartOfGroup(time: number, timeunit: TimeUnit): number {
    return moment(time).startOf(timeunit).valueOf();
  }

  getMillisecondsInTimeUnit(timeunit: TimeUnit) {
    return moment.duration(1, timeunit).asMilliseconds();
  }

  getTimeScaleUnit(): TimeUnit | false {
    let period = this.getPeriod() / 1000;
    if (period === 0 || isNaN(period)) {
      return false;
    }

    if (period <= 86400) {
      return 'hour';
    }

    if (period <= 86400 * 7) {
      return 'day';
    }

    if (period <= 86400 * 31 * 3) {
      return 'week';
    }

    return 'month';
  }

  getPeriod() {
    let min: number;
    let max: number;
    for (let key in this.extract.data) {
      let series = this.extract.data[key];
      let times = Object.keys(series).map(t => parseInt(t, 10)).sort();
      if (times.length > 0) {
        if (min === undefined || times[0] < min) {
          min = times[0];
        }
        if (max === undefined || times[times.length - 1] > max) {
          max = times[times.length - 1];
        }
      }
    }
    return (max - min) || 0;
  }
}
