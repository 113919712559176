export enum SpeedEnum {
  MBPS0 = 0,
  MBPS1 = 1,
  MBPS10 = 10,
  MBPS30 = 30,
  MBPS50 = 50,
  MBPS100 = 100,
  MBPS350 = 350,
  MBPS1000 = 1000,
  MBPS2500 = 2500,
  MBPS5000 = 5000,
  MBPS8500 = 8500,
  MBPS10000 = 10000,
}
