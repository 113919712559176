<mat-sidenav-container class="example-container w-100">
  <mat-sidenav mode="over" #sidenav position="end" [opened]="false" class="sidenav-filter">
    <div class="w-100 h-100 p-3">
      <div class="close" (click)="sidenav.close()">
        <fa-icon [icon]="icons.falTimes"></fa-icon>
      </div>
      <app-chart-filters [extract]="extract" (update)="update()" *ngIf="!lockSidenavOpen" [canChangeType]="canChangeType" [chartTypes]="types" [currentType]="extract.chartType" (currentTypeChange)="changeType($event)"></app-chart-filters>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col d-flex flex-column horizontal-overflow-hidden">

          <!--<div class="loader-overlay" [class.shown]="isLoading">-->
          <div class="loader-overlay" [class.shown]="isLoading">
            <fa-icon [icon]="icons.falSpinnerThird" size="3x" [spin]="true"></fa-icon>
          </div>

          <div class="row header p-3">
            <h2 class="title" [id]="extract.name" *ngIf="!hideTitle">{{ titleTranslationPrefix + '.' + extract.title | translate }}</h2>

            <div class="d-flex actions">
              <div class="d-lg-none icon-toggle" (click)="sidenav.toggle()">
                <fa-icon [icon]="icons.falFilter"></fa-icon>
                <!--{{ 'ui.charts.filters' | translate }}-->
              </div>
            </div>
          </div>

          <div class="row flex-grow-1">
            <div class="col-lg-3 col-md-12 filters pt-3 pb-3" *ngIf="lockSidenavOpen">
              <app-chart-filters [extract]="extract" (update)="update()" [canChangeType]="canChangeType" [chartTypes]="types" [currentType]="extract.chartType" (currentTypeChange)="changeType($event)"></app-chart-filters>
            </div>

            <div class="col-lg-9 col-md-12 chart">
              <div class="row position-relative p-3 flex-column">

                <canvas #chart id="chart"></canvas>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
