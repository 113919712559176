import { Component, OnDestroy } from "@angular/core";
import { Filter } from "../../datasets/filter";
import { StatisticsService } from "../../services/statistics/statistics.service";
import { AbstractPresentationPage } from "../abstract-presentation-page/abstract-presentation-page";
import {
  PROJECT_DESCRIPTIONS,
  ProjectService,
} from "../../services/project.service";
import { MetadataService } from "../../services/metadata.service";
import { environment } from "../../../environments/environment";
import { AtlasFixDataset } from "../../datasets/atlas-fix.dataset";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import { GeoserverAPIService } from "src/app/services/geoserver/geoserver-api.service";
import { removeDuplicatedValues } from "src/app/utils/removeDuplicatedValues";
import { transformArrayToLabel } from "src/app/utils/transformArrayToLabel";
import { DataSourceDescriptionData } from "src/app/models/data-source-description-data.model";
import { Area } from "src/app/services/area/area";
import { AtlasFixService } from "src/app/services/ol-map/atlas-fix.service";
import { finalize, forkJoin } from "rxjs";
import { unsubscribe } from "src/app/utils/helpers";
import { OLMapService } from "src/app/services/ol-map/ol-map.service";

@Component({
  selector: "app-atlas-fix-page",
  templateUrl: "./atlas-fix-page.component.html",
  styleUrls: ["./atlas-fix-page.component.scss"],
})
export class AtlasFixPageComponent
  extends AbstractPresentationPage
  implements OnDestroy
{
  dataDescription: DataSourceDescriptionData = PROJECT_DESCRIPTIONS.atlasFixe;

  constructor(
    protected statistics: StatisticsService,
    protected metadata: MetadataService,
    protected translationHelper: TranslationHelperService,
    private geoserverService: GeoserverAPIService,
    public atlasFixService: AtlasFixService,
    private projectService: ProjectService,
    private olMapService: OLMapService
  ) {
    super(statistics);
    const group = projectService.groups.filter((c) => c.group == "Atlas")[0];
    projectService.selectGroup(group);
    this.setParameters();
    this.metadata.setImage(
      environment.baseHref + "assets/images/og/data-portal.png"
    );
    this.setPolygon();
    this.metadata.setSubtitle("ui.projects.atlaslandline.title");
    this.metadata.setDescription("ui.projects.atlaslandline.description");
  }

  private setPolygon() {
    this.subscriptions.push(
      this.geoserverService.getLandLineCapabilities().subscribe((val) => {
        const featureDescriptor = this.olMapService.readWfsCapabilities(val);
        const forkJoinItems = featureDescriptor.map((fd) => {
          return this.geoserverService.getLandLineDescribeFeatures(fd.name);
        });

        forkJoin(forkJoinItems)
          .pipe(finalize(() => this.atlasFixService.polygonSubject.next(true)))
          .subscribe((response) => {
            const featureTypeList = response.map((res) => {
              return res.featureTypes[0];
            });

            const uniqueLevels = featureTypeList.map((c) => c.typeName);

            const levelArr = transformArrayToLabel(
              uniqueLevels,
              true,
              "area.level."
            );

            //TODO: get from layer
            const uniqueCovers = ["households", "population"];

            //TODO: get from layer
            const uniqueSpeeds = [
              "1",
              "10",
              "30",
              "50",
              "100",
              "350",
              "1000",
              "2500",
              "5000",
              "8500",
              "10000"
            ];

            const coverArr = transformArrayToLabel(
              uniqueCovers,
              true,
              "labels."
            );
            const speedArr = transformArrayToLabel(
              uniqueSpeeds,
              true,
              "labels.",
              "",
              "MBPS"
            );

            const sectors = this.atlasFixService.olMapService.datasets.find(
              (ds) => ds.label == "labels.sectors"
            );
            const sectorLevel = sectors.filters.find((fl) => fl.key == "level");
            const sectorCover = sectors.filters.find(
              (fl) => fl.key == "coverType"
            );
            const sectorSpeed = sectors.filters.find((fl) => fl.key == "speed");

            sectorLevel.choices = levelArr;
            sectorCover.choices = coverArr;
            sectorSpeed.choices = speedArr;

            this.atlasFixService.olMapService.filter = sectors.filter =
              new Filter({
                speed: speedArr[5].value,
                coverType: coverArr[0].value,
                level: levelArr[5].value,
              });

            this.atlasFixService.olMapService.filters = sectors.filters;
          });
      })
    );
  }
  private setParameters() {
    this.atlasFixService.olMapService.datasets = [
      {
        label: "labels.sectors",
        dataset: new AtlasFixDataset(),
        filter: new Filter(),
        filters: [
          {
            label: "controls.speed",
            key: "speed",
            choices: [],
          },
          {
            label: "controls.level",
            key: "level",
            choices: [],
          },
          {
            label: "controls.coverType",
            key: "coverType",
            choices: [],
          },
        ],
      },
    ];
    this.atlasFixService.olMapService.dataset =
      this.atlasFixService.olMapService.datasets[0].dataset;
    this.atlasFixService.olMapService.filter =
      this.atlasFixService.olMapService.datasets[0].filter;
    this.atlasFixService.olMapService.filters =
      this.atlasFixService.olMapService.datasets[0].filters;
  }
  searchArea(area: Area) {
    this.atlasFixService.olMapService.searchArea(area);
  }
  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
