<!--<div class="container-fluid page-header bg-1 bg-image">-->
<!--<div class="bg-image-overlay"></div>-->
<!--<div class="page-header-inner">-->
<!--<h1>{{'opendata.title' | translate}}</h1>-->
<!--<p>{{'opendata.description' | translate}}</p>-->
<!--</div>-->
<!--</div>-->

<!-- <div class="container-fluid page-header bg-1 bg-image">
  <div class="bg-image-overlay"></div>
  <div class="page-header-inner">
    <h1>{{'opendata.title' | translate}}</h1>
    <h2 class="subtitle">{{'opendata.description' | translate}}</h2>
  </div>
</div> -->

<main class="container breathe">
  <div class="row" *ngFor="let project of projects">
    <div class="col-12">
      <div class="project">
        <div class="project-title">
          <h3>{{ project.title | translate }}</h3>
          <ul>
            <li
              *ngFor="let badge of project.badges"
              [style.color]="badge.colors.text.toRGBAString()"
              [style.backgroundColor]="badge.colors.background.toRGBAString()"
              class="badge"
            >
              {{ badge.label | label | async }}
            </li>
          </ul>
        </div>

        <div *ngIf="project.description" class="project-description">
          <h4>{{ "opendata.project.description" | translate }} :</h4>
          <p>{{ project.description | translate }}</p>
        </div>

        <div *ngIf="project.license" class="project-license">
          <h4>{{ "opendata.project.license" | translate }} :</h4>
          <p [innerHTML]="project.license"></p>
        </div>

        <div class="files">
          <h4>{{ "opendata.project.files" | translate }} :</h4>
          <ul *ngIf="project.urls">
            <li *ngFor="let url of project.urls">
              <a [href]="url.value">
                <fa-icon [icon]="icons.falFileCsv" class="mr-1"></fa-icon>
                {{ url.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</main>
