<div>
  <ng-container *ngIf="filters">
    <app-area-select [(selectedArea)]="selectedArea" (selectedAreaChange)="onSearchArea($event)"></app-area-select>

    <mat-form-field *ngFor="let fil of filters" class="w-100">
      <mat-select [placeholder]="fil.label | translate" [ngModel]="filter.get(fil.key)"
        (ngModelChange)="setFilter(fil.key, $event)" [multiple]="fil.multiple">
        <mat-option *ngFor="let choice of fil.choices" [value]="choice.value">{{choice.label | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>