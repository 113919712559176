import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { IndicatorsService } from '../indicators.service';

function printElem(elem: HTMLCanvasElement) {
  const mywindow = window.open('', 'PRINT', 'height=400,width=600');

  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write('</head><body >');
  mywindow.document.write('<img src=\'' + elem.toDataURL() + '\'/>');
  mywindow.document.write('</body></html>');

  setTimeout(() => {
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
  }, 0);
}

@Component({
  selector: 'app-indicator-buttons',
  templateUrl: './indicator-buttons.component.html',
  styleUrls: ['./indicator-buttons.component.scss']
})
export class IndicatorButtonsComponent implements OnChanges {

  @Input() group: string;
  @Input() short: string;
  @Input() breakdown: string;

  get canPrintChart(): boolean {
    return !!this.group && !!this.short && !!this.breakdown;
  }

  csvDownloadLink: any;
  excelDownloadLink: any;

  constructor(
    private indicatorService: IndicatorsService,
  ) {
  }

  printPage() {
    window.print();
  }

  printChart() {
    const chartEl: HTMLCanvasElement = document.getElementById('indicators-chart') as any;
    printElem(chartEl as any);
  }

  downloadImage() {
    const chartEl: HTMLCanvasElement = document.getElementById('indicators-chart') as any;
    const a = document.createElement('a'); //Create <a>
    a.href = chartEl.toDataURL();
    const indicatorServiceData = this.indicatorService.get();
    a.download = `${indicatorServiceData.short} - ${indicatorServiceData.breakdown}`.replace(/[.]/g, '') + '.png';
    a.click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.group || this.short || this.breakdown) {
      let params = new HttpParams({fromObject: {group: this.group}});
      if (this.short) {
        params = params.append('short', this.short);
      }
      if (this.breakdown) {
        params = params.append('breakdown', this.breakdown);
      }

      this.csvDownloadLink = `${environment.indicators.api.url}/v1/indicators/download?${params.append('format', 'csv').toString()}`;
      this.excelDownloadLink = `${environment.indicators.api.url}/v1/indicators/download?${params.append('format', 'excel').toString()}`;
    } else {
      this.csvDownloadLink = undefined;
      this.excelDownloadLink = undefined;
    }
  }
}
