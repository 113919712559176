<div class="container-fluid">
  <div class="row">
    <div class="col text-center app-bagde-section">
      <h2>{{ 'ui.projects.crowdsourcing.banner.title' | translate }}</h2>
      <p class="mb-0">{{ 'ui.projects.crowdsourcing.banner.description' | translate }}</p>
      <div class="badges">
        <app-store-badge store="ios" appUrl="https://itunes.apple.com/us/app/bipt-speedtest/id1391005650"></app-store-badge>
        <app-store-badge store="android" appUrl="https://play.google.com/store/apps/details?id=be.charliebravo.bipt.qos3"></app-store-badge>
      </div>
    </div>
  </div>
</div>
