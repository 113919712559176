import { Injectable } from "@angular/core";
import { OLMapService } from "./ol-map.service";
import MapBrowserEvent from "ol/MapBrowserEvent";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import {
  BBOX,
  FIX_FILTERS,
  FIX_WORKSPACES,
  LABEL_ATLAS_FIX,
  TYPE_NAME_PREFIX,
} from "src/assets/constants/constants";
import { PointDetails } from "src/app/models/pointDetails";
import { OlAtlasBaseService } from "./ol-atlas-base.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiFilterValue } from "src/app/datasets/api-filter-value.model";
import { BehaviorSubject, Observable } from "rxjs";
import {
  checkCorrectParams,
  checkQueryParameters,
} from "src/app/utils/helpers";

@Injectable({
  providedIn: "root",
})
export class AtlasFixService extends OlAtlasBaseService {
  pointDetails: PointDetails;
  polygonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    public olMapService: OLMapService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(olMapService);
  }

  mapOperations(event: MapBrowserEvent<MouseEvent>) {
    this.olMapService.details = [];
    this.removeVectorLayers();
    return this.createJoinEndpoints(event);
  }
  createJoinEndpoints(
    event: MapBrowserEvent<MouseEvent>
  ): Observable<Object>[] {
    const tileLayers = event.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l instanceof TileLayer &&
          l.getSource() instanceof TileWMS &&
          l.getVisible()
      ) as TileLayer<TileWMS>[];

    if (!tileLayers) {
      return;
    }
    const tileSources = tileLayers.map((tl) => tl.getSource()) as TileWMS[];

    if (!tileSources) {
      return;
    }

    const viewResolution = this.olMapService.map.getView().getResolution();
    const zoom = this.olMapService.map.getView().getZoom();

    const urls = tileSources.map((ts) => {
      let style = "";
      const workspace = ts
        .getUrls()[0]
        .split(environment.geoServer.baseUrl)[1]
        .split("/")[1];

      if (zoom > 15.5) {
        if (workspace === environment.geoServer.dp_landline_overview) {
          return "";
        }
      } else {
        if (workspace === environment.geoServer.dp_landline_details) {
          return "";
        }
      }
      if (workspace === environment.geoServer.dp_landline_overview) {
        style = this.getStyleName();
      }
      
      return ts.getFeatureInfoUrl(
        event.coordinate,
        viewResolution/2,
        "EPSG:3857",
        {
          INFO_FORMAT: "application/json",
          STYLES: style,
          FEATURE_COUNT: 50,
        }
      );
    });
    return urls.filter((url) => url != "").map((url) => this.http.get(url));
  }
  getLayerDetails(feature: Feature<Geometry>) {
    this.olMapService.detailsLoading = true;
    feature
      .getKeys()
      .sort()
      .forEach((key) => {
        if (
          LABEL_ATLAS_FIX.includes(key) &&
          feature.get(key) !== "" &&
          feature.get(key) !== null
        ) {
          this.olMapService.details.push({
            label: key.includes(LABEL_ATLAS_FIX[0]) ? "area" : key,
            value: feature.get(key),
          });
        }
      });
    const style = this.getStyleName();
    feature
      .getKeys()
      .sort()
      .forEach((key) => {
        const column = style
          .replace("households", "H")
          .replace("population", "P");
        if (
          !column.localeCompare(key) &&
          feature.get(key) !== "" &&
          feature.get(key) !== null
        ) {
          this.olMapService.details.push({
            label: "cover",
            ratio: feature.get(key),
          });
        }
      });
    this.olMapService.detailsLoading = false;
  }
  getLayerName(): string {
    const layerName = this.olMapService.filter.get(FIX_FILTERS[1]);
    if (!layerName) {
      return;
    }
    return layerName;
  }
  getStyleName(): string {
    const speed = this.olMapService.filter.get(FIX_FILTERS[0]);
    const coverType = this.olMapService.filter.get(FIX_FILTERS[2]);
    const style = `${coverType}_${speed}`;
    return style;
  }
  loadLayer(): void {
    this.removeVectorLayers();
    const layerName = this.getLayerName();
    const styleName = this.getStyleName();
    let existingLayer = undefined;
    const selectedLayerName = `${layerName}_${styleName}`;
    existingLayer = this.olMapService.layers.find(
      (layer) => layer.name === selectedLayerName
    );
    if (existingLayer === undefined) {
      const tileLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServer.baseUrl}/${environment.geoServer.dp_landline_overview}/wms`,
          params: {
            LAYERS: TYPE_NAME_PREFIX + layerName,
            FORMAT: "image/png",
            STYLES: styleName,
          },
          serverType: "geoserver",
        }),
        opacity: 0.6,
      });
      this.olMapService.map.addLayer(tileLayer);
      this.olMapService.layers.push({
        name: selectedLayerName,
        layer: tileLayer,
        workspace: environment.geoServer.dp_landline_overview,
      });
      tileLayer.set("layerdetails", {
        name: selectedLayerName,
        workspace: environment.geoServer.dp_landline_overview,
      });
    } else {
      existingLayer.layer.setVisible(true);
    }
    this.olMapService.layers.forEach((visibleLayer) => {
      if (
        visibleLayer.workspace === environment.geoServer.dp_landline_overview &&
        visibleLayer.name !== environment.geoServer.statsec_details &&
        visibleLayer.name !== selectedLayerName
      ) {
        visibleLayer.layer.setVisible(false);
      }
    });
    this.changeUrl();
  }

  loadPoint(apiFilterValue: ApiFilterValue) {
    const existingLayer = this.olMapService.layers.find(
      (point) => point.name == environment.geoServer.statsec_details
    );
    if (!existingLayer) {
      const tileLayer = new TileLayer({
        source: new TileWMS({
          url: `${environment.geoServer.baseUrl}/${environment.geoServer.dp_landline_details}/wms`,
          params: {
            LAYERS: environment.geoServer.statsec_details,
            FORMAT: "image/png",
            STYLES: "",
            VERSION: "1.1.1",
          },
          serverType: "geoserver",
        }),
        opacity: 0.6,
      });
      this.olMapService.map.addLayer(tileLayer);
      this.olMapService.layers.push({
        name: environment.geoServer.statsec_details,
        layer: tileLayer,
        workspace: environment.geoServer.dp_landline_details,
      });
      tileLayer.set("layerdetails", {
        name: environment.geoServer.statsec_details,
        workspace: environment.geoServer.dp_landline_details,
      });
    } else {
      existingLayer.layer.setVisible(true);
    }
    this.olMapService.layers.forEach((visibleLayer) => {
      if (
        visibleLayer.workspace !== environment.geoServer.dp_landline_details &&
        visibleLayer.workspace !== environment.geoServer.dp_landline_overview
      ) {
        visibleLayer.layer.setVisible(false);
      }
    });
  }
  setFilter(): void {
    this.loadLayer();
  }
  updateDetails(feature: Feature<Geometry>) {
    const layerName = this.getLayerName();
    const id = feature.getId().toString().split(".");

    if (!id[0].includes(layerName)) {
      return;
    }

    this.pointDetails = [];
    this.olMapService.detailsLoading = true;
    this.getLayerDetails(feature);

    this.olMapService.detailsLoading = false;
  }

  updatePointDetails(features: Feature<Geometry>[]) {
    const layerName = this.getLayerName();

    features.forEach((feature, i) => {
      const id = feature.getId().toString().split(".");

      if (!id[0].includes(layerName)) {
        if (i === 0) {
          this.pointDetails = [];
          this.olMapService.detailsLoading = true;
        }

        feature.setProperties({ Provider: id.shift() });
        this.pointDetails.push(feature.getProperties());
      }
    });

    this.olMapService.detailsLoading = false;
  }

  setDefaultFilter() {
    if (Object.keys(this.olMapService.mapConfig).length === 0) {
      return;
    }
    const haveAllQueryParams = checkQueryParameters(
      this.olMapService.mapConfig,
      FIX_FILTERS
    );
    if (!haveAllQueryParams) {
      return;
    }

    const isCorrectParams = checkCorrectParams(
      this.olMapService.filters,
      this.olMapService.mapConfig,
      FIX_FILTERS
    );
    if (isCorrectParams.some((cp) => cp == false)) {
      return;
    }

    this.olMapService.filter.set(
      FIX_FILTERS[0],
      this.olMapService.mapConfig[FIX_FILTERS[0]]
    );
    this.olMapService.filter.set(
      FIX_FILTERS[1],
      this.olMapService.mapConfig[FIX_FILTERS[1]]
    );
    this.olMapService.filter.set(
      FIX_FILTERS[2],
      this.olMapService.mapConfig[FIX_FILTERS[2]]
    );
    const bBoxArr = this.olMapService.mapConfig[BBOX]
      ? this.olMapService.mapConfig[BBOX].split(",").map((bbox) => Number(bbox))
      : "";
    if (bBoxArr != "") {
      this.olMapService.map.getView().fit(bBoxArr);
    }
  }

  changeUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [FIX_FILTERS[0]]: this.olMapService.filter.get(FIX_FILTERS[0]),
        [FIX_FILTERS[1]]: this.olMapService.filter.get(FIX_FILTERS[1]),
        [FIX_FILTERS[2]]: this.olMapService.filter.get(FIX_FILTERS[2]),
        [BBOX]: [
          this.olMapService.map
            .getView()
            .calculateExtent(this.olMapService.map.getSize()),
        ].toString(),
      },
      replaceUrl: true,
    });
  }
}
