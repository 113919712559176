import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IndicatorValue } from '../models/indicator-value';
import { Chart, ChartDataSets, ChartType } from 'chart.js';
import { labelToColor } from '../../../utils/labelToColor';

function round2(val) {
  return Math.round(val * 10) / 10;
}

function sortByKey<T>(k: keyof T) {
  return (a: T, b: T): number => a[k] < b[k] ? -1 : a[k] > b[k] ? 1 : 0;
}

@Component({
  selector: 'app-indicator-chart-component',
  templateUrl: './indicator-chart.component.html',
  styleUrls: ['./indicator-chart.component.scss']
})
export class IndicatorChartComponent implements OnChanges {

  private chartElement: HTMLCanvasElement;

  @ViewChild('canvasElement', { static: true })
  set canvasElement(val: ElementRef) {
    this.chartElement = val.nativeElement;
  }

  @Input() short: string;
  @Input() breakdown: string;
  @Input() unit: string;
  @Input() subjects: string[];
  @Input() periods: string[];
  @Input() values: IndicatorValue[];
  @Input() chartType: ChartType;

  private chart: Chart;

  ngOnChanges(changes: SimpleChanges): void {
    this.buildChart();
  }

  private buildChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    const values = this.values.filter(({ period }) => (
      !this.periods || this.periods.indexOf(period) !== -1
    ));

    const periods = Array.from(new Set(values.map(val => val.period)));
    const subjects = Array.from(new Set(values.map(v => v.subject)));

    const filteredSubjects = subjects
      .filter(subject => !this.subjects || this.subjects.indexOf(subject) !== -1);

    const datasets: ChartDataSets[] = filteredSubjects
      .sort()
      .map(subject => {
        const valuesWithSameSubject = values
          .filter(v => v.subject === subject)
          .sort(sortByKey('period'))
          .map(v => ({
            x: v.period,
            y: v.value,
          }));
        const color = filteredSubjects.length > 1 ? labelToColor(subject).toHexString() : '#2E2F7F';
        return ({
          label: subject,
          data: valuesWithSameSubject,
          ...(this.chartType !== 'line'
            ? {
              backgroundColor: color
            }
            : {
              backgroundColor: 'transparent',
              borderColor: color,
              pointRadius: 6,
              pointHoverRadius: 8,
              // pointBorderWidth: 3,
              // pointHoverBorderWidth: 3,
              pointBackgroundColor: color,
            }),
        });
      });

    this.chart = new Chart(this.chartElement.getContext('2d'), {
      type: this.chartType,
      data: {
        labels: periods.sort(),
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: `${this.short} - ${this.breakdown}`,
          fontSize: 14,
          fontFamily: '\'Nunito Sans\', \'Open Sans\', sans-serif',
          // fontColor: '#2E2F7F'
        },
        legend: {
          onClick: (e) => e.stopPropagation()
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Period'
              },
            }
          ],
          yAxes: [{
            scaleLabel: {
              labelString: this.unit,
              display: true,
            },
            ticks: {
              callback: (value: number, index, values) => {
                if (value >= 1000000000) {
                  return `${round2(value / 1000000000)}B`;
                } else if (value >= 1000000) {
                  return `${round2(value / 1000000)}M`;
                } else if (value >= 1000) {
                  return `${round2(value / 1000)}k`;
                }
                return value;
              },
              beginAtZero: true,
            }
          }]
        }
      }
    });
  }

}
