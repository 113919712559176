import { Injectable } from "@angular/core";
import { OLMapService } from "./ol-map.service";
import { Feature, MapBrowserEvent } from "ol";
import { Geometry } from "ol/geom";
import { HttpClient } from "@angular/common/http";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { Observable } from "rxjs";
import { OlAtlasBaseService } from "./ol-atlas-base.service";
import {
  BBOX,
  FTTH_FILTERS,
  LABEL_FTTH,
  LAYERPOSTFIX,
  TYPE_NAME_PREFIX,
} from "src/assets/constants/constants";
import { ExecutionStateEnum } from "src/app/models/domain/execution-state-enum";
import { ExecutedStateLayerEnum } from "src/app/models/domain/file-name-enum";
import {
  checkCorrectParams,
  checkQueryParameters,
  formatYearQuarter,
  getCurrentYearQuarter,
  getCurrentYearQuarterPostfix,
  getCurrentYearQuartersPostfixes,
} from "src/app/utils/helpers";
import { AtlasBaseMapComponent } from "src/app/components/ol-map/components/atlas-base-map.component";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AtlasFtthService extends OlAtlasBaseService {
  layerPostfix: string;
  lastQuarterDataAvailable: boolean = false;

  constructor(
    private http: HttpClient,
    public olMapService: OLMapService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(olMapService);
  }

  getLayerDetails(feature: Feature<Geometry>) {
    this.olMapService.detailsLoading = true;
    const keys = feature.getKeys();
    if (keys.length > 0) {
      feature
        .getKeys()
        .sort()
        .forEach((key) => {
          if (
            LABEL_FTTH.includes(key) &&
            feature.get(key) !== "" &&
            feature.get(key) !== null
          ) {
            this.olMapService.details.push({
              label: key,
              value: feature.get(key),
            });
          }
        });
    }
    this.olMapService.detailsLoading = false;
  }
  getLayerName(): string[] {
    const layerNames = [];
    const executionState = this.olMapService.filter.get(FTTH_FILTERS[0]);
    if (executionState?.includes(ExecutionStateEnum.EXECUTED)) {
      layerNames.push(
        ExecutedStateLayerEnum.EXECUTED + "_" + this.layerPostfix
      );
    }
    if (executionState?.includes(ExecutionStateEnum.PLANNED)) {
      layerNames.push(ExecutedStateLayerEnum.PLANNED + "_" + this.layerPostfix);
    }
    return layerNames;
  }
  getStyleName(): string {
    return "";
  }
  loadLayer() {
    this.removeVectorLayers();
    const layerNames = this.getLayerName();
    layerNames.forEach((layerName) => {
      const existingLayer = this.olMapService.layers.find(
        (l) => l.name === layerName
      );
      if (existingLayer === undefined) {
        const workspace = layerName.includes(ExecutedStateLayerEnum.PLANNED)
          ? environment.geoServer.dp_fiber_planned
          : environment.geoServer.dp_fiber_rolledout;
        const tileLayer = new TileLayer({
          source: new TileWMS({
            url: `${environment.geoServer.baseUrl}/${workspace}/wms`,
            params: {
              LAYERS: layerName,
              FORMAT: "image/png",
            },
            serverType: "geoserver",
          }),
          opacity: 0.6,
        });
        this.olMapService.map.addLayer(tileLayer);
        this.olMapService.layers.push({ name: layerName, layer: tileLayer });
      } else {
        existingLayer.layer.setVisible(true);
      }
    });
    this.olMapService.layers.forEach((visibleLayer) => {
      if (!layerNames?.includes(visibleLayer.name)) {
        visibleLayer.layer.setVisible(false);
      }
    });
    this.changeUrl();
  }
  setFilter(layerPostfix: string) {
    this.layerPostfix = layerPostfix;
    this.loadLayer();
  }
  updateDetails(feature: Feature<Geometry>) {
    this.olMapService.detailsLoading = true;
    this.getLayerDetails(feature);
    this.olMapService.detailsLoading = false;
  }
  mapOperations(event: MapBrowserEvent<MouseEvent>) {
    this.olMapService.details = [];
    this.removeVectorLayers();
    return this.createJoinEndpoints(event);
  }
  createJoinEndpoints(
    event: MapBrowserEvent<MouseEvent>
  ): Observable<Object>[] {
    const tileLayers = event.map
      .getLayers()
      .getArray()
      .filter(
        (l) =>
          l instanceof TileLayer &&
          l.getSource() instanceof TileWMS &&
          l.getVisible()
      ) as TileLayer<TileWMS>[];

    if (!tileLayers) {
      return;
    }
    const tileSources = tileLayers.map((tl) => tl.getSource()) as TileWMS[];

    if (!tileSources) {
      return;
    }
    const viewResolution = this.olMapService.map.getView().getResolution();
    const urls = tileSources.map((ts) =>
      ts.getFeatureInfoUrl(event.coordinate, viewResolution, "EPSG:3857", {
        INFO_FORMAT: "application/json",
      })
    );
    return urls.filter((url) => url != "").map((url) => this.http.get(url));
  }
  getLayerNamesLastQuarter(): string[] {
    const layerNames = [];
    const lastQuarterLayerPostfix = formatYearQuarter(getCurrentYearQuarter());
    const executionState = this.olMapService.filter.get(FTTH_FILTERS[0]);
    if (executionState?.includes(ExecutionStateEnum.EXECUTED)) {
      layerNames.push(
        ExecutedStateLayerEnum.EXECUTED + "_" + lastQuarterLayerPostfix
      );
    }
    if (executionState?.includes(ExecutionStateEnum.PLANNED)) {
      layerNames.push(
        ExecutedStateLayerEnum.PLANNED + "_" + lastQuarterLayerPostfix
      );
    }

    return layerNames;
  }
  checkDataCurrentQuarter(): void {
    const layerNames = this.getLayerNamesLastQuarter();
    const style = this.getStyleName();
    let url;
    layerNames.forEach((layerName) => {
      const tileLayer = new TileLayer({
        source: new TileWMS({
          url: this.olMapService.dataset.getMapBaseUrl() + "/wms",
          params: {
            LAYERS: TYPE_NAME_PREFIX + layerName,
            FORMAT: "image/png",
            STYLES: style,
          },
          serverType: "geoserver",
        }),
        opacity: 0.6,
      });
      const source = tileLayer.getSource();
      const size = this.olMapService.map.getSize();
      const projection = this.olMapService.map.getView().getProjection();

      if (!this.lastQuarterDataAvailable) {
        url = source.getFeatureInfoUrl(
          this.olMapService.map.getView().getCenter(),
          this.olMapService.map.getView().getResolution(),
          projection,
          {
            INFO_FORMAT: "application/json",
            QUERY_LAYERS: layerName,
            LAYERS: layerName,
            FEATURE_COUNT: 1,
            WIDTH: size[0],
            HEIGHT: size[1],
            BBOX: AtlasBaseMapComponent.DEFAULT_EXTENT,
          }
        );
      }
    });
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        this.lastQuarterDataAvailable = true;
        if (this.olMapService.mapConfig[LAYERPOSTFIX]) {
          this.layerPostfix = this.olMapService.mapConfig[LAYERPOSTFIX];
          this.loadLayer();
          return;
        }
        this.layerPostfix = getCurrentYearQuarterPostfix();
        this.loadLayer();
      })
      .catch(() => {
        this.lastQuarterDataAvailable = false;
        this.layerPostfix = this.getPreviousQuarterPostfix(
          getCurrentYearQuarterPostfix()
        );
        this.loadLayer();
      });
  }
  getPreviousQuarterPostfix(currentQuarter: string): string {
    const splitStrings = currentQuarter.split("Q", 2);
    const splitNums = [];
    splitStrings.forEach((s) => {
      splitNums.push(parseInt(s));
    });
    if (splitNums[1] > 1) {
      splitNums[1] -= 1;
      return splitNums[0] + "Q0" + splitNums[1];
    } else {
      splitNums[1] = 4;
      splitNums[0] -= 1;
      return splitNums[0] + "Q0" + splitNums[1];
    }
  }
  setDefaultFilter() {
    if (Object.keys(this.olMapService.mapConfig).length === 0) {
      return;
    }
    const haveAllQueryParams = checkQueryParameters(
      this.olMapService.mapConfig,
      FTTH_FILTERS
    );
    if (!haveAllQueryParams) {
      return;
    }

    const isCorrectParams = checkCorrectParams(
      this.olMapService.filters,
      this.olMapService.mapConfig,
      FTTH_FILTERS
    );
    if (isCorrectParams.some((cp) => cp == false)) {
      return;
    }

    const quarters = getCurrentYearQuartersPostfixes();
    if (!quarters.some((q) => q == this.olMapService.mapConfig[LAYERPOSTFIX])) {
      return;
    }

    this.olMapService.filter.set(
      FTTH_FILTERS[0],
      this.olMapService.mapConfig[FTTH_FILTERS[0]]
    );
    this.layerPostfix = this.olMapService.mapConfig[LAYERPOSTFIX];
    const bBoxArr = this.olMapService.mapConfig[BBOX].split(",").map((bbox) =>
      Number(bbox)
    );
    this.olMapService.map.getView().fit(bBoxArr);
  }
  changeUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        [FTTH_FILTERS[0]]: this.olMapService.filter.get(FTTH_FILTERS[0]),
        [LAYERPOSTFIX]: this.layerPostfix,
        [BBOX]: [
          this.olMapService.map
            .getView()
            .calculateExtent(this.olMapService.map.getSize()),
        ].toString(),
      },
      replaceUrl: true,
    });
  }
}
