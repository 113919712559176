import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum BootstrapMediaBreakpoint {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

@Injectable()
export class BootstrapMediaService {

  private breakpoints = {
    'XS': '(max-width: 575px)',
    'SM': '(min-width: 576px)',
    'MD': '(min-width: 768px)',
    'LG': '(min-width: 992px)',
    'XL': '(min-width: 1200px)',
  };

  private observables = new Map<BootstrapMediaBreakpoint, Observable<BreakpointState>>();

  constructor(protected breakpointObserver: BreakpointObserver) {

  }

  observe(breakpoint: BootstrapMediaBreakpoint): Observable<BreakpointState> {
    if (!this.observables.has(breakpoint)) {
      this.observables.set(breakpoint, this.breakpointObserver.observe(this.breakpoints[breakpoint]));
    }
    return this.observables.get(breakpoint);
  }
}
