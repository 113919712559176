import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter, FilterConfig } from '../../../datasets/filter';
import { Area } from '../../../services/area/area';

@Component({
  selector: 'app-map-filters',
  templateUrl: './map-filters.component.html',
  styleUrls: ['./map-filters.component.scss'],
})
export class MapFiltersComponent {

  @Input() filters: FilterConfig[];
  @Input() filter: Filter;
  @Output() update: EventEmitter<null> = new EventEmitter<null>();

  @Output() selectedAreaChange = new EventEmitter();
  selectedArea: Area;

  setFilter(filter: string, value: any) {
    this.filter.set(filter, value);
    this.update.emit(null);
  }

  onSearchArea(area: Area): void {
    this.selectedAreaChange.emit(area);
  }
}
