import { Filter, FilterConfig } from '../filter';
import { Dataset } from '../dataset';
import { ExtractRegistry } from './extract-registry';
import { Subject } from 'rxjs';
import { DEFAULT_FILTER, DOMAIN } from '../../models/domain/domain';
import { ChartConfig } from '../../components/abstract-chartjs/abstract-chartjs.component';

export enum ExtractType {
  DISTRIBUTION,
  TIMESERIES,
  COMPARISON,
}

export interface ExtractOptions {
  dataset: Dataset;
  config?: { [key: string]: any };
  configList?: ChartConfig[];
  filter?: Filter;
  filters?: FilterConfig[];
}

export class Extract<D = any> {

  name: string;
  data: D;
  type: ExtractType;
  chartType: string | 'line' | 'bar';
  loading: boolean = false;
  choices: { [filter: string]: any } = <any>DOMAIN;
  canChangeChartType: boolean = false;
  information: string;
  updateSubject: Subject<any> = new Subject<any>();

  xUnit?: string;
  yUnit?: string;

  get title() {
    return this.name;
  }

  getInformationUrl(): any {
    return undefined;
  }

  groupingFunction: (values: Array<number>) => number = (values) => {
    return values.reduce((a, b) => a + b) / values.length;
  };

  public dataset: Dataset;
  public config: any;
  public configList: FilterConfig[];
  public filter: Filter;
  public filters: FilterConfig[];

  constructor(options: ExtractOptions) {
    const defaultOptions = {
      config: {},
      configList: [],
      filter: new Filter(DEFAULT_FILTER),
      filters: [],
    };

    const opts = Object.assign({}, defaultOptions, options);

    this.dataset = opts.dataset;
    this.config = opts.config;
    this.configList = opts.configList;
    this.filter = opts.filter;
    this.filters = opts.filters;
  }

  observeUpdates() {
    return this.updateSubject.asObservable();
  }

  toRequest(): any {
    return {
      name: this.name,
      config: this.config,
      filter: this.filter.getPlain(),
    };
  }

  toPlain(): any {
    return {
      name: this.name,
      data: this.data,
      type: ExtractType[this.type],
      config: this.config,
      filter: this.filter.getPlain(),
    };
  }

  enrichFromPlain(plain: any): void {
    this.name = plain.name;
    this.data = plain.data;
    this.type = <ExtractType>plain.type;
    this.config = plain.config;
    this.data = plain.data;
    // this.choices['dataLabel'] = Extract.extractDataLabels(plain.data);
  }

  updateWith(model: Extract) {
    this.name = model.name;
    this.data = model.data;
    this.type = model.type;
    this.config = model.config;
    this.filter.updateWith(model.filter);
    this.dataset = model.dataset;
    this.updateSubject.next(this);
  }

  static fromPlain<D>(plain: any, dataset: Dataset): Extract<D> {
    let constructor = ExtractRegistry.getConstructor(plain.name);
    if (!constructor) {
      throw new Error(`No Extract type found for "${name}"`);
    }

    let extract = new constructor();
    extract.name = plain.name;
    extract.data = plain.data;
    extract.type = <ExtractType>plain.type;
    extract.config = plain.config;
    extract.filter = Filter.fromPlain(plain.filter);
    extract.dataset = dataset;

    // extract.choices['dataLabel'] = this.extractDataLabels(plain.data);

    return extract;
  }

  static extractDataLabels(data: { [setLabel: string]: { [dataLabel: string]: string } }): Array<string> {
    let sets = Object.values(data);
    let dataLabelsGroups = sets.map(set => Object.keys(set));

    return dataLabelsGroups
      .reduce((a, b) => a.concat(b))
      .filter((e, i, a) => a.indexOf(e) === i)
      .sort()
      ;
  }
}
