<div class="container" >
  <fa-icon class="loader" [class.shown]="!(atlasMobileFilterService.atlasMobileService.isByzoneLoaded && atlasMobileFilterService.atlasMobileService.isDetailedLoaded)" [icon]="icons.falSpinnerThird" [spin]="true"></fa-icon>
  <div class="row h-100" *ngIf="atlasMobileFilterService.atlasMobileService.isByzoneLoaded && atlasMobileFilterService.atlasMobileService.isDetailedLoaded">
    <div class="col-md-12 filters" >
      <div class="row datasets" >
        <div
          class="col dataset p-3"
          *ngFor="let entry of olMapService.datasets"
          [class.active]="olMapService.dataset === entry.dataset"
          (click)="changeDataset(entry)"
          id="{{ entry.label }}"
        >
          {{ entry.label | translate }}
        </div>
      </div>
      <ng-container>
        <app-area-select
        [(selectedArea)]="selectedArea"
        (selectedAreaChange)="onSearchArea($event)"
        ></app-area-select>
        
        <div>
            <mat-form-field
              *ngFor="let fil of olMapService.filters"
              class="w-100"
            >
              <mat-select
                [placeholder]="fil.label | translate"
                [ngModel]="olMapService.filter.get(fil.key)"
                (ngModelChange)="setFilter(fil.key, $event)"
                [multiple]="fil.multiple"
              >
                <mat-option
                  *ngFor="let choice of fil.choices"
                  [value]="choice.value"
                  >{{ choice.label | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
      
        </div>
      </ng-container>
    </div>
  </div>
</div>
