import { DeclareExtract } from '../../declare-extract.decorator';
import { Extract, ExtractType } from '../../extract';

@DeclareExtract('clientcount_comparison')
export class ClientCountComparison extends Extract {

  name = 'clientcount_comparison';
  type = ExtractType.COMPARISON;
  chartType = 'bar';

}
