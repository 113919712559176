import { Component } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { Project } from '../../models/project';
import { MetadataService } from '../../services/metadata.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

  projects: Project[];

  constructor(
    protected projectService: ProjectService,
    protected metadata: MetadataService,
  ) {
    this.metadata.setImage(environment.baseHref + 'assets/images/og/data-portal.png');
    this.metadata.setSubtitle('home.title');
    this.metadata.setDescription('home.subtitle');
    this.projects = this.projectService.projects;
    this.projectService.select(null);
    this.projectService.selectMember(null);
    this.projectService.selectGroup(null);
  }

  selectProject(project: Project){
    this.projectService.select(project);
  }
}
