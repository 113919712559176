import { Component, OnDestroy } from '@angular/core';

import { faLock as fasLock } from '@fortawesome/pro-solid-svg-icons/faLock';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { Subscription } from 'rxjs';
import { unsubscribe } from 'src/app/utils/helpers';

@Component({
  selector: 'app-login-page-component',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  readonly icons = {
    fasLock,
    eyeSlash: faEyeSlash,
    eye: faEye,
  };


  username: string;

  password: string;

  authenticating: boolean = false;

  failed: boolean = false;

  showPassword: boolean = false;

  protected static defaultRedirect: string = '/';

  constructor(
    protected authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    unsubscribe(this.subscriptions);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  authenticate() {
    this.authenticating = true;
    this.subscriptions.push(
      this.authenticationService.authenticate(this.username, this.password).subscribe(
        (success) => {
          console.log(this.constructor.name, 'authenticate', success);
          this.failed = !success;
          if (success) {
            this.subscriptions.push(
              this.route.queryParams
                .subscribe(
                  params => {
                    this.router.navigateByUrl(params['redirect'] || LoginPageComponent.defaultRedirect);
                  },
                  (err) => {
                    this.router.navigateByUrl(LoginPageComponent.defaultRedirect);
                  }
                ))
          }
        },
        (err) => {
          this.failed = true;
          console.error(this.constructor.name, 'authenticate', err);
        },
        () => {
          this.authenticating = false;
        }
      ))
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

}
