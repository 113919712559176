import { Component, OnInit } from "@angular/core";
import { ProjectService } from "src/app/services/project.service";
import { Group, Member } from "src/app/models/project";
import { SidenavKey, SidenavService } from "src/app/services/sidenav-service";
import { Subscription } from "rxjs";
import { unsubscribe } from "src/app/utils/helpers";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslationHelperService } from "src/app/services/translations/translation-helper.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-nav-link",
  templateUrl: "./nav-link.component.html",
  styleUrls: ["./nav-link.component.scss"],
})
export class NavLinkComponent implements OnInit {
  readonly icons = {
    times: faTimes,
    bars: faBars,
  };

  item: Group;
  selectedGroup: Group;
  selectedMember: Member;
  groups: Group[] = [];
  lang: string;
  groupList: HTMLElement;
  memberList: HTMLElement;
  route: string;
  sidenavOpen: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private projectService: ProjectService,
    protected sidenavService: SidenavService,
    protected translationHelper: TranslationHelperService
  ) {
    unsubscribe(this.subscriptions);

    this.groups = this.projectService.groups;
  }
  toggleMenu() {
    this.sidenavService.toggle(SidenavKey.MAIN);
  }
  ngOnInit(): void {
    this.subscriptions.push(
      this.router.events.subscribe((val) => {
        this.translationHelper.getLanguage().subscribe((lang) => {
          this.lang = lang;
          if (val instanceof NavigationEnd) {
            this.route = val.urlAfterRedirects.split("?")[0];
          }
        });
      }),
      this.projectService.getSelectedGroup().subscribe((g) => {
        this.selectedGroup = g;
        if (!g) {
          return;
        }
        this.selectedMember = g.members[0];
      }),
      this.projectService.getSelectedMember().subscribe((m) => {
        this.selectedMember = m;
      }),
      this.sidenavService.watch(SidenavKey.MAIN).subscribe((e) => {
        this.sidenavOpen = e;
      })
    );
  }

  onClickGroup(groupName: string) {
    const group = this.groups.find((gr) => gr.group === groupName);
    this.projectService.selectGroup(group);
  }
  onClickMember(memberTitle: string) {
    const member = this.selectedGroup.members.find(
      (gr) => gr.title === memberTitle
    );
    this.projectService.selectMember(member);
  }
  checkGroup(group: Group) {
    if (!this.selectedGroup) {
      return false
    }
    if(!this.route){
      return false
    }
    let checker = false;
    group.members.forEach((m) => {
      if (this.route.includes(group.routerNew)) {
        checker = true;
      }
    });

    return checker;
  }
  checkMember(member: Member) {
    let checker = false;
    if ("/" + this.lang + "/" + member?.routerLink === this.route) {
      checker = true;
    }
    return checker;
  }
}
