import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Extract } from '../../../datasets/extracts/extract';
import { faChartBar, faChartLine, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-chart-filters',
  templateUrl: './chart-filters.component.html',
  styleUrls: ['./chart-filters.component.scss'],
})
export class ChartFiltersComponent {

  @Input() extract: Extract;
  @Input() canChangeType: boolean = false;
  @Input() chartTypes: string[];
  @Input() currentType: string;
  @Output() currentTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() update: EventEmitter<null> = new EventEmitter<null>();

  get config() {
    return this.extract.config;
  }

  setFilter(filter: string, value: any) {
    this.extract.filter.set(filter, value);
    this.update.emit(null);
  }

  setConfig(key: string, value: any) {
    this.config[key] = value;

    // if (key === 'groupKey' || key === 'comparisonKey') {
    //   let otherKey = (key === 'groupKey' ? 'comparisonKey' : 'groupKey');
    //   if (this.config[key] === this.config[otherKey]) {
    //     this.config[otherKey] = (value === 'PROVIDER' ? 'TECHNOLOGY' : 'PROVIDER');
    //   }
    // }

    this.update.emit(null);
  }

  defaultCompare = (v1, v2) => {
    return v1 === v2;
  };

  getTypeIcon(type: string): IconDefinition {
    switch (type) {
      case 'line':
        return faChartLine;
      case 'bar':
        return faChartBar;
      default:
        return faChartLine;
    }
  }

  onChangeType(type: string): void {
    this.currentTypeChange.emit(type);
  }
}
